import React, { useState } from 'react';
import "./Accordian.css"
 

const Accordion = () => {
  const [selectedOption, setSelectedOption] = useState('USA'); // Set default value to 'USA'

  const optionContents = {
    'USA': 'Datavalley INC, 254 Chapman RD STE 208, NEWARK DE 19702, (+1) 302 722 6197',
    'Hyderabad': 'Datavalley India Pvt Ltd, 203, 2nd Floor, SBR CV Towers, Madhapur, Hyderabad, 500081, +91 9256899199',
    'UK': 'Datavalley, Legacy Centre, 7 Hampton Rd W, Feltham TW13 DH, +44 (0)7585888428',
    'Vijayawada': 'Fortune Heights, 52-1 / 8-11, Plot No\'s-8 & 9, Road No-2, NTR Colony, ESI Road, Vijayawada-520008, 9256899199',
  };

  const handleInputChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="accordion">
      <select
        value={selectedOption}
        onChange={handleInputChange}
        className="accordion-input"
      >
        {Object.keys(optionContents).map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {selectedOption && (
        <div className="accordion-content">
          <p>{optionContents[selectedOption]}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
