import { Route, Routes, useLocation } from "react-router-dom";
import { GetAuthGuard } from "./Guards";
import { SocketContextProvider } from "../services/Socket/Socket.context";
import { AuthenticationContextProvider } from "../services/Authentication/Authentication.context";

import PageNotFound from "../components/NotFound/PageNotFound";
import { AnimatePresence } from "framer-motion";
import Signin from "../components/Auth/Signin/Signin";
import Signup from "../components/Auth/Signup/Signup";
import Verify from "../components/Auth/Signup/Verify";
import ResetPassword from "../components/Auth/ResetPassword/ResetPassword";
import Dashboard from "../components/Admin/Dashboard";
import { BatchesContextProvider } from "../services/Batches/Batches.context";
import Batches from "../components/Admin/Batches/Batches";
import CreateEditBatch from "../components/Admin/Batches/CreateEditBatch";
import Users from "../components/Admin/Users/Users";
import { QuizContextProvider } from "../services/Quiz/Quiz.context";
import Quiz from "../components/Admin/Quiz/Quiz";
import StudentQuiz from "../components/Student/StudentQuiz/StudentQuiz";
import Student from "../components/Student/Student";
import StudentQuizStart from "../components/Student/StudentQuiz/StudentQuizStart";
import { ReportsContextProvider } from "../services/Reports/Reports.context";
import StudentReports from "../components/Student/StudentReports/StudentReports";
import StudentReportView from "../components/Student/StudentReports/StudentReportView";
import Reports from "../components/Admin/Reports/Reports";
import ReportView from "../components/Admin/Reports/ReportView";
// import { CourseContextProvider } from "../services/Courses/Courses.context";
// import Courses from "../components/Admin/Courses/Courses";
import StudentCourses from "../components/Student/StudentCourses/StudentCourses";
import StudentCourseView from "../components/Student/StudentCourses/StudentCourseView";
import { ResourcesContextProvider } from "../services/Resources/Resources.context";
import Resources from "../components/Admin/Resources/Resources";
import StudentResources from "../components/Student/StudentResources/StudentResources";
import { HelperContextProvider } from "../services/Helper/Helper.context";
import BatchesHome from "../components/Admin/Batches/BatchesHome";
import UsersHome from "../components/Admin/Users/UsersHome";
import CreateEditUser from "../components/Admin/Users/CreateEditUser";
import QuizHome from "../components/Admin/Quiz/QuizHome";
import CreateEditQuiz from "../components/Admin/Quiz/CreateEditQuiz";
import StudentProfile from "../components/Student/StudentProfile/StudentProfile";
import { UsersContextProvider } from "../services/users/users.context";
import StudentDashboard from "../components/Student/StudentDashboard/StudentDashboard";
import StudentPlayground from "../components/Student/StudentPlayground/StudentPlayground";
import { CourseNewContextProvider } from "../services/CoursesNew/CoursesNew.context";
import CoursesNew from "../components/Admin/CoursesNew/CoursesNew";
import { LeaderboardsContextProvider } from "../services/Leaderboards/Leaderboards.context";
import { NotificationsContextProvider } from "../services/Notification/Notification.context";
import QuizHomeNew from "../components/Admin/QuizNew/QuizHomeNew";
import QuizNew from "../components/Admin/QuizNew/QuizNew";
import CreateEditQuizNew from "../components/Admin/QuizNew/CreateEditQuizNew";
import { QuizNewContextProvider } from "../services/QuizNew/QuizNew.context";
import StudentQuizNew from "../components/Student/StudentQuizNew/StudentQuizNew";
import StudentQuizStartNew from "../components/Student/StudentQuizNew/StudentQuizStartNew";
import EmailService from "../components/Admin/EmailService/EmailService";
import { EmailServiceContextProvider } from "../services/EmailServiceContext/EmailService.context";
import { GlobalSearchContextProvider } from "../services/GlobalSearch/GlobalSearch.context";
import DSAHome from "../components/Admin/DSA/DSAHome";
import DSA from "../components/Admin/DSA/DSA";
import CreateEditDSA from "../components/Admin/DSA/CreateEditDSA";
import StudentDSAPractice from "../components/Student/StudentDSAPractice/StudentDSAPractice";
import { DSAContextProvider } from "../services/DSA/DSA.context";
import StudentDSAPracticeView from "../components/Student/StudentDSAPractice/StudentDSAPracticeView";
import { CodeExecutionContextProvider } from "../services/CodeExecution/CodeExecution.context";
import TrainerStatus from "../components/Admin/TrainerStatus/TrainerStatus";
import { TrainerStatusContextProvider } from "../services/TrainerStatus/TrainerStatus.context";
import TrainerData from "../components/Admin/TrainerData/TrainerData";

const Layout = (props) => {
  const location = useLocation();

  const StudentQuizElement = ({ title }) => {
    return (
      <QuizContextProvider>
        <StudentQuiz title={title} />
      </QuizContextProvider>
    );
  };

  const StudentQuizNewElement = ({ title }) => {
    return (
      <QuizNewContextProvider>
        <StudentQuizNew title={title} />
      </QuizNewContextProvider>
    );
  };

  const StudentQuizStartElement = ({ title }) => {
    return (
      <QuizContextProvider>
        <StudentQuizStart title={title} />
      </QuizContextProvider>
    );
  };

  const StudentQuizNewStartElement = ({ title }) => {
    return (
      <QuizNewContextProvider>
        <StudentQuizStartNew title={title} />
      </QuizNewContextProvider>
    );
  };

  const StudentReportsElement = ({ title }) => {
    return (
      <ReportsContextProvider>
        <StudentReports title={title} />
      </ReportsContextProvider>
    );
  };

  const StudentReportViewElement = ({ title }) => {
    return (
      <ReportsContextProvider>
        <QuizNewContextProvider>
          <StudentReportView title={title} />
        </QuizNewContextProvider>
      </ReportsContextProvider>
    );
  };

  const ReportsElement = ({ title }) => {
    return (
      <ReportsContextProvider>
        <QuizNewContextProvider>
          <Reports title={title} />
        </QuizNewContextProvider>
      </ReportsContextProvider>
    );
  };

  const ReportViewElement = ({ title }) => {
    return (
      <ReportsContextProvider>
        <QuizNewContextProvider>
          <ReportView title={title} />
        </QuizNewContextProvider>
      </ReportsContextProvider>
    );
  };
  /*
  // in case old course required in future
  const CoursesElement = ({ title }) => {
    return (
      <BatchesContextProvider>
        <CourseContextProvider>
          <Courses title={title} />
        </CourseContextProvider>
      </BatchesContextProvider>
    );
  };
  */

  const CoursesNewElement = ({ title }) => {
    return (
      <BatchesContextProvider>
        <CourseNewContextProvider>
          <CoursesNew title={title} />
        </CourseNewContextProvider>
      </BatchesContextProvider>
    );
  };

  const StudentCoursesElement = ({ title }) => {
    return (
      <CourseNewContextProvider>
        <StudentCourses title={title} />
      </CourseNewContextProvider>
    );
  };

  const StudentCoursesViewElement = ({ title }) => {
    return (
      <CourseNewContextProvider>
        <StudentCourseView title={title} />
      </CourseNewContextProvider>
    );
  };

  const AdminBatchesElement = ({ title }) => {
    return (
      <ReportsContextProvider>
        <Batches title={title} />
      </ReportsContextProvider>
    );
  };

  const ResourcesElement = ({ title }) => {
    return (
      <AuthenticationContextProvider>
        <ResourcesContextProvider>
          <BatchesContextProvider>
            <Resources title={title} />
          </BatchesContextProvider>
        </ResourcesContextProvider>
      </AuthenticationContextProvider>
    );
  };

  const EmailServiceElement = ({ title }) => {
    return (
      <BatchesContextProvider>
        <EmailServiceContextProvider>
          <EmailService title={title} />
        </EmailServiceContextProvider>
      </BatchesContextProvider>
    );
  };

  const StudentResourcesElement = ({ title }) => {
    return (
      <ResourcesContextProvider>
        <StudentResources title={title} />
      </ResourcesContextProvider>
    );
  };

  const StudentProfileElement = ({ title }) => {
    return (
      <UsersContextProvider>
        <StudentProfile title={title} />
      </UsersContextProvider>
    );
  };

  const StudentDashboardElement = ({ title }) => {
    return (
      <CourseNewContextProvider>
        <QuizNewContextProvider>
          <LeaderboardsContextProvider>
            <NotificationsContextProvider>
              <StudentDashboard title={title} />
            </NotificationsContextProvider>
          </LeaderboardsContextProvider>
        </QuizNewContextProvider>
      </CourseNewContextProvider>
    );
  };
  const StudentPlaygroundElement = ({ title }) => {
    return (
      <CodeExecutionContextProvider>
        <StudentPlayground title={title} />
      </CodeExecutionContextProvider>
    );
  };

  const StudentDSAPracticeElement = ({ title }) => {
    return (
      <DSAContextProvider>
        <StudentDSAPractice title={title} />
      </DSAContextProvider>
    );
  };

  const StudentDSAPracticeViewElement = ({ title }) => {
    return (
      <DSAContextProvider>
        <CodeExecutionContextProvider>
          <StudentDSAPracticeView title={title} />
        </CodeExecutionContextProvider>
      </DSAContextProvider>
    );
  };

  const TrainerStatusElement = ({ title }) => {
    return (
      <TrainerStatusContextProvider>
        <TrainerStatus title={title} />
      </TrainerStatusContextProvider>
    );
  };

  const TrainerDataElement = ({ title }) => {
    return (
      <TrainerStatusContextProvider>
        <TrainerData title={title} />
      </TrainerStatusContextProvider>
    );
  };

  return (
    <SocketContextProvider>
      <AuthenticationContextProvider>
        <HelperContextProvider>
          <GlobalSearchContextProvider>
            <AnimatePresence mode="wait">
              <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Signin title="Sign In" />} />

                <Route path="/auth">
                  <Route path="signin" element={<Signin title="Sign In" />} />
                  <Route path="signup" element={<Signup title="Sign Up" />} />
                  <Route
                    path="verify/:verificationToken"
                    element={<Verify title="Verification" />}
                  />
                  <Route
                    path="reset-password/:resetPasswordToken"
                    element={<ResetPassword title="Reset Password" />}
                  />
                </Route>

                <Route
                  path="/student"
                  element={
                    <GetAuthGuard
                      component={<Student title="Student" />}
                      to={"/auth/signin"}
                    />
                  }>
                  <Route
                    path="dashboard"
                    element={<StudentDashboardElement title={"Dashboard"} />}
                  />
                  <Route
                    path="quiz"
                    element={<StudentQuizElement title="Available Quizes" />}
                  />
                  <Route
                    path="quiz-new"
                    element={<StudentQuizNewElement title="Available Quizes" />}
                  />
                  <Route
                    path="courses"
                    element={<StudentCoursesElement title="Courses" />}
                  />

                  <Route
                    path="courses/view"
                    element={<StudentCoursesViewElement title="Course View" />}
                  />

                  <Route
                    path="quiz/start"
                    element={<StudentQuizStartElement title="Start Quiz" />}
                  />
                  <Route
                    path="quiz-new/start"
                    element={<StudentQuizNewStartElement title="Start Quiz" />}
                  />
                  <Route
                    path="reports"
                    element={<StudentReportsElement title="Reports" />}
                  />

                  <Route
                    path="resources"
                    element={<StudentResourcesElement title="Resources" />}
                  />

                  <Route
                    path="reports/view"
                    element={<StudentReportViewElement title="View Report" />}
                  />
                  <Route
                    path="profile"
                    element={<StudentProfileElement title="Profile" />}
                  />
                  <Route
                    path="playground"
                    element={<StudentPlaygroundElement title="Playground" />}
                  />
                  <Route
                    path="practice"
                    element={<StudentDSAPracticeElement title="Practice" />}
                  />
                  <Route
                    path="practice/view"
                    element={<StudentDSAPracticeViewElement title="Practice" />}
                  />
                </Route>

                <Route
                  path="/dashboard"
                  element={
                    <GetAuthGuard
                      component={<Dashboard title="Dashboard" />}
                      to={"/auth/signin"}
                    />
                  }>
                  <Route
                    path="batches"
                    element={<BatchesHome title="Batches" />}>
                    <Route
                      path=""
                      element={<AdminBatchesElement title="Batches" />}
                    />
                    <Route
                      path="create"
                      element={<CreateEditBatch mode={"create"} />}
                    />
                    <Route
                      path="edit"
                      element={<CreateEditBatch mode={"edit"} />}
                    />
                  </Route>
                  {/* incase old course required in future */}
                  {/* <Route
                  path="courses"
                  element={<CoursesElement title="Courses" />}
                /> */}
                  <Route
                    path="courses-new"
                    element={<CoursesNewElement title="CoursesNew" />}
                  />

                  <Route
                    path="resources"
                    element={<ResourcesElement title="Resources" />}
                  />
                  <Route path="users" element={<UsersHome title="Users" />}>
                    <Route path="" element={<Users title="Users" />} />
                    <Route
                      path="create"
                      element={<CreateEditUser mode={"create"} />}
                    />
                    <Route
                      path="edit"
                      element={<CreateEditUser mode={"edit"} />}
                    />
                  </Route>
                  <Route path="quiz" element={<QuizHome title="Quiz" />}>
                    <Route path="" element={<Quiz title="Quiz" />} />
                    <Route
                      path="create"
                      element={<CreateEditQuiz mode={"create"} />}
                    />
                    <Route
                      path="edit"
                      element={<CreateEditQuiz mode={"edit"} />}
                    />
                  </Route>
                  <Route path="dsa" element={<DSAHome title="DSAHome" />}>
                    <Route path="" element={<DSA title="DSA" />} />
                    <Route
                      path="create"
                      element={<CreateEditDSA mode={"create"} />}
                    />
                    <Route
                      path="edit"
                      element={<CreateEditDSA mode={"edit"} />}
                    />
                  </Route>
                  <Route path="quiz-new" element={<QuizHomeNew title="Quiz" />}>
                    <Route path="" element={<QuizNew title="Quiz" />} />
                    <Route
                      path="create"
                      element={<CreateEditQuizNew mode={"create"} />}
                    />
                    <Route
                      path="edit"
                      element={<CreateEditQuizNew mode={"edit"} />}
                    />
                  </Route>
                  <Route
                    path="reports"
                    element={<ReportsElement title="Reports" />}
                  />

                  <Route
                    path="reports/view"
                    element={<ReportViewElement title="View Report" />}
                  />

                  <Route
                    path="email-service"
                    element={<EmailServiceElement title={"Email service"} />}
                  />

                  <Route
                    path="trainer-status"
                    element={<TrainerStatusElement title={"Trainer status"} />}
                  />
                  <Route
                    path="trainer-updation"
                    element={<TrainerDataElement title={"Trainer updation"} />}
                  />
                </Route>
                <Route
                  path="*"
                  element={<PageNotFound title="Page Not Found" />}
                />
              </Routes>

              {/* <main>
            <Outlet />
          </main> */}
            </AnimatePresence>
          </GlobalSearchContextProvider>
        </HelperContextProvider>
      </AuthenticationContextProvider>
    </SocketContextProvider>
  );
};

export default Layout;
