import React, { useContext, useState, useEffect } from "react";
import classes from "./StudentDashboardSidebar.module.css";
import blankImg from "../../../assets/blank-image.png";
import { AuthenticationContext } from "../../../services/Authentication/Authentication.context";
import Trophy from "../../../assets/icons/trophy.svg";
import Points from "../../../assets/icons/points.svg";
import Coins from "../../../assets/icons/coins.svg";
import Goal from "../../../assets/icons/goal.svg";
import StopWatch from "../../../assets/icons/stop-watch.svg";
import CicleTick from "../../../assets/icons/circle-tick.svg";
import CircularProgressBar from "../../CircularProgressBar/CircularProgressBar";
import StudentLeaderboard from "../StudentLeaderboard.js/StudentLeaderboard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import VideoChatIcon from "@mui/icons-material/VideoChat";
import RateReviewIcon from "@mui/icons-material/RateReview";

const StudentDashboardSidebar = () => {
  const { userData } = useContext(AuthenticationContext);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  const onSetHoursLeft = () => {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);
    const diff = midnight - now;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    setHoursLeft(hours);
  };

  useEffect(() => {
    onSetHoursLeft();
    const interval = setInterval(() => {
      onSetHoursLeft();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const addSuffixToDay = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };
  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${addSuffixToDay(currentDate.getDate())} ${
    months[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;

  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let weeklyGoalsReached = 0;
  if (userData?.weeklyGoalsReached) {
    Object.values(userData?.weeklyGoalsReached).forEach((weekValue) => {
      if (weekValue === 1) {
        weeklyGoalsReached++;
      }
    });
  }
  const getShortDayName = (day) => {
    switch (day) {
      case 0:
        return "M";
      case 1:
        return "T";
      case 2:
        return "W";
      case 3:
        return "T";
      case 4:
        return "F";
      case 5:
        return "S";
      case 6:
        return "S";
      default:
        return "Invalid day";
    }
  };

  const isToday = (day) => {
    const today = new Date().getDay();
    if (today === 0 && day === 6) {
      return true;
    }
    return day === today - 1;
  };

  const onClickLeaderboard = () => {
    setShowLeaderboard(true);
  };

  const onClickBack = () => {
    setShowLeaderboard(false);
  };

  return (
    <div className={classes.mainSidebar}>
      {!showLeaderboard && (
        <div style={{ padding: "20px" }}>
          <div>
            <div className={classes.profileContainer}>
              <div>
                <img height={90} width={90} src={blankImg} alt="blank" />
              </div>
              <div className={classes.nameContainer}>
                <div className={classes.studentName}>
                  {userData?.firstName} {userData?.lastName}
                </div>
                <div
                  className={classes.leaderboard}
                  onClick={onClickLeaderboard}>
                  <div>
                    <img src={Trophy} width={20} height={20} alt="trophy" />
                  </div>
                  <div className={classes.ranksText}>
                    <div>Daily Ranks</div>
                    <div className={classes.arrowContainer}>
                      <div>{` --`}</div>
                      <div className={classes.rightArrow}>
                        <ArrowForwardIosIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.currDate}>{formattedDate}</div>
              </div>
            </div>
            <div className={classes.dataContainer}>
              <div className={classes.pointsContainer}>
                <div>
                  <img width={55} height={55} src={Points} alt="points" />
                </div>
                <div>
                  {userData?.totalPoints && (
                    <span className={classes.points}>
                      {numberWithCommas(userData?.totalPoints)}
                    </span>
                  )}
                  <br /> <span className={classes.pointsText}>Points</span>
                </div>
              </div>
              <div className={classes.coinsContainer}>
                <div>
                  <img width={55} height={55} src={Coins} alt="coins" />
                </div>
                <div>
                  {userData?.totalCoins && (
                    <span className={classes.coins}>
                      {numberWithCommas(userData.totalCoins)}
                    </span>
                  )}
                  <br />
                  <span className={classes.coinsText}>Coins</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.dailyGoalContainer}>
            <div className={classes.dailyGoalHeader}>
              <div className={classes.headerText}>
                Daily Goal <img className={classes.goalImg} alt="goal" src={Goal} height={15} width={15} />
              </div>
              <div className={classes.timeLeft}>
                <div className={classes.clockIcon}>
                  <img
                    width={16}
                    height={16}
                    src={StopWatch}
                    alt="stop watch"
                  />
                </div>
                <div>{hoursLeft} Hrs Left</div>
              </div>
            </div>
            <div className={classes.progressBar}>
              <div>
                <div>
                  <CircularProgressBar
                    isPercentageReqired={false}
                    isTotalRequired={true}
                    textFontSize={"16px"}
                    height={90}
                    width={90}
                    percentage={
                      userData?.todaysPoints ? userData.todaysPoints : 0
                    }
                    total={100}
                    fillColor={"#6366f1"}
                    backgroundColor={"#c7d2fe"}
                    circleWidth={6}
                    isTextRequired={true}>
                    {/* <img src={Points} width={30} height={30} alt="points" /> */}
                  </CircularProgressBar>
                </div>
              </div>
              <div className={classes.dailyGoalText}>
                <div>Daily Goal Reward</div>
                <div style={{ height: "30px", marginTop: "-2px" }}>
                  <img width={20} height={20} src={Coins} alt="coin" />
                </div>
                <div>30</div>
              </div>
            </div>
          </div>
          <div className={classes.weeklyGoalContainer}>
            <div className={classes.weeklyGoalHeader}>
              <div className={classes.headerText}>
                Weekly Goal <img className={classes.goalImg} alt="goal" src={Goal} height={15} width={15} />
              </div>
            </div>
            <div className={classes.weeksContainer}>
              <div className={classes.weeks}>
                <div>
                  <p
                    className={classes.goalsReached}
                    style={{ marginBottom: 0 }}>
                    {weeklyGoalsReached}/5 Days
                  </p>
                  <p className={classes.weekText} style={{ marginBottom: 0 }}>
                    This Week
                  </p>
                </div>
                <div
                  className={classes.weekContainer}
                  style={{ display: "flex" }}>
                  {userData?.weeklyGoalsReached &&
                    Object.values(userData.weeklyGoalsReached).map(
                      (weekValue, i) => {
                        if (weekValue === 1) {
                          return (
                            <div key={i}>
                              {isToday(i) && (
                                <div className={classes.todayDot}></div>
                              )}
                              <p
                                className={classes.weekWord}
                                style={{ marginBottom: 0 }}>
                                {getShortDayName(i)}
                              </p>
                              <div
                                style={{
                                  marginLeft: "-3px",
                                  marginRight: "-3px",
                                }}>
                                <img
                                  src={CicleTick}
                                  alt="circle tick"
                                  width={17}
                                  height={17}
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div key={i}>
                              {isToday(i) && (
                                <div className={classes.todayDot}></div>
                              )}
                              <p
                                className={classes.weekWord}
                                style={{
                                  marginBottom: 0,
                                  paddingLeft: isToday(i) ? "1px" : "2.5px",
                                }}>
                                {getShortDayName(i)}
                              </p>
                              {isToday(i) ? (
                                userData.todaysPoints >= 100 ? (
                                  <div
                                    style={{
                                      marginLeft: "-3px",
                                      marginRight: "-3px",
                                    }}>
                                    <img
                                      src={CicleTick}
                                      alt="circle tick"
                                      width={17}
                                      height={17}
                                    />
                                  </div>
                                ) : (
                                  <CircularProgressBar
                                    isTotalRequired={true}
                                    textFontSize={"16px"}
                                    height={15.5}
                                    width={15.5}
                                    percentage={userData.todaysPoints}
                                    total={100}
                                    fillColor={"#6366f1"}
                                    backgroundColor={"#c7d2fe"}
                                    circleWidth={2}
                                    isTextRequired={false}
                                    marginTop={"6px"}
                                    isPercentageReqired={false}
                                  />
                                )
                              ) : (
                                <CircularProgressBar
                                  height={15.5}
                                  width={15.5}
                                  percentage={0}
                                  total={100}
                                  fillColor={"#6366f1"}
                                  backgroundColor={"#c7d2fe"}
                                  circleWidth={1.5}
                                  isTextRequired={false}
                                  marginTop={"6px"}
                                />
                              )}
                            </div>
                          );
                        }
                      },
                    )}
                </div>
              </div>
              <div className={classes.dailyGoalText}>
                <div>Weekly Goal Reward</div>
                <div style={{ height: "30px", marginTop: "-2px" }}>
                  <img width={20} height={20} src={Coins} alt="coin" />
                </div>
                <div>200</div>
              </div>
            </div>
          </div>
          <div className={classes.supportContainer}>
            <div className={`${classes.headerText} ${classes.supportText}`}>
              Connect and learn
            </div>
            <div>
              <div className={classes.supportIcons}>
                {/* <div>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://community.datavalley.ai/">
                    <VideoChatIcon width={35} height={35} />
                  </a>
                </div> */}
                <div>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://community.datavalley.ai/">
                    <RateReviewIcon width={35} height={35} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showLeaderboard && <StudentLeaderboard onClickBack={onClickBack} />}
    </div>
  );
};

export default StudentDashboardSidebar;
