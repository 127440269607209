const executeJS = async (code) => {
  try {
    let capturedOutput = "";
    const originalConsoleLog = console.log;
    console.log = (...args) => {
      const formattedArgs = args
        .map((arg) => {
          if (typeof arg === "string") {
            return `"${arg}"`;
          } else if (Array.isArray(arg)) {
            return `[${arg.join(", ")}]`;
          }
          return arg;
        })
        .join(" ");
      capturedOutput += formattedArgs + "\n";
    };
    await new Function(code)();
    console.log = originalConsoleLog;
    return capturedOutput.trim();
  } catch (error) {
    throw new Error(error.message);
  }
};

export default executeJS;
