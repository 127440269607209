import { Outlet } from "react-router-dom";
import { BatchesContextProvider } from "../../../services/Batches/Batches.context";
import { useEffect } from "react";
import { QuizNewContextProvider } from "../../../services/QuizNew/QuizNew.context";

const QuizHomeNew = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);
  return (
    <BatchesContextProvider>
      <QuizNewContextProvider>
        <Outlet />
      </QuizNewContextProvider>
    </BatchesContextProvider>
  );
};

export default QuizHomeNew;
