import {
  Card,
  CardContent,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  Box,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState, useContext, useRef } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { BatchesContext } from "../../../services/Batches/Batches.context";
import _ from "lodash";
import classes from "./EmailService.module.css";
import { EmailServiceContext } from "../../../services/EmailServiceContext/EmailService.context";
import {
  DS11Template,
  FS11Template,
  DM11Template,
  CL11Template,
} from "../../../emailTemplates/trackEmailTemplates";
import CloseIcon from "@mui/icons-material/Close";

const EmailService = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [batches, setBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const { onGetBatches } = useContext(BatchesContext);
  const { onSendEmailsToMails } = useContext(EmailServiceContext);
  const [selectedEmail, setSelectedEmail] = useState("admissions");
  const [subject, setSubject] = useState("");
  const [errors, setErrors] = useState({
    subjectError: "",
    batchesError: "",
  });
  const [currentTrack, setCurrentTrack] = useState("");
  const [emailsSelected, setEmailsSelected] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const noOfSelectedStudents = useRef(0);

  useEffect(() => {
    getBatches();
  }, []);

  const onBatchesChangeHandler = (updatedBatches) => {
    if (!updatedBatches.length) {
      setErrors((prevError) => {
        return {
          ...prevError,
          batchesError: "please select at least one batch",
        };
      });
    } else {
      setErrors((prevError) => {
        return { ...prevError, batchesError: "" };
      });
    }

    let studentsCount = 0;
    updatedBatches.forEach((batch) => {
      studentsCount += batch.studentsCount;
    });
    noOfSelectedStudents.current = studentsCount;
    setSelectedBatches(updatedBatches);
  };

  const getBatches = (query = "") => {
    onGetBatches(
      query,
      (result) => {
        if (result.batches && result.batches.length > 0) {
          let structuredBatches = result.batches.map((b) => ({
            label: `${b.name} - ${b.code}`,
            id: b._id,
            studentsCount: b.studentsCount,
          }));
          setBatches(structuredBatches);
        }
      },
      () => {},
      false,
      false,
    );
  };

  const handleEditorChange = (newState) => {
    setEditorState(newState);
    const currentContent = newState.getCurrentContent();
    const html = convertToHTML(currentContent);
    if (!html || html === "<p></p>") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bodyError: "The body cannot be empty.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bodyError: "",
      }));
    }
  };

  const emailChangeHandler = (e) => {
    setSelectedEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    if (e.target.value) {
      setErrors((prevError) => {
        return { ...prevError, subjectError: "" };
      });
      return;
    }
  };

  const handleClickSendEmails = (e) => {
    if (subject === "") {
      setErrors((prevError) => {
        return { ...prevError, subjectError: "subject cannot be empty" };
      });
      return;
    }

    if (selectedBatches.length === 0 && emailsSelected.length === 0) {
      setErrors((prevError) => {
        return {
          ...prevError,
          batchesError: "please select at least one batch or a email",
        };
      });
      return;
    }
    const currentContent = editorState.getCurrentContent();
    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style === "BOLD") {
          return <strong />;
        }
        if (style === "ITALIC") {
          return <em />;
        }
        if (style === "UNDERLINE") {
          return <u />;
        }
        if (style === "CODE") {
          return <code />;
        }
        // Add more style conversions as needed
      },
      blockToHTML: (block) => {
        switch (block.type) {
          case "unstyled":
            return <p />;
          case "blockquote":
            return <blockquote />;
          case "code-block":
            return <pre />;
          default:
            return <p />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(currentContent);

    const batchIds = [];
    selectedBatches.forEach((batch) => {
      batchIds.push(batch.id);
    });

    const emails = [];
    emailsSelected.forEach((currentEmail) => {
      emails.push(currentEmail.mail);
    });

    const data = {
      subject,
      emailType: selectedEmail,
      htmlBody: html,
      batchIds,
      emails,
    };

    onSendEmailsToMails(
      data,
      (result) => {
        setEmailsSelected([]);
        setSelectedBatches([]);
        setSubject("");
        setCurrentTrack("");
        setEditorState(EditorState.createEmpty());
        noOfSelectedStudents.current = 0;
      },
      (e) => {
        console.log(e);
      },
      true,
      true,
    );
  };

  const handleTrackChange = (e) => {
    const value = e.target.value;
    setCurrentTrack(e.target.value);
    let newEditorState;
    let template;
    switch (value) {
      case "ds11":
        template = DS11Template();
        break;
      case "fs11":
        template = FS11Template();
        break;
      case "cl11":
        template = CL11Template();
        break;
      case "dm11":
        template = DM11Template();
        break;
      default:
        template = "<p></p>";
    }
    newEditorState = EditorState.createWithContent(convertFromHTML(template));

    setEditorState(newEditorState);
  };

  const handleEmailChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleAddEmailClick = () => {
    if (currentEmail) {
      const splittedEmails = currentEmail.split(" ");

      const emailsData = [];
      let validEmailsCount = 0;
      if (splittedEmails.length) {
        splittedEmails.forEach((email) => {
          if (email.length >= 3) {
            emailsData.push({ mail: email, id: Date.now() });
            validEmailsCount++;
          }
        });
      }

      setEmailsSelected((prevEmails) => {
        return [...prevEmails, ...emailsData];
      });
      setCurrentEmail("");

      noOfSelectedStudents.current += validEmailsCount;
      setErrors((prevError) => {
        return {
          ...prevError,
          batchesError: "",
        };
      });
    }
  };

  const handleClickRemoveEmail = (emailId) => {
    const filteredEmail = emailsSelected.filter(
      (email) => email.id !== emailId,
    );
    setEmailsSelected(filteredEmail);
    noOfSelectedStudents.current -= 1;
  };

  return (
    <div>
      <div>
        <Card>
          <CardContent>
            <div
              className={classes.headerContainer}
              style={{ marginBottom: "15px" }}>
              <div className={classes.studentsNumText}>
                Students selected: {noOfSelectedStudents.current}
              </div>
              <div>
                <Select
                  sx={{ minWidth: "150px" }}
                  value={selectedEmail}
                  onChange={emailChangeHandler}
                  displayEmpty
                  inputProps={{ "aria-label": "Select email type" }}>
                  <MenuItem value="" disabled>
                    Select email type
                  </MenuItem>
                  <MenuItem value="support">Support</MenuItem>
                  <MenuItem value="info">Info</MenuItem>
                  <MenuItem value="admissions">Admissions</MenuItem>
                  <MenuItem value="trainings">Trainings</MenuItem>
                </Select>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  error={errors.subjectError}
                  helperText={errors.subjectError}
                  margin="normal"
                  placeholder="Enter subject "
                  required
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  value={subject}
                  onChange={handleSubjectChange}
                />
              </Grid>
              <Grid sx={{ mt: "16px" }} item md={6}>
                <FormControl fullWidth>
                  <InputLabel id="dropdown-label">Select Track</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    value={currentTrack}
                    label="Select Track"
                    onChange={handleTrackChange}>
                    <MenuItem value="cl11">CL11</MenuItem>
                    <MenuItem value="dm11">DM11</MenuItem>
                    <MenuItem value="fs11">FS11</MenuItem>
                    <MenuItem value="ds11">DS11</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                {batches.length && (
                  <Autocomplete
                    disablePortal
                    id="batches"
                    className="mt-1"
                    options={batches}
                    multiple
                    fullWidth
                    value={selectedBatches}
                    onChange={(e, newValue) => {
                      let uniqueValues = _.uniqBy(newValue, "id");
                      onBatchesChangeHandler(uniqueValues);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.batchesError}
                        helperText={errors.batchesError}
                        label="Select students from batches "
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                <Box>
                  {emailsSelected.length > 0 && (
                    <div className={classes.emailsContainer}>
                      {emailsSelected.map((email, emailIndex) => (
                        <div className={classes.emailContainer} key={email.id}>
                          <div>{email.mail}</div>
                          <div style={{ cursor: "pointer" }}>
                            <CloseIcon
                              onClick={() => handleClickRemoveEmail(email.id)}
                              size={20}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item md={8}>
                <TextField
                  error={errors.batchesError}
                  helperText={errors.batchesError}
                  margin="normal"
                  placeholder="Enter Email "
                  fullWidth
                  id="email"
                  label="Email to add"
                  name="Email"
                  value={currentEmail}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item md={4} sx={{ mt: "16px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAddEmailClick}
                  sx={{
                    width: "150px",
                    height: "40px",
                  }}>
                  Add Email
                </Button>
              </Grid>
              <Grid item md={12}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName={classes.editorClass}
                  toolbarClassName="toolbar-class">
                  {errors.bodyError && (
                    <div className="error-message">{errors.bodyError}</div>
                  )}
                </Editor>
              </Grid>
            </Grid>
            <Box
              sx={{ mt: 2, mr: 3 }}
              className={classes.sendBtnContainer}
              display="flex"
              justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickSendEmails}
                sx={{
                  width: "100px",
                  height: "40px",
                }}>
                Send
              </Button>
            </Box>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EmailService;
