import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DSAContext } from "../../../services/DSA/DSA.context";
import StudentPlayground from "../StudentPlayground/StudentPlayground";
import classes from "./StudentDSAPracticeView.module.css";

const StudentDSAPracticeView = ({ title }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onGetSingleDSA } = useContext(DSAContext);
  const [dsaData, setDsaData] = useState(null);

  useEffect(() => {
    document.title = title;
    let dsaId = searchParams.get("id");
    if (!dsaId) {
      navigate("/student/practice");
      return;
    }
    onGetSingleDSA(
      dsaId,
      (result) => {
        console.log(result);
        if (result && result.dsa) {
          setDsaData(result.dsa);
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  }, []);
  return (
    <div>
      <div className={classes.mainContainer}>
        <div className={classes.leftDsaContent}>
          {dsaData?._id && (
            <div>
              <div className={classes.dsaTitle}>
                <h3>{dsaData.questionNumber}. {dsaData.title}</h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: dsaData.content,
                }}></div>
            </div>
          )}
        </div>
        <div style={{ width: "65%" }}>
          <StudentPlayground />
        </div>
      </div>
    </div>
  );
};

export default StudentDSAPracticeView;
