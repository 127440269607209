import { createContext } from "react";
import useHttp from "../../hooks/useHttp";

export const CodeExecutionContext = createContext({
  onExecuteJava: (data, callback, errorCallBack, loader, notify) => null,
  onExecuteCPP: (data, callback, errorCallBack, loader, notify) => null,
  onExecuteCLanguage: (data, callback, errorCallBack, loader, notify) => null,
});

export const CodeExecutionContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();
  const baseUrl = "/execute";

  const onExecuteJava = async (
    data,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/java",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onExecuteCPP = async (
    data,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/cpp",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onExecuteCLanguage = async (
    data,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/c-language",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  return (
    <CodeExecutionContext.Provider
      value={{ onExecuteJava, onExecuteCPP, onExecuteCLanguage }}>
      {children}
    </CodeExecutionContext.Provider>
  );
};
