import React, { useState, useContext, useEffect } from "react";
import classes from "./StudentLeaderboard.module.css";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LeaderboardsContext } from "../../../services/Leaderboards/Leaderboards.context";
import FirstMedalIcon from "../../../assets/icons/first-medal-icon.svg";
import SecondMedalIcon from "../../../assets/icons/second-medal-icon.svg";
import ThirdMedalIcon from "../../../assets/icons/third-medal-icon.svg";
import blankImg from "../../../assets/blank-image.png";
import Points from "../../../assets/icons/points.svg";
import Coins from "../../../assets/icons/coins.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const StudentLeaderboard = ({ onClickBack }) => {
  const [activeTab, setActiveTab] = useState("Daily");
  const {
    onGetYesterdaysLeaderboard,
    onGetTodaysLeaderboard,
    onGetLastweekLeaderboard,
    onGetThisWeekLeaderboardList,
  } = useContext(LeaderboardsContext);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardList, setLeaderboardDataList] = useState([]);

  useEffect(() => {
    if (activeTab === "Daily") {
      onGetYesterdaysLeaderboard(
        (result) => {
          if (result && result.leaderboard) {
            setLeaderboardData(result.leaderboard);
          }
        },
        (e) => {
          console.log(e);
        },
        false,
        false,
      );
      onGetTodaysLeaderboard(
        (result) => {
          if (result && result.users) {
            setLeaderboardDataList(result.users);
          }
        },
        (e) => {
          console.log(e);
        },
        false,
        false,
      );
    } else {
      onGetLastweekLeaderboard(
        (result) => {
          if (result && result.leaderboard) {
            setLeaderboardData(result.leaderboard);
          }
        },
        (e) => {
          console.log(e);
        },
        false,
        false,
      );
      onGetThisWeekLeaderboardList(
        (result) => {
          if (result && result.users) {
            setLeaderboardDataList(result.users);
          }
        },
        (e) => {
          console.log(e);
        },
        false,
        false,
      );
    }
  }, [activeTab]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format date as "DD MMM"
  const formatDate = (dateStr) => {
    const day = dateStr.slice(8, 10);
    const month = months[parseInt(dateStr.slice(5, 7)) - 1];
    return `${day} ${month}`;
  };

  const formateThisWeekDate = (date) => {
    const day = date.getDate();
    const month = months[date.getMonth()];
    return `${day} ${month}`;
  };

  const today = new Date();
  const day = today.getDate();
  const month = months[today.getMonth()];

  const onTabChange = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };
  const isDailyTab = activeTab === "Daily";

  const firstDayOfWeek = new Date(
    today.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1),
    ),
  );
  const lastDayOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay() + 7),
  );

  return (
    <div>
      <div>
        <div className={classes.headSectionContainer}>
          <div className={classes.backBtn} onClick={onClickBack}>
            <ArrowBackIosIcon height={20} width={20} /> Back
          </div>
          <div className={classes.leaderboardHeader}>
            <div className={classes.leaderboardIcon}>
              <div>
                <LeaderboardIcon />
              </div>
            </div>
            <div className={classes.leaderboardText}>Leaderboard</div>
          </div>
          <div className={classes.tabsListWrapper}>
            <div className={classes.tabsContainer}>
              <span
                onClick={() => onTabChange("Daily")}
                className={`${classes.tab} ${
                  isDailyTab ? classes.active : ""
                }`}>
                Daily
              </span>
              <span
                onClick={() => onTabChange("Weekly")}
                className={`${classes.tab} ${
                  !isDailyTab ? classes.active : ""
                }`}>
                Weekly
              </span>
            </div>
          </div>
        </div>

        <div style={{ padding: "0 24px 24px" }}>
          <div
            className={
              isDailyTab
                ? classes.dailyLeaderboardCard
                : classes.weeklyLeaderboardCard
            }>
            <div
              className={
                isDailyTab
                  ? classes.ribbonContainer
                  : classes.weekRibbonContainer
              }>
              Leaderboard Winners
            </div>
            <div
              className={`${classes.calenderTagContainer} ${
                !isDailyTab ? classes.calenderTagWeek : ""
              }`}>
              <div>
                <div
                  className={`${classes.iconContainer} ${
                    !isDailyTab ? classes.iconForWeek : ""
                  }`}>
                  <CalendarMonthIcon width={15} height={15} />
                </div>
                {isDailyTab && leaderboardData?.date ? (
                  <span className={classes.dateText}>
                    {formatDate(leaderboardData.date)}
                  </span>
                ) : (
                  leaderboardData?.startDate && (
                    <span
                      className={`${classes.dateText} ${
                        !isDailyTab ? classes.dateTextForWeek : ""
                      }`}>
                      {formatDate(leaderboardData.startDate)}
                      {` - `}
                      {formatDate(leaderboardData.endDate)}
                    </span>
                  )
                )}
              </div>
            </div>
            <div className={classes.winners}>
              {leaderboardData?.top_three?.length > 0 &&
                leaderboardData.top_three.map((student, i) => {
                  return (
                    <div key={i} className={classes.winnerRowCard}>
                      <div style={{ marginRight: "5px" }}>
                        <img
                          alt="rank icon"
                          width={30}
                          height={30}
                          src={
                            i === 0
                              ? FirstMedalIcon
                              : i === 1
                              ? SecondMedalIcon
                              : ThirdMedalIcon
                          }
                        />
                      </div>
                      <div style={{ marginRight: "5px" }}>
                        <img
                          alt="user_blank"
                          width={30}
                          height={30}
                          src={blankImg}
                        />
                      </div>
                      <div
                        className={
                          isDailyTab
                            ? classes.studentName
                            : classes.studentNameWeek
                        }>
                        {student.user.firstName} {student.user.lastName}
                      </div>
                      <div className={classes.pointsContainer}>
                        <div className={classes.pointsIcon}>
                          <img
                            src={Points}
                            alt="points"
                            width={18}
                            height={18}
                          />
                        </div>
                        <div style={{ marginTop: "2px", fontSize: "14px" }}>{student.points}</div>
                      </div>
                      <div className={classes.coinsContainer}>
                        <div className={classes.coinsIcon}>
                          <img src={Coins} alt="coins" width={18} height={18} />
                        </div>
                        <div style={{ marginTop: "2px", fontSize: "14px" }}>{student.coins}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.headerTextTodays}>
        <span className={classes.lbtext}>
          {isDailyTab ? "Today's Leaderboard" : "Weekly Leaderboard"}
        </span>
        <span className={classes.lbdateText}>
          {isDailyTab
            ? `${day} ${month}`
            : `${formateThisWeekDate(firstDayOfWeek)} - ${formateThisWeekDate(
                lastDayOfWeek,
              )}`}
        </span>
      </div>
      <div className={classes.outerDiv}>
        <div className={classes.listDiv}>
          {leaderboardList?.length > 0 &&
            leaderboardList.map((student, i) => {
              return (
                <div className={classes.studentRow} key={i}>
                  <div className={classes.nameContainer}>
                    <div className={classes.rankContainer}>{i + 1}</div>
                    <div>
                      <img src={blankImg} alt="blank" width={25} height={25} />
                    </div>
                    <div className={classes.studentNameText}>
                      {student.firstName} {student.lastName}
                    </div>
                  </div>
                  <div className={classes.pointsContainer}>
                    <div className={classes.pointsIcon}>
                      <img src={Points} alt="points" width={18} height={18} />
                    </div>
                    <div style={{ marginTop: "2px", fontSize: "14px" }}>
                      {isDailyTab ? student.todaysPoints : student.weekPoints}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StudentLeaderboard;
