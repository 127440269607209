import React, { useState, useContext, useRef } from "react";
import ReactModal from "react-modal";
import classes from "./StudentSearchModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { GlobalSearchContext } from "../../../services/GlobalSearch/GlobalSearch.context";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

ReactModal.setAppElement("#root");

const StudentSearchModal = ({ showSearchModel, handleCloseSearchModel }) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const { onGlobalSearch } = useContext(GlobalSearchContext);
  const [showTopSearches, setToggleTopSearches] = useState(true);
  const [quizzes, setQuizzes] = useState(null);
  const [courses, setCourses] = useState(null);
  const [resources, setResources] = useState(null);
  const searchInputAfterSearch = useRef("");
  const [activeTab, setActiveTab] = useState("quizzes");
  const navigate = useNavigate();

  const handleCloseModal = () => {
    handleCloseSearchModel();
  };

  const inputChangeHandler = (e) => {
    setSearchInputValue(e.target.value);
  };

  const onClickSuggestion = (suggestion) => {
    setSearchInputValue(suggestion.text);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchInputValue.length >= 4) {
      const query = `?searchKeyword=${searchInputValue}`;
      onGlobalSearch(
        query,
        (result) => {
          if (result && result.quizzes && result.resources && result.courses) {
            setQuizzes(result.quizzes);
            setResources(result.resources);
            setCourses(result.courses);
            setToggleTopSearches(false);
            searchInputAfterSearch.current = searchInputValue;
          }
        },
        (error) => {
          console.log(error);
        },
        true,
        false,
      );
    }
  };

  const suggestions = [
    {
      id: 1,
      text: "Assessment - Python",
    },
    {
      id: 2,
      text: "Java Material",
    },
    {
      id: 3,
      text: "Cloud & Devops",
    },
    {
      id: 4,
      text: "SQL Mastery",
    },
  ];

  const tabs = [
    {
      id: 1,
      text: "Quizzes",
      active: "quizzes",
    },
    {
      id: 2,
      text: "Courses",
      active: "courses",
    },
    {
      id: 3,
      text: "Resources",
      active: "resources",
    },
  ];

  const noResultsElement = () => {
    return (
      <div className={classes.noResults}>
        <div>No results in this section</div>
      </div>
    );
  };

  const isQuizzesTab = activeTab === "quizzes";
  const isCoursesTab = activeTab === "courses";
  const isResourcesTab = activeTab === "resources";

  return (
    <ReactModal
      isOpen={showSearchModel}
      onRequestClose={handleCloseSearchModel}
      contentLabel="Search modal"
      overlayClassName={classes.modalOverlay}
      className={classes.modalContent}>
      <div className={classes.searchModelMain}>
        <div className={classes.closeContainer}>
          <div
            className={classes.closeIconContainer}
            onClick={handleCloseModal}>
            <CloseIcon width={22} height={22} />
          </div>
        </div>
        <div className={classes.globalSearchContainer}>
          <SearchIcon width={22} height={22} />
          <input
            value={searchInputValue}
            className={classes.globalSearchInput}
            type="text"
            placeholder="Search for quiz, resource.."
            onKeyDown={handleKeyPress}
            onChange={inputChangeHandler}
          />
        </div>
        <div className={classes.intructionTextContainer}>
          <div className={classes.intructionText}>
            Please enter atleast 4 letters to search
          </div>
        </div>
        {showTopSearches && (
          <div className={classes.suggestionContainer}>
            <div className={classes.topSearchText}>Top searches*</div>
            <div className={classes.suggestionWrapper}>
              {suggestions.map((suggestion) => {
                return (
                  <div
                    onClick={() => onClickSuggestion(suggestion)}
                    className={classes.suggestion}
                    key={suggestion.id}>
                    "{suggestion.text}"
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!showTopSearches && (
          <div className={classes.resultsContainer}>
            <div className={classes.searchResultsText}>
              Showing search results for{" "}
              <span className={classes.searchText}>
                "{searchInputAfterSearch.current}"
              </span>
            </div>
            <div className={classes.tabsContainer}>
              {tabs.map((tab) => (
                <div
                  onClick={() => {
                    setActiveTab(tab.active);
                  }}
                  className={`${classes.tab} ${
                    tab.active === activeTab ? classes.activeTab : ""
                  }`}
                  key={tab.id}>
                  {tab.text}
                </div>
              ))}
            </div>
            <div>
              {isQuizzesTab && (
                <div>
                  {quizzes.length === 0 ? (
                    noResultsElement()
                  ) : (
                    <div className={classes.tabContent}>
                      {quizzes.map((quiz) => {
                        return (
                          <div
                            onClick={() => {
                              if (quiz.isNewQuizSchema) {
                                navigate(
                                  `/student/quiz-new/start?id=${quiz._id}`,
                                );
                              } else {
                                navigate(`/student/quiz/start?id=${quiz._id}`);
                              }
                            }}
                            className={classes.quiz}
                            key={quiz._id}>
                            <div className={classes.quizText}>{quiz.name}</div>
                            <div>
                              <ArrowRightAltIcon width={22} height={22} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              {isCoursesTab && (
                <div>
                  {courses.length === 0 ? (
                    noResultsElement()
                  ) : (
                    <div className={classes.tabContent}>
                      {courses.map((course) => {
                        return (
                          <div
                            onClick={() => {
                              navigate(
                                `/student/courses/view?id=${course._id}`,
                              );
                            }}
                            className={classes.course}
                            key={course._id}>
                            <div className={classes.courseText}>
                              {course.title}
                            </div>
                            <div>
                              <ArrowRightAltIcon width={22} height={22} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              {isResourcesTab && (
                <div>
                  {resources.length === 0 ? (
                    noResultsElement()
                  ) : (
                    <div className={classes.tabContent}>
                      {resources.map((resource) => {
                        return (
                          <div
                            onClick={() => {
                              navigate(`/student/resources`);
                            }}
                            className={classes.resource}
                            key={resource._id}>
                            <div className={classes.resourceText}>
                              {resource.title}
                            </div>
                            <div>
                              <ArrowRightAltIcon width={22} height={22} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default StudentSearchModal;
