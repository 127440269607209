import { useContext, useEffect, useState } from "react";
import classes from "./Signin.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthenticationContext } from "../../../services/Authentication/Authentication.context";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SignInIcon from "@mui/icons-material/Login";
import ResetIcon from "@mui/icons-material/RestartAlt";
import Swal from "sweetalert2";
import GoogleLogin, { useGoogleLogout } from "react-google-login";
import { userRole, trainerRole } from "../../../utility/helper";
import Footer from "../../../shared/Footer/Footer";
import LogosImg from "../../../assets/logos.jpg"

const errors = {
  emailRequired: "Email required",
  passwordRequired: "Password required",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  email: {
    ...commonInputFieldProps,
  },
  password: {
    ...commonInputFieldProps,
  },
};

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Signin = ({ title }) => {
  const [inputs, setInputs] = useState(defaultInputState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [forgotPassModal, setForgotPassModal] = useState(false);
  const [forgotPassLoading, setForgotPassLoading] = useState(false);
  const [forgotPassEmail, setForgotPassEmail] = useState(null);
  const [forgotPassError, setForgotPassError] = useState(null);
  const [forgotPassSuccess, setForgotPassSuccess] = useState(null);
  let redirect = searchParams.get("redirect");

  const {
    onSignin,
    onLogout,
    onGoogleSignin,
    onResetPassword,
    onResendVerificationLink,
  } = useContext(AuthenticationContext);

  useEffect(() => {
    document.title = title;
  }, []);

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };

    const { email, password } = inputs;

    if (!email.value.trim()) {
      setErrorMessage("email", errors.emailRequired);
    }
    if (!password.value.trim()) {
      setErrorMessage("password", errors.passwordRequired);
    }

    let data = {
      email: email.value.trim(),
      password: password.value,
    };

    if (!hadErrors) {
      setLoading(true);
      onSignin(
        data,
        (res) => {
          setLoading(false);
          redirect
            ? navigate("/" + redirect)
            : res.userData?.role === userRole
            ? navigate("/student/dashboard")
            : res.userData?.role === trainerRole
            ? navigate("/dashboard/reports")
            : navigate("/dashboard/users");
        },
        (error) => {
          console.log(error);
          if (error?.status && error.status === "notVerified") {
            Swal.fire({
              title: error.message,
              text: "Click below to resend verification link!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Send Verification Link!",
            }).then((result) => {
              if (result.isConfirmed) {
                onResendVerificationLink(
                  { email: email.value },
                  (result) => {
                    Swal.fire("Done", result.message, "success");
                  },
                  (err) => {
                    Swal.fire("Failed", err?.message, "error");
                  },
                  true,
                  false,
                );
              }
            });
            setLoading(false);
            return;
          }
          Swal.fire("Sign In Failed", error?.message, "error");
          setLoading(false);
        },
        false,
        false,
      );
    }
  };

  const onCloseForgotPass = () => {
    setForgotPassEmail(null);
    setForgotPassError(null);
    setForgotPassSuccess(null);
    setForgotPassModal(false);
  };

  const onClickForgotPassword = () => {
    let hadErrors = false;
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (!emailRegex.test(forgotPassEmail)) {
      setForgotPassError("Invaid Email Address");
      hadErrors = true;
    }

    let data = {
      email: forgotPassEmail,
    };
    if (!hadErrors) {
      setForgotPassLoading(true);
      onResetPassword(
        data,
        (result) => {
          setForgotPassEmail(null);
          setForgotPassSuccess(result.message);
          setForgotPassLoading(false);
        },
        (error) => {
          setForgotPassLoading(false);
          setForgotPassError(error.message);
        },
        false,
        false,
      );
    }
  };

  const onGoogleSigninSuccess = (res) => {
    let data = {
      token: res.tokenId,
    };
    onGoogleSignin(
      data,
      (result) => {
        setLoading(false);
        redirect ? navigate("/" + redirect) : navigate("/student/quiz");
      },
      (error) => {
        Swal.fire("Sign In Failed", error?.message, "error");
        signOut();
      },
      true,
      false,
    );
  };

  const onGoogleError = (e) => {
    console.log(e, "google error");
    Swal.fire("Google Authentication Failed", e?.error, "error");
  };

  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENT_ID,
    onLogoutSuccess: onLogout,
    onFailure: onGoogleError,
  });

  return (
    <div>
      <div className={classes.main}>
        <div className={classes.container}>
          <div>
            <div className={classes.leftPanel}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../../../assets/logo.png")}
                  alt="Datavalley logo"
                />
              </div>
              <div>
                <div className={classes.title}> Welcome back!</div>
              </div>
              <div className={classes.continueText}>
                Login to continue learning
              </div>

              <Box
                component="form"
                noValidate
                onSubmit={onSubmitForm.bind(this)}
                sx={{ mt: "20px" }}>
                <TextField
                  error={inputs.email.error}
                  helperText={inputs.email.errorMessage}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={inputs.email.value}
                  onChange={onValueChangeHandler}
                />

                <TextField
                  error={inputs.password.error}
                  helperText={inputs.password.errorMessage}
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  value={inputs.password.value}
                  onChange={onValueChangeHandler}
                />
                <div
                  className={classes.forgotPass}
                  onClick={() => setForgotPassModal(true)}>
                  Forgot Password?
                </div>
                <LoadingButton
                  type="submit"
                  fullWidth
                  loadingPosition="end"
                  endIcon={<SignInIcon />}
                  color="primary"
                  loading={loading}
                  loadingIndicator={"Authenticating..."}
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    backgroundColor: "rgba(76, 40, 175, 0.85)",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgba(76, 40, 175, 0.94)",
                    },
                  }}>
                  {!loading && "Sign In"}
                </LoadingButton>
                <Typography style={{ fontSize: "14px" }} component="p">
                  <span className={classes.noAccountText}>Don't have an account? </span>
                  <Link
                    to={"/auth/signup"}
                    style={{
                      color: "rgba(76, 40, 175, 0.94)",
                    }}>
                    Sign Up
                  </Link>
                </Typography>
              </Box>
              <br />
              {/* <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={(res) => onGoogleSigninSuccess(res)}
        onFailure={(e) => onGoogleError(e)}
        isSignedIn={true}
      /> */}

              <Dialog open={forgotPassModal} onClose={onCloseForgotPass}>
                <DialogTitle>Reset Your Password</DialogTitle>
                <DialogContent>
                  <DialogContentText color={forgotPassSuccess ? "green" : ""}>
                    {forgotPassSuccess
                      ? forgotPassSuccess
                      : "Kindly provide your email address so that we can send you the password reset verification link."}
                  </DialogContentText>

                  <TextField
                    autoFocus
                    error={forgotPassError}
                    helperText={forgotPassError}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={forgotPassEmail}
                    onChange={(e) => {
                      setForgotPassError(null);
                      setForgotPassEmail(e.target.value.trim());
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseForgotPass}>Cancel</Button>
                  <LoadingButton
                    loadingPosition="end"
                    color="primary"
                    endIcon={<ResetIcon />}
                    onClick={onClickForgotPassword}
                    loading={forgotPassLoading}
                    loadingIndicator={"Sending link..."}
                    variant="contained"
                    sx={{ mb: 2, mt: 2, minWidth: 200 }}>
                    {!forgotPassLoading && "Reset Password"}
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </div>
            <div className={classes.logosContainer}>
              <div className={classes.imgContainer}>
                <div className={classes.bgImg}>
                </div>
                <img className={classes.logosImg} src={LogosImg} alt="logos" />
              </div>
              <div className={classes.bgWhite}></div>
            </div>
          </div>
        </div>

        <div className={classes.rightPanel}>
          <div className={classes["background-wrapper"]}>
            <div className={classes["container-fluid"]}>
              <div className={classes["right-heading"]}>
                Global online learning hub
              </div>
              <div className={classes["content"]}>
                Get started with your immersive learning journey now!
                <a
                  target="_blank"
                  className={classes.learnMore}
                  href="https://datavalley.ai/"
                  rel="noopener noreferrer">
                  <span className={classes.learn}>Learn more</span>{" "}
                  <span className={classes.arrow}>→</span>
                </a>
                {/* "Leading data empowerment provider.
                  Cutting-edge training solutions.
                  Global impact focus." */}
                <div className={classes["additional-content"]}>
                  <p style={{ marginBottom: "8px" }}>Global impact focus</p>
                  <p style={{ marginBottom: "8px" }}>
                    Cutting-edge training solutions
                  </p>
                  <p style={{ marginBottom: "8px" }}>
                    Personalized learning experiences
                  </p>
                  <p style={{ marginBottom: "8px" }}>
                    Collaboration and Tracking learner progress
                  </p>
                  <p style={{ marginBottom: "8px" }}>
                    Leading data empowerment provider
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.Foot}>
        <Footer />
      </div>
    </div>
  );
};

export default Signin;
