import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { BatchesContextProvider } from "../../../services/Batches/Batches.context";
import { DSAContextProvider } from "../../../services/DSA/DSA.context";

const DSAHome = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);
  return (
    <>
      <BatchesContextProvider>
        <DSAContextProvider>
          <Outlet />
        </DSAContextProvider>
      </BatchesContextProvider>
    </>
  );
};

export default DSAHome;
