import React, { useContext, useEffect, useState, useRef } from "react";
import classes from "./StudentDSAPractice.module.css";
import { DSAContext } from "../../../services/DSA/DSA.context";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

const StudentDSAPractice = ({ title }) => {
  const { onGetAllDSA } = useContext(DSAContext);
  const [allDSA, setAllDSA] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalDSA = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
    getAllDSA();
  }, [page, rowsPerPage]);

  const getAllDSA = (query = `?page=${page + 1}&limit=${rowsPerPage}`) => {
    onGetAllDSA(
      query,
      (result) => {
        console.log(result);
        if (result && result.dsa) {
          setAllDSA(result.dsa);
          totalDSA.current = result.totalCount;
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.mainContainer}>
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <h3 className={classes.pageTitle}>DSA Questions - {allDSA.length}</h3>
        </Box>

        {allDSA.length === 0 && (
          <NotFoundContainer>
            <div>
              <NotFoundText>No DSA available</NotFoundText>
              <NotFoundContainerImage
                src={require("../../../assets/no_data.png")}
                alt="..."
              />
            </div>
          </NotFoundContainer>
        )}

        {allDSA.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Difficulty Level</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDSA.map((dsa, index) => {
                  let { title, _id, difficultyLevel } = dsa;
                  const isEasyLevel = difficultyLevel === "easy";
                  const isMediumLevel = difficultyLevel === "medium";
                  const isHardLevel = difficultyLevel === "hard";
                  return (
                    <TableRow
                      onClick={() => navigate("view?id=" + _id)}
                      key={_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}>
                      <TableCell component="th" scope="row">
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell>{title}</TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        <div
                          className={`${classes.difficultyLevel} ${
                            isEasyLevel
                              ? classes.easyLevel
                              : isMediumLevel
                              ? classes.mediumLevel
                              : classes.hardLevel
                          }`}>
                          {difficultyLevel}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20]}
                    labelRowsPerPage="DSA per Page"
                    count={totalDSA.current}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default StudentDSAPractice;
