import React, { useContext, useEffect, useState, useRef } from "react";
import { CourseNewContext } from "../../../services/CoursesNew/CoursesNew.context";
import { formatVideoDuration } from "../../../utility/helper";
import { useNavigate } from "react-router-dom";
import classes from "./StudentDashboard.module.css";
import {
  NotFoundContainer,
  NotFoundText,
  NotFoundContainerImage,
} from "../../../styles";
import StudentDashboardSidebar from "../StudentDashboardSidebar/StudentDashboardSidebar";
import CircularProgressBar from "../../CircularProgressBar/CircularProgressBar";
import Notification from "../../../assets/icons/notification.svg";
import { NotificationsContext } from "../../../services/Notification/Notification.context";
import StudentNotifications from "../StudentNotifications/StudentNotifications";
import StudentAllNotifications from "../StudentAllNotifications/StudentAllNotifications";
import { QuizNewContext } from "../../../services/QuizNew/QuizNew.context";

const StudentDashboard = () => {
  const { onGetCourses } = useContext(CourseNewContext);
  const { onGetQuizes } = useContext(QuizNewContext);
  const { onGetNotifications, onMarkNotificationAsRead } =
    useContext(NotificationsContext);
  const [courses, setCourses] = useState([]);
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videosCompletedForEachCourse, setVideosCompletedForEachCourse] =
    useState([]);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(null);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const notificationRef = useRef(null);
  const clickCount = useRef(0);
  const totalUnreadNotifications = useRef(null);

  useEffect(() => {
    let query = `?page=${1}&limit=${3}`;
    onGetCourses(
      query,
      (result) => {
        setLoading(false);
        if (result && result.courses) {
          setCourses(result.courses);
          setVideosCompletedForEachCourseHandler(result.courses);
        }
      },
      true,
      false,
    );
    onGetQuizes(
      query,
      (result) => {
        setLoading(false);
        setQuizes(result.quizzes);
      },
      true,
      false,
    );
    getNotifications();
  }, []);

  const getNotifications = (query = `?limit=10`) => {
    onGetNotifications(
      query,
      (result) => {
        if (result?.notifications) {
          setNotifications(result.notifications);
          totalUnreadNotifications.current = result.totalUnread;
        }
      },
      (e) => {
        console.log(e);
      },
      false,
      false,
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        showNotificationPopup
      ) {
        setShowNotificationPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setVideosCompletedForEachCourseHandler = (courses) => {
    const tempArrVideosCompleted = [];
    courses.forEach((course) => {
      let videosCompletedForCurrSection = 0;
      course.content.forEach((section) => {
        section.videos.forEach((video) => {
          if (video.watchedByCurrentUser) {
            videosCompletedForCurrSection++;
          }
        });
      });
      tempArrVideosCompleted.push({
        videosCompleted: videosCompletedForCurrSection,
      });
    });
    setVideosCompletedForEachCourse(tempArrVideosCompleted);
  };

  const onClickViewCourse = (id) => {
    navigate(`/student/courses/view?id=${id}`);
  };
  const onClickViewQuiz = (id, isNewQuizSchema) => {
    if(isNewQuizSchema) {
      navigate(`/student/quiz-new/start?id=${id}`);
    }else {
      navigate(`/student/quiz/start?id=${id}`);
    }
  };

  const handleNotificationClick = async () => {
    setShowNotificationPopup(!showNotificationPopup);
    if (clickCount.current === 0) {
      onMarkNotificationAsRead(
        (result) => {
          clickCount.current += 1;
        },
        (e) => console.log(e),
        false,
        false,
      );
    }
    if (clickCount.current === 1 && showNotificationPopup) {
      getNotifications();
      clickCount.current += 1;
    }
  };

  const handleClickShowAll = () => {
    setShowAllNotifications(true);
    setShowNotificationPopup(false);
  };

  const handleClickBack = () => {
    setShowAllNotifications(false);
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        className={classes["hide-scrollbar"]}
        style={{
          flex: "60%",
          padding: "28px 16px 28px 28px",
          background: "rgb(241, 245, 249)",
          "overflow-y": "auto",
          "min-height": "100vh",
          paddingTop: showAllNotifications ? "0" : "28px",
        }}>
        {!showAllNotifications && (
          <div style={{ "min-height": "100vh" }}>
            {loading && <div></div>}

            {!courses.length && !quizes.length && !loading && (
              <NotFoundContainer>
                <div>
                  <NotFoundText>
                    You have not enrolled in any courses!
                  </NotFoundText>
                  <NotFoundContainerImage
                    src={require("../../../assets/no_data.png")}
                    alt="..."
                  />
                </div>
              </NotFoundContainer>
            )}
            {!loading && (courses.length > 0 || quizes.length > 0) && (
              <div className={classes.main}>
                {showNotificationPopup && (
                  <div
                    ref={notificationRef}
                    className={classes.notificationContainer}>
                    <StudentNotifications
                      handleClickShowAll={handleClickShowAll}
                      notifications={notifications}
                    />
                  </div>
                )}
                <span className={classes.notificationsCount}>
                  {totalUnreadNotifications.current}
                </span>
                <div className={classes.dashboardHeader}>
                  <h3 style={{ marginBottom: "0", color: "#666666" }}>
                    Welcome Back!
                  </h3>
                  <div
                    onClick={handleNotificationClick}
                    className={`${classes.notificationIcon} ${
                      showNotificationPopup
                        ? classes.notificationActiveIcon
                        : ""
                    }`}>
                    <img
                      height={21}
                      width={21}
                      src={Notification}
                      alt="notification"
                    />
                  </div>
                </div>
                <div className={classes.content}>
                  {courses.length > 0 && (
                    <div className={classes.container}>
                      <div className={classes.contentHead}>
                        <div className={classes.sectionTitle}>My courses</div>
                        <div
                          className={classes.seeAll}
                          onClick={() => navigate("/student/courses")}>
                          View all
                        </div>
                      </div>
                      <div>
                        {courses.map((course, i) => {
                          const courseCompletionPercentage = Math.floor(
                            (videosCompletedForEachCourse[i].videosCompleted /
                              course.totalLectures) *
                              100,
                          );
                          return (
                            <div className={classes.contentItem} key={i}>
                              <div className={classes.itemName}>
                                {course.title}
                              </div>
                              <div>
                                Duration:
                                <br />
                                <span className={classes.duration}>
                                  {formatVideoDuration(course.totalDuration)}
                                </span>
                              </div>
                              <div className={classes.progressbarContainer}>
                                <CircularProgressBar
                                  percentage={courseCompletionPercentage}
                                  total={100}
                                  isTextRequired={true}
                                  isPercentageReqired={true}
                                  backgroundColor={"#f3deb7"}
                                  fillColor={"#FB6D48"}
                                  width={45}
                                  height={45}
                                  circleWidth={3}
                                  textFontSize={"8px"}
                                  isTotalRequired={false}
                                />
                              </div>
                              <div
                                onClick={() => onClickViewCourse(course._id)}>
                                <button className={classes.viewBtn}>
                                  View course
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {quizes.length > 0 && (
                    <div className={classes.container}>
                      <div className={classes.contentHead}>
                        <div className={classes.sectionTitle}>My quizzes</div>
                        <div
                          className={classes.seeAll}
                          onClick={() => navigate("/student/quiz-new")}>
                          View all
                        </div>
                      </div>
                      <div>
                        {quizes.map((quiz, i) => {
                          return (
                            <div className={classes.contentItem} key={i}>
                              <div className={classes.quizName}>
                                {quiz.name}
                              </div>
                              <div
                                onClick={() =>
                                  onClickViewQuiz(
                                    quiz._id,
                                    quiz.isNewQuizSchema,
                                  )
                                }>
                                <button className={classes.viewBtn}>
                                  View quiz
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {showAllNotifications && (
          <div>
            <StudentAllNotifications handleClickBack={handleClickBack} />
          </div>
        )}
      </div>
      <div className={classes.dashboardSidebar} style={{ flex: "40%" }}>
        <StudentDashboardSidebar />
      </div>
    </div>
  );
};

export default StudentDashboard;
