/* eslint-disable jsx-a11y/alt-text */
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classes from "./StudentReportView.module.css";
import _ from "lodash";
import { ReportsContext } from "../../../services/Reports/Reports.context";
import moment from "moment";
import { formatTimeWithSeconds, scrollToTop } from "../../../utility/helper";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { QuizNewContext } from "../../../services/QuizNew/QuizNew.context";

ChartJS.register(
  ArcElement,
  Tooltip,
  Title,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const StudentReportView = ({ title }) => {
  const [report, setReport] = useState(null);
  const [orgReport, setOrgReport] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onGetReport } = useContext(ReportsContext);
  const { onGetQuiz } = useContext(QuizNewContext);
  const [filterBy, setFilterBy] = useState(null);
  const [filters, setFilters] = useState([]);
  const [performancePieData, setperformancePieData] = useState(null);

  const [percentage, setPercentage] = useState(0);
  const [marksObtained, setMarksObtained] = useState(0);

  const navigate = useNavigate();
  const noOfQuestionsRef = useRef(0);
  const [headerMapping, setHeaderMapping] = useState([]);
  const questions = useRef([]);
  const answers = useRef([])

  useEffect(() => {
    scrollToTop();
    let reportId = searchParams.get("id");
    if (!reportId) {
      navigate("/student/reports");
      return;
    }
    if (reportId) {
      onGetReport(
        reportId,
        (result) => {
          let reportData = result.report[0];
          let {
            correctAnswers,
            incorrectAnswers,
            unattemptedAnswers,
            reviewMarkedAnswers,
          } = reportData;
          if (!reportData) {
            navigate("/student/reports");
            return;
          }
          let filterValues = [
            {
              value: "all",
              label: `All Questions`,
            },
            {
              value: "correct",
              label: `Correct (${correctAnswers || 0})`,
            },
            {
              value: "incorrect",
              label: `Incorrect (${incorrectAnswers || 0})`,
            },
            {
              value: "unattempted",
              label: `Unattempted (${unattemptedAnswers || 0})`,
            },
            {
              value: "review",
              label: `Marked for Review (${reviewMarkedAnswers || 0})`,
            },
          ];
          setFilterBy(filterValues.find((v) => v.value === "all"));
          setFilters(filterValues);
          setReport(reportData);
          setOrgReport(reportData);
          if (reportData.isNewQuizSchema && reportData.quiz) {
            getQuizData(reportData.quiz);
            const allAnswers = [];
            reportData.answerGroups.forEach((answerGroup) => {
              if (answerGroup.answers.length) {
                answerGroup.answers.forEach((answer) => {
                  allAnswers.push(answer);
                });
              }
            });
            answers.current = allAnswers;
          } else {
            document.title = `Report | ${reportData.quiz.name}`;
            setQuiz(reportData.quiz);
          }
          onSetPerformanceChartData(reportData);
        },
        () => {
          navigate("/student/reports");
        },
        true,
        false
      );
    }
  }, []);

  // animation
  useEffect(() => {
    if (!report) {
      return;
    }
    const animationDuration = 1000; // milliseconds
    const framesPerSecond = 100;
    const increment =
      report.percentage / (animationDuration / 1000) / framesPerSecond;
    const marksIncrement =
      report.marksObtained / (animationDuration / 1000) / framesPerSecond;
    let currentPercentage = 0;
    let currentMarks = 0;

    const updatePercentage = () => {
      currentPercentage += increment;
      setPercentage(currentPercentage);
      if (currentPercentage < report.percentage) {
        requestAnimationFrame(updatePercentage);
      } else {
        setPercentage(report.percentage);
      }
    };

    const updateMarks = () => {
      currentMarks += marksIncrement;
      setMarksObtained(currentMarks);

      if (currentMarks < report.marksObtained) {
        requestAnimationFrame(updateMarks);
      } else {
        setMarksObtained(report.marksObtained);
      }
    };

    // Start the animation
    updatePercentage();
    updateMarks();
    // Cleanup on component unmount
    return () => {
      cancelAnimationFrame(updatePercentage);
      cancelAnimationFrame(updateMarks);
    };
  }, [report]);

  const getQuizData = (quizId) => {
    onGetQuiz(
      quizId,
      (result) => {
        if (result && result.quiz) {
          document.title = `Report | ${result.quiz.name}`;
          scrollToTop();
          setQuiz(result.quiz);
          let noOfQuestions = 0;
          let headers = [];
          const allQuestions = [];

          result.quiz.questionGroups.forEach((questionGroup) => {
            noOfQuestions += questionGroup.questions.length;
            if (questionGroup.questions.length) {
              questionGroup.questions.forEach((question) => {
                headers.push(questionGroup.groupHeader);
                allQuestions.push(question);
              });
            }
          });

          noOfQuestionsRef.current = noOfQuestions;
          setHeaderMapping(headers);
          questions.current = allQuestions;
        }
      },
      (error) => {
        navigate("/student/reports");
      },
      true,
      false,
    );
  };

  function calculateLateSubmission(dueDateStr, submissionDateStr) {
    const dueDate = new Date(dueDateStr);
    const submissionDate = new Date(submissionDateStr);

    const timeDifference = submissionDate - dueDate;
    const lateMilliseconds = Math.max(timeDifference, 0);

    const days = Math.floor(lateMilliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (lateMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (lateMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((lateMilliseconds % (1000 * 60)) / 1000);

    let lateMessage = "";
    if (days > 0) {
      lateMessage += `${days}d `;
    }
    if (hours > 0) {
      lateMessage += `${hours}h `;
    }
    if (minutes > 0) {
      lateMessage += `${minutes}m `;
    }
    if (seconds > 0) {
      lateMessage += `${seconds}s`;
    }

    return lateMessage.trim();
  }

  const isLateSubmission = () => {
    if (quiz.dueDate) {
      return moment(report.submittedOn).isAfter(quiz.dueDate) && quiz.dueDate;
    }
    return false;
  };

  const fillInTheBlank = (question, answers) => {
    let filledQuestion = question;
    let index = 0;
    if (!answers || answers.length === 0) {
      filledQuestion = filledQuestion.replaceAll("#blank#", () => {
        return "<u> ______ </u>";
      });
    } else {
      filledQuestion = filledQuestion.replace(/#blank#/g, () => {
        const answer = answers[index++];
        return answer ? `<u><b>${answer}</u></b>` : "<u>______</u>";
      });
    }

    return filledQuestion;
  };

  const onFilterValueChange = (newValue) => {
    setFilterBy(newValue);
    if (quiz && newValue && newValue.value) {
      let value = newValue.value;
      let currentReport = _.cloneDeep(orgReport);
      let currentQuiz = currentReport.quiz;
      let currentQuestions = currentQuiz.questions;
      let currentAnswers = currentReport.answers;
      let finalAnswers = [];
      let finalQuestions = [];
      if (value === "correct") {
        finalAnswers = currentAnswers.filter((a) => a.isCorrect);
      } else if (value === "incorrect") {
        finalAnswers = currentAnswers.filter(
          (a) => !a.isCorrect && !a.unattempted
        );
      } else if (value === "unattempted") {
        finalAnswers = currentAnswers.filter((a) => a.unattempted);
      } else if (value === "review") {
        finalAnswers = currentAnswers.filter((a) => a.reviewMarked);
      } else if (value === "all") {
        finalAnswers = currentAnswers;
      }
      finalAnswers.forEach((a) => {
        let question = currentQuestions.find((q) => q._id === a.questionId);
        finalQuestions.push(question);
      });
      currentReport.answers = finalAnswers;
      currentReport.quiz.questions = finalQuestions;
      setReport(currentReport);
      setQuiz(currentReport.quiz);
    } else {
      setReport(orgReport);
      setQuiz(orgReport.quiz);
    }

    return;
  };

  const onSetPerformanceChartData = (report) => {
    let {
      correctAnswers,
      incorrectAnswers,
      unattemptedAnswers,
      reviewMarkedAnswers,
    } = report;
    const performancePie = {
      labels: [
        "Correct Answers",
        "Incorrect Answers",
        "Unattempted Answers",
        "Marked For Review",
      ],
      datasets: [
        {
          label: "Total",
          data: [
            correctAnswers || 0,
            incorrectAnswers || 0,
            unattemptedAnswers || 0,
            reviewMarkedAnswers || 0,
          ],
          backgroundColor: [
            "rgba(76, 174, 78, 0.5)",
            "rgba(245, 66, 54, 0.5)",
            "rgba(50, 152, 252, 0.5)",
            "rgba(254, 158, 104, 0.5)",
          ],
          borderColor: [
            "rgba(76, 174, 78, 0.5)",
            "rgba(245, 66, 54, 0.5)",
            "rgba(50, 152, 252, 0.5)",
            "rgba(254, 158, 104, 0.5)",
          ],
          borderWidth: 1,
        },
      ],
    };
    setperformancePieData(performancePie);
  };

  const getTheCurrentHeaderText = (questionIndex) => {
    if(quiz.isNewQuizSchema) {
      const headerText = headerMapping[questionIndex];
      const prevHeaderText = headerMapping[questionIndex - 1];
      return headerText !== prevHeaderText ? headerText : "";
    }
    return ""
  };

  return orgReport && quiz ? (
    <section className={classes.reportViewMain}>
      <div className={classes.quizName}>
        <h4>
        <span style={{ fontSize: "20px", color: "rgb(71, 85, 105)" }}>
            Quiz:{" "}
          </span>
          {quiz.name}
        </h4>
      </div>
      <Card className={`${classes.card} ${classes.reportView}`}>
        <div className={`${classes.cardHeader} ${classes.borderTopLeftRight20}`}>
          <p className={classes.date}>
            Completed on -{" "}
            <span
              className={`${isLateSubmission() ? classes.lateSubmission : ""}`}
            >
              {moment(report.submittedOn).format(
                "ddd,DD MMM YYYY - hh:mm:ss A"
              )}
              <br />
              {isLateSubmission() &&
                `Late Submission By -  ${calculateLateSubmission(
                  quiz.dueDate,
                  report.submittedOn
                )}`}
            </span>
          </p>
        </div>
        <div className={classes.cardContent}>
          <div className={classes.overviewItems}>
            <div className={classes.overview}>
              <img
                className={classes.icon}
                src={require("../../../assets/reports-marks-obtained.png")}
                alt="..."
              />
              <p className={classes.title}>{`${Math.round(marksObtained)}/${
                quiz.totalMarks
              }`}</p>
              <p className={classes.desc}>Marks Obtained</p>
            </div>
            <div className={classes.overview}>
              <img
                className={classes.icon}
                src={require("../../../assets/reports-scrore.png")}
                alt="..."
              />
              <p className={classes.title}>{Math.round(percentage)}%</p>
              <p className={classes.desc}>Your Score</p>
            </div>
            <div className={classes.overview}>
              <img
                className={classes.icon}
                src={require("../../../assets/reports-time-taken.png")}
                alt="..."
              />
              <p className={classes.title}>
                {formatTimeWithSeconds(report.timeSpentInSeconds)}
              </p>
              <p className={classes.desc}>Time Taken</p>
            </div>
            <div className={classes.overview}>
              <img
                className={classes.icon}
                src={require("../../../assets/reports-pass-result.png")}
                alt="..."
              />
              <p
                className={classes.title}
                style={{
                  color: report.result === "pass" ? "green" : "red",
                }}
              >
                {_.toUpper(report.result)}
              </p>
              <p className={classes.desc}>Result</p>
            </div>
          </div>
        </div>
      </Card>

      <Card className={`${classes.card} ${classes.perfCard} ${classes.reportView} ${classes.borderTopLeftRight20}`}>
        <div className={`${classes.cardHeader} ${classes.borderTopLeftRight20}`}>
          <p className={classes.title}>Quiz Performance Report</p>
        </div>
        <Grid container>
          <Grid item md={8}>
            <div className={classes.cardContent}>
              <div className={classes.perfContainer}>
                {/* questions */}
                <div className={classes.perf}>
                  <p className={classes.perfTitle}>Total Questions</p>
                  <p className={classes.perfDesc}>{noOfQuestionsRef.current}</p>
                </div>

                {/* correct */}
                <div className={classes.perf}>
                  <p className={classes.perfTitle}>Correct Answers</p>
                  <p className={classes.perfDesc}>
                    {report.correctAnswers || 0}
                  </p>
                </div>

                {/* incorrect */}
                <div className={classes.perf}>
                  <p className={classes.perfTitle}>Incorrect Answers</p>
                  <p className={classes.perfDesc}>
                    {report.incorrectAnswers || 0}
                  </p>
                </div>

                {/* Unattempted */}
                <div className={classes.perf}>
                  <p className={classes.perfTitle}>Unattempted Answers</p>
                  <p className={classes.perfDesc}>
                    {report.unattemptedAnswers || 0}
                  </p>
                </div>

                {/* Review */}
                <div className={classes.perf}>
                  <p className={classes.perfTitle}>Marked For Review</p>
                  <p className={classes.perfDesc}>
                    {report.reviewMarkedAnswers || 0}
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={classes.perfChart}>
              <Pie
                data={performancePieData}
                options={{
                  ...chartOptions,
                  scales: {
                    ...chartOptions.scales,
                    y: {
                      ...chartOptions.scales.y,
                      ticks: {
                        display: false,
                        beginAtZero: true,
                      },
                    },
                    x: {
                      ...chartOptions.scales.x,
                      ticks: {
                        display: false,
                        beginAtZero: true,
                      },
                    },
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Card>

      {quiz && !quiz.isNewQuizSchema && (
        <Card
          className={`${classes.card} ${classes.reviewCard} ${classes.reportView}`}>
          <div
            className={`${classes.cardHeader} ${classes.borderTopLeftRight20}`}>
            <p className={classes.title}>Review the Answers</p>
            {filters && filters.length > 0 && (
              <Autocomplete
                disablePortal
                className="mt-1"
                options={filters}
                sx={{
                  minWidth: 250,
                }}
                value={filterBy || null}
                onChange={(e, newValue) => {
                  onFilterValueChange(newValue);
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Filter By "
                  />
                )}
              />
            )}
          </div>
        </Card>
      )}

      {quiz &&
        (!quiz.isNewQuizSchema ? quiz.questions : questions.current).map(
          (question, i) => {
        let { questionText, questionType, options, _id } = question;
        let answer = (quiz.isNewQuizSchema ? answers.current : report.answers).find(
          (a) => a.questionId === _id,
        );
        let {
          isCorrect,
          unattempted,
          reviewMarked,
          selectedOption,
          selectedOptions,
        } = answer;
        let correctAnswer = isCorrect && !unattempted && !reviewMarked;
        let correctAnswerAndReviewMarked =
          isCorrect && !unattempted && reviewMarked;

        let incorrectAnswer = !isCorrect && !unattempted && !reviewMarked;
        let incorrectAndReviewMarked =
          !isCorrect && reviewMarked && !unattempted;
        let unattemptedAnswer = unattempted && !reviewMarked;
        let unattemptedAndReviewMarked = unattempted && reviewMarked;
        let greenClass =
          correctAnswer ||
          unattemptedAnswer ||
          correctAnswerAndReviewMarked ||
          unattemptedAndReviewMarked;
        let blankCorrectAnswers = [];
        if (questionType === "fill_in_the_blank") {
          blankCorrectAnswers = options.map((o) => o.optionText);
        }

        return (
          <>
            {getTheCurrentHeaderText(i) && (
              <div className={classes.groupHeaderTitle}>
                <div>{getTheCurrentHeaderText(i)}</div>
              </div>
            )}
            <Card sx={{mt: "8px"}} key={i} className={`${classes.card} ${classes.reportView} ${classes.borderTopLeftRight20}`}>
              <div className={classes.qstnCardContent}>
                <div className={classes.questionContainer}>
                  {questionType !== "fill_in_the_blank" && (
                    <div className={classes.qstn}>{i+1}. {questionText}</div>
                  )}
                  {questionType === "fill_in_the_blank" && (
                    <div
                    dangerouslySetInnerHTML={{
                      __html: fillInTheBlank(questionText, selectedOptions || []),
                    }}
                    className={classes.qstn}
                    />
                  )}
                  <div className={`${greenClass ? classes.green : classes.red}`}>
                    {correctAnswer && <div className={`${classes.status}`}>Correct</div>}
                    {incorrectAnswer && (
                      <div className={`${classes.status}`}>Incorrect</div>
                    )}

                    {unattemptedAnswer && (
                      <div className={`${classes.statusText}`}>Unattempted</div>
                    )}

                    {correctAnswerAndReviewMarked && (
                      <div className={classes.statusContainer}>
                        <p className={`${classes.status}`}>Correct</p>
                        <p className={`${classes.statusText}`}>Marked for review</p>
                      </div>
                    )}

                    {incorrectAndReviewMarked && (
                      <div className={classes.statusContainer}>
                        <p className={`${classes.status}`}>Incorrect</p>
                        <p className={`${classes.statusText}`}>Marked for review</p>
                      </div>
                    )}

                    {unattemptedAndReviewMarked && (
                      <div className={`${classes.statusText}`}>
                        Unattempted Marked for review
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.optionsContainer}>
                  {["single_option"].includes(questionType) && (
                    <>
                      <FormControl>
                        <RadioGroup
                          name="radio-buttons-group"
                          value={selectedOption || null}
                        >
                          {options.map((o, i) => {
                            let { optionText, isCorrect } = o;
                            let label = optionText;
                            let correctOption = isCorrect;
                            const selected = optionText === selectedOption;
                            let inCorrectOption = selected && !isCorrect;

                            return (
                              <div key={i}>
                                <FormControlLabel
                                  value={optionText}
                                  control={<Radio />}
                                  label={
                                    <div className={classes.chipContainer}>
                                      <p className={classes.optionText}>
                                        {label}
                                      </p>
                                      {inCorrectOption && (
                                        <div
                                          className={`${classes.chip} ${classes.wrong}`}
                                        >
                                          <FaTimes className={classes.chipIcon} />
                                          <p className={classes["margin-bottom-0"]}>wrong</p>
                                        </div>
                                      )}
                                      {correctOption && (
                                        <div
                                          className={`${classes.chip} ${classes.right}`}
                                        >
                                          <FaCheck className={classes.chipIcon} />
                                          <p className={classes["margin-bottom-0"]}>right</p>
                                        </div>
                                      )}
                                    </div>
                                  }
                                />
                              </div>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}

                  {["multiple_options"].includes(questionType) && (
                    <>
                      {options.map((o, i) => {
                        let { optionText, isCorrect } = o;
                        let label = optionText;
                        let correctOption = isCorrect;
                        const selected = selectedOptions.includes(optionText);
                        let inCorrectOption = selected && !isCorrect;
                        return (
                          <div key={i}>
                            <FormGroup>
                              <FormControlLabel
                                checked={selected}
                                value={label}
                                control={<Checkbox />}
                                label={
                                  <div className={classes.chipContainer}>
                                    <p className={classes.optionText}>{label}</p>
                                    {inCorrectOption && (
                                      <div
                                        className={`${classes.chip} ${classes.wrong}`}
                                      >
                                        <FaTimes className={classes.chipIcon} />
                                        <p>wrong</p>
                                      </div>
                                    )}
                                    {correctOption && (
                                      <div
                                        className={`${classes.chip} ${classes.right}`}
                                      >
                                        <FaCheck className={classes.chipIcon} />
                                        <p>right</p>
                                      </div>
                                    )}
                                  </div>
                                }
                              />
                            </FormGroup>
                          </div>
                        );
                      })}
                    </>
                  )}

                  {["fill_in_the_blank"].includes(questionType) && (
                    <>
                      <p>
                        Correct Options are :{" "}
                        <strong>{blankCorrectAnswers.join(", ")}</strong>{" "}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Card>
          </>
        );
      })}

      {((noOfQuestionsRef.current === 0 && quiz?.isNewQuizSchema) ||
        quiz?.questions?.length === 0) && (
        <NotFoundContainer>
          <div>
            <NotFoundText>
              No Data Available ! <br />
            </NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        className={classes.reportView}
      >
        <Button
          onClick={() => navigate("/student/reports")}
          fullWidth
          sx={{
            borderRadius: 50,
            marginTop: "20px"
          }}
          variant="contained"
        >
          Finish Review
        </Button>
      </Box>
    </section>
  ) : null;
};

export default StudentReportView;
