// import { Button, Divider, Grid, TextField } from "@mui/material";
import classes from "./Footer.module.css";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import Accordian from "./Accordian";

const Footer = () => {
  return (
    <>
      <MDBFooter
        bgColor="#0C0F2A"
        color="white"
        className="text-center text-lg-start text-muted">
        <section className="" style={{ backgroundColor: "#0C0F2A"}}>
          <MDBContainer className="text-center text-md-start">
            <MDBRow sx={{ mt: 0 }}>
              <MDBCol md="3" lg="4" xl="3" className={`mx-auto mb-4 ${classes.colMarginTop}`}>
                <h6 className="text-uppercase fw-bold">
                  <img
                    src={require("../../assets/logo_white.png")}
                    alt="LoGO"
                    style={{
                      maxWidth: "90%",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />

                  <Accordian />
                </h6>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className={`mx-auto mb-4 ${classes.colMarginTop}`}>
                <h6 className="text-uppercase fw-bold mb-4" style={{color:"#f8f8f8"}}>About</h6>
                <p>
                  <a
                    href="https://datavalley.ai/about-us/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/contact-us/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Contact Us
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/course/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Courses
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/our-team/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Our Team
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className={`mx-auto mb-4 ${classes.colMarginTop}`}>
                <h6 className="text-uppercase fw-bold mb-4" style={{color: "#f8f8f8"}}>Work With Us</h6>
                <p>
                  <a
                    href="https://datavalley.ai/career/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Career
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/become-an-instructor/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Become an Instructor
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/#"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Become an Affiliate
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/job-openings/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Job Openings
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className={`mx-auto mb-4 ${classes.colMarginTop}`}>
                <h6 className="text-uppercase fw-bold mb-4" style={{color: "#f8f8f8"}}>Courses</h6>
                <p>
                  <a
                    href="https://datavalley.ai/courses/aws-data-engineer-masters-program/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Data Engineering
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/courses/aws-certified-solutions-architect-masters-program-4/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    AWS Solutions Architect
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/courses/microsoft-azure-devops-masters-program/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    DevOps Engineering
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/courses/business-intelligence-and-analytics-with-power-bi/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Power Bi
                  </a>
                </p>
                <p>
                  <a
                    href="https://datavalley.ai/courses/data-science-certification-masters-program/"
                    className={`text-reset ${classes.anchorItem}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Data Science
                  </a>
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <section className="d-flex justify-content-center justify-content-lg-between border-bottom"></section>

        <section>
          <div
            style={{
              backgroundColor: "#0C0F2A",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              // position:"relative",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "2px"
            }}>
            <div
              className="social-icons"
              style={{
                backgroundColor: "#0C0F2A",
                padding: "15px",
                display: "flex",
                alignItems: "center",
              }}>
              <a
                href="https://twitter.com/Datavalley_ai"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4 text-reset social-icon"
                style={{
                  backgroundColor: "#1DA1F2",
                  padding: "8px",
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 0.3s ease",
                }}>
                <MDBIcon
                  color="white"
                  fab
                  icon="twitter"
                  style={{ width: "23px", height: "23px" }}
                />
              </a>

              <a
                href="https://www.facebook.com/datavalleyai/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4 text-reset social-icon"
                style={{
                  backgroundColor: "#3b5998",
                  padding: "8px",
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 0.3s ease",
                }}>
                <MDBIcon
                  color="white"
                  fab
                  icon="facebook-f"
                  style={{ width: "23px", height: "23px" }}
                />
              </a>

              <a
                href="https://www.linkedin.com/company/datavalley-cloud/"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4 text-reset social-icon"
                style={{
                  backgroundColor: "#0077B5",
                  padding: "8px",
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 0.3s ease",
                }}>
                <MDBIcon
                  color="white"
                  fab
                  icon="linkedin"
                  style={{ width: "23px", height: "23px" }}
                />
              </a>

              <a
                href="https://www.youtube.com/@datavalley-ai"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4 text-reset social-icon"
                style={{
                  backgroundColor: "#FF0000",
                  padding: "8px",
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 0.3s ease",
                }}>
                <MDBIcon
                  color="white"
                  fab
                  icon="youtube"
                  style={{ width: "23px", height: "23px" }}
                />
              </a>

              <a
                href="https://www.instagram.com/datavalley.ai/"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4 text-reset social-icon"
                style={{
                  backgroundColor: "#833ab4",
                  padding: "8px",
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 0.3s ease",
                }}>
                <MDBIcon
                  color="white"
                  fab
                  icon="instagram"
                  style={{ width: "23px", height: "23px" }}
                />
              </a>
            </div>
            <div
              className={`text-center ${classes.contentItem}`}
              style={{
                backgroundColor: "#0C0F2A",
                color: "white",
              }}>
              <p>© 2024 Datavalley Inc.</p>
            </div>
            <div
              className={`text-center ${classes.contentItem}`}
              style={{
                backgroundColor: "#0C0F2A",
                color: "white",
              }}>
              <a
                target="_blank"
                href="https://datavalley.ai/terms-and-conditions/"
                rel="noopener noreferrer"
                className="me-4 text-reset">
                Terms of use
              </a>
            </div>
            <div
              className={`text-center ${classes.contentItem}`}
              style={{
                backgroundColor: "#0C0F2A",
                color: "white",
              }}>
              <a
                target="_blank"
                href="https://datavalley.ai/privacy-policy/"
                rel="noopener noreferrer"
                className="me-4 text-reset">
                Privacy policy
              </a>
            </div>
          </div>
        </section>
      </MDBFooter>
    </>
  );
};

export default Footer;
