import React, { useContext, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { TrainerStatusContext } from "../../../services/TrainerStatus/TrainerStatus.context";

const WorkUpdateForm = ({ handleClickBack, selectedDate }) => {
  const [formData, setFormData] = useState({
    college: "",
    year: "",
    branchAndSection: "",
    course: "",
    startTime: null,
    endTime: null,
    topic: "",
    totalStudents: 0,
    attendedStudents: 0,
    comments: "",
  });

  const [errors, setErrors] = useState({});
  const { onAddWorkUpdate } = useContext(TrainerStatusContext);
  const dummyColleges = [
    "St. Mary's - Budampadu",
    "St. Mary's - Chebrolu",
    "Eswar College",
    "SVIT College",
    "SMEC - Deshmukhi",
    "SMGOIH - Deshmukhi",
    "SMIC - Deshmukhi",
    "ISTS College",
    "RVIT College",
    "ST. Ann's College",
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTimeChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};

    tempErrors.college = formData.college ? "" : "College is required";
    tempErrors.year = formData.year ? "" : "Year is required";
    tempErrors.branchAndSection = formData.branchAndSection
      ? ""
      : "Branch and section are required";
    tempErrors.course = formData.course ? "" : "Course is required";
    tempErrors.startTime = formData.startTime ? "" : "Start time is required";
    tempErrors.endTime = formData.endTime ? "" : "End time is required";
    tempErrors.topic = formData.topic ? "" : "Topic is required";
    tempErrors.totalStudents = formData.totalStudents
      ? ""
      : "Total students is required";
    tempErrors.attendedStudents = formData.attendedStudents
      ? ""
      : "Attended students is required";

    if (
      formData.totalStudents &&
      formData.attendedStudents &&
      parseInt(formData.attendedStudents) > parseInt(formData.totalStudents)
    ) {
      tempErrors.attendedStudents =
        "Attended students can't be more than total students";
    }

    setErrors(tempErrors);

    const firstError = Object.values(tempErrors).find((error) => error !== "");

    if (firstError) {
      toast.error(firstError);
    }

    return !firstError;
  };

  const clearFormData = () => {
    setFormData({
      college: "",
      year: "",
      branchAndSection: "",
      course: "",
      startTime: null,
      endTime: null,
      topic: "",
      totalStudents: 0,
      attendedStudents: 0,
      comments: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      onAddWorkUpdate(
        { ...formData, selectedDate },
        (result) => {
          if (result.lecture) {
            clearFormData();
            handleClickBack();
          }
        },
        (error) => {
          console.log(error);
        },
        true,
        true,
      );
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        Class Report Form
      </Typography>
      <Grid container spacing={3}>
        {/* College */}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.college}>
            <InputLabel>College Name</InputLabel>
            <Select
              name="college"
              value={formData.college}
              onChange={handleChange}
              label="College Name"
              required>
              {dummyColleges.map((college, index) => (
                <MenuItem key={index} value={college}>
                  {college}
                </MenuItem>
              ))}
            </Select>
            {errors.college && (
              <Typography color="error">{errors.college}</Typography>
            )}
          </FormControl>
        </Grid>

        {/* Year */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.year}>
            <InputLabel>Year</InputLabel>
            <Select
              name="year"
              value={formData.year}
              onChange={handleChange}
              label="Year"
              required>
              <MenuItem value="I year">I year</MenuItem>
              <MenuItem value="II year">II year</MenuItem>
              <MenuItem value="III year">III year</MenuItem>
              <MenuItem value="IV year">IV year</MenuItem>
            </Select>
            {errors.year && (
              <Typography color="error">{errors.year}</Typography>
            )}
          </FormControl>
        </Grid>

        {/* Branch & Section */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="branchAndSection"
            label="Branch & Section"
            fullWidth
            value={formData.branchAndSection}
            onChange={handleChange}
            error={!!errors.branchAndSection}
            helperText={errors.branchAndSection}
            required
          />
        </Grid>

        {/* Course */}
        <Grid item xs={6}>
          <TextField
            name="course"
            label="Course"
            fullWidth
            value={formData.course}
            onChange={handleChange}
            error={!!errors.course}
            helperText={errors.course}
            required
          />
        </Grid>

        {/* Time span */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} sm={6} lg={3}>
            <TimePicker
              label="Start Time"
              value={formData.startTime}
              onChange={(newValue) => handleTimeChange("startTime", newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth error={!!errors.startTime} />
              )}
              required
            />
            {errors.startTime && (
              <Typography color="error">{errors.startTime}</Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <TimePicker
              label="End Time"
              value={formData.endTime}
              onChange={(newValue) => handleTimeChange("endTime", newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth error={!!errors.endTime} />
              )}
              required
            />
            {errors.endTime && (
              <Typography color="error">{errors.endTime}</Typography>
            )}
          </Grid>
        </LocalizationProvider>

        {/* Topic Covered */}
        <Grid item xs={12}>
          <TextField
            name="topic"
            label="Topic Covered"
            fullWidth
            value={formData.topic}
            onChange={handleChange}
            error={!!errors.topic}
            helperText={errors.topic}
            required
          />
        </Grid>

        {/* Total Students */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="totalStudents"
            label="Total Students"
            type="number"
            fullWidth
            value={formData.totalStudents}
            onChange={handleChange}
            error={!!errors.totalStudents}
            helperText={errors.totalStudents}
            required
          />
        </Grid>

        {/* Attended Students */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="attendedStudents"
            label="Attended Students"
            type="number"
            fullWidth
            value={formData.attendedStudents}
            onChange={handleChange}
            error={!!errors.attendedStudents}
            helperText={errors.attendedStudents}
            required
          />
        </Grid>

        {/* Comments */}
        <Grid item xs={12}>
          <TextField
            name="comments"
            label="Comments"
            fullWidth
            multiline
            rows={3}
            value={formData.comments}
            onChange={handleChange}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkUpdateForm;
