const DS11Template = () => {
  return `<p>Dear Student, </p><p> congratulations! <strong>Please download</strong> your Internship <strong>Acceptance letter</strong>
   using the <strong>link below.</strong> </p><p>Please join the <strong>WhatsApp group</strong>
   if you haven't already done so yet. Thanks </p><p><u><a href="https://datavalley.ai/WhatsApp_AP24SDS_B11">https://datavalley.ai/WhatsApp_AP24SDS_B11</a></u> </p><p><strong>Data Science Machine Learning, AI</strong>
  </p><p><strong>Technical Sessions will be conducted from Monday to Friday</strong> </p><p><strong>Session Timings: </strong>11:30 AM - 1:00 PM </p><p><strong>Mode:</strong>
  Online(Microsoft Teams) </p><p><u><a href="https://datavalley.ai/join_ds_b11">https://datavalley.ai/join_ds_b11</a></u> </p><p>You can add Datavalley to your LinkedIn profile to show your internship. Also, feel free to share your letters on Linked In, and don't forget to tag us. You can use the tags #Datavalley #internships etc.
   </p><p>Best Regards, </p><p>Datavalley.ai</p>`;
};

const FS11Template = () => {
  return `<p>Dear Student, </p><p>Congratulations! <strong>Please download</strong> your Internship <strong>Acceptance letter</strong> using the <strong>link</strong> 
  <strong>below.</strong> </p><p>Please join the <strong>WhatsApp group</strong> if you haven't already done so yet. Thanks </p><p><u><a href="https://datavalley.ai/WhatsApp_AP24SFS_B11">https://datavalley.ai/WhatsApp_AP24SFS_B11</a></u> 
  </p><p><strong>Full Stack Developer</strong> </p><p><strong>Technical Sessions will be conducted from Monday to Friday</strong> </p><p><strong>Session Timings: </strong>11:30 AM - 1:00 PM </p><p><strong>Mode:</strong> Online(Microsoft Teams) </p>
  <p><u><a href="https://datavalley.ai/join_fs_b11">https://datavalley.ai/join_fs_b11</a></u> </p><p>You can add Datavalley to your LinkedIn profile to show your internship. Also, feel free to share your letters on Linked In, and don't forget to tag us. You can use the tags #Datavalley #internships etc. </p>
  <p>Best Regards, </p><p>Datavalley.ai  </p>`;
};

const DM11Template = () => {
  return `<p>Dear Student, </p><p></p><p>Congratulations! <strong>Please download</strong> your Internship <strong>Acceptance letter</strong> using the <strong>link below.</strong> </p><p>Please join the <strong>WhatsApp group</strong> if you haven't already done so yet. Thanks </p>
  <p><u><a href="https://datavalley.ai/WhatsApp_AP24SDM_B11">https://datavalley.ai/WhatsApp_AP24SDM_B11</a></u> </p><p><strong>Digital Marketing</strong> </p><p><strong>Technical Sessions will be conducted from Monday to Friday</strong> </p><p><strong>Session Timings: </strong>10:00 AM - 11:30 AM </p>
  <p><strong>Mode:</strong> Online(Microsoft Teams) </p><p><u><a href="https://datavalley.ai/join_dm_b11">https://datavalley.ai/join_dm_b11</a></u> </p><p></p><p>You can add Datavalley to your LinkedIn profile to show your internship. Also, feel free to share your letters on Linked In, and don't forget to tag us. 
  You can use the tags #Datavalley #internships etc. </p><p>Best Regards, </p><p>Datavalley.ai </p>`
};

const CL11Template = () => {
  return `<p>Dear Student, </p><p>Congratulations! <strong>Please download</strong> your Internship <strong>Acceptance letter</strong> using the <strong>link below.</strong> </p><p>Please join the <strong>WhatsApp group</strong> if you haven't already done so yet. Thanks </p><p><u><a href="https://datavalley.ai/WhatsApp_AP24SCL_B11">https://datavalley.ai/WhatsApp_AP24SCL_B11</a>
  </u> </p><p><strong>Cloud Engineering &amp; DevOps</strong> <br/><strong>Technical Sessions will be conducted from Monday to Friday</strong> </p><p><strong>Session Timings:</strong> 11:30 AM - 1:00 PM </p><p><strong>Mode:</strong> Online(Microsoft Teams) </p><p><u><a href="https://datavalley.ai/join_CL_b11">https://datavalley.ai/join_CL_b11</a></u> </p>
  <p>You can add Datavalley to your LinkedIn profile to show your internship. Also, feel free to share your letters on Linked In, and don't forget to tag us. You can use the tags #Datavalley #internships etc. </p><p></p><p>Best Regards, </p><p>Datavalley.ai  </p>`
};
export { DS11Template, FS11Template, DM11Template, CL11Template };
