import { useContext, useEffect, useState } from "react";
import { ResourcesContext } from "../../../services/Resources/Resources.context";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
  IconButton
} from "@mui/material";
import classes from "./StudentResources.module.css";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import SearchIcon from "@mui/icons-material/Search";

import { FaDownload } from "react-icons/fa";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import { MdDocumentScanner } from "react-icons/md";
import {
  downloadFileFromS3Url,
  generatePresignedUrl,
} from "../../../utility/s3Helpers";
import { hideLoader } from "../../../shared/Loader/Loader";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../shared/Notification/Notification";
import _ from "lodash";

const StudentResources = ({ title }) => {
  const { onGetResources } = useContext(ResourcesContext);
  const [resources, setResources] = useState([]);
  const [orgResources, setOrgResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    document.title = title;
    getResources();
  }, []);

  const getResources = () => {
    onGetResources(
      (result) => {
        setLoading(false);
        if (result.resources) {
          setResources(result.resources);
          setOrgResources(result.resources);
        }
      },
      true,
      false
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgResources;
    if (value) {
      value = value.toLowerCase();
      let finalResources = _.cloneDeep(orgResources);
      filtered = finalResources.filter((course) => {
        let { title } = course;
        let titleFound = title.toLowerCase().includes(value);

        return titleFound;
      });
    }
    setResources(filtered);
  };

  const handleOpenResource = async (path, duration) => {
    let previewUrl = await generatePresignedUrl(path);
    window.open(previewUrl, "__blank");
  };

  const onDownloadResource = async (resource) => {
    let { title, path } = resource;
    try {
      const fileExtension = path.split("/").pop().split(".").pop();
      const fileName = `${title}.${fileExtension}`;
      hideLoader(dispatch);
      await downloadFileFromS3Url(path, fileName);
    } catch (e) {
      console.log(e);
      hideLoader(dispatch);
      showNotification({
        message: e.toString(),
        status: "error",
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentResources = resources;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["title"];
    if (fields.includes(fieldToSort)) {
      let sortedResources = _.orderBy(currentResources, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setResources(sortedResources);
    }
  };

  return (
    <div className={classes.resourcesMain} style={{padding: "28px"}}>
      <div className={classes.headerContainer}>
        <div className={classes.headerText}>
          <h4>
            Available resources: {orgResources.length}
          </h4>
        </div>
        {orgResources && orgResources.length > 0 && (
          <div className={classes.searchContainer}>
            <div className={classes["wrapper"]}>
            <div className={classes["searchBar"]}>
              <input
                id={classes["searchQueryInput"]}
                type="text"
                name="searchQueryInput"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e) => onChangeSearchKeyword(e)}
              />
              <button
                id={classes["searchQuerySubmit"]}
                type="submit"
                name="searchQuerySubmit">
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24">
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </div>
          </div>
          </div>
        )}
      </div>
      {resources.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Resources Available!</NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}

      {resources && resources.length > 0 && (
        <Box>
          <TableContainer component={Paper} sx={{ mt: "12px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>S.NO</TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      direction={
                        sort.type && sort.type === "desc" ? "asc" : "desc"
                      }
                      active
                      onClick={() => onChangeSorting("title")}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Resource</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? resources.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : resources
                ).map((resource, index) => {
                  let { title, path, _id } = resource;

                  return (
                    <TableRow
                      key={_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: index % 2 === 0 ? "rgb(250, 250, 250)" : 'inherit'
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell>{title}</TableCell>
                      <TableCell>
                        <Button
                          className={classes.openButton}
                          onClick={() => handleOpenResource(path)}
                          startIcon={<MdDocumentScanner />}
                        >
                          Open Resource
                        </Button>
                      </TableCell>
                      <TableCell>
                        <FaDownload
                          size={15}
                          color="grey"
                          className="cp"
                          onClick={() => onDownloadResource(resource)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      50,
                      { label: "All", value: -1 },
                    ]}
                    count={resources.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
};

export default StudentResources;
