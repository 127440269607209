import Sk from "skulpt";

const executePython = async (code) => {
  try {
    let output1 = "";
    Sk.configure({
      output: (text) => {
        output1 += text;
      },
    });
    await Sk.misceval.asyncToPromise(() =>
      Sk.importMainWithBody("<stdin>", false, code, true),
    );
    return output1.trim();
  } catch (error) {
    throw new Error(error.message);
  }
};

export default executePython;
