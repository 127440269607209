import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BatchesContext } from "../../../services/Batches/Batches.context";
import _ from "lodash";
import { MdAssignmentAdd } from "react-icons/md";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import classes from "./QuizNew.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaQuestion } from "react-icons/fa";
import { showNotification } from "../../../shared/Notification/Notification";
import Swal from "sweetalert2";
import { scrollToTop } from "../../../utility/helper";
import { QuizNewContext } from "../../../services/QuizNew/QuizNew.context";

const errors = {
  nameRequired: "Quiz name required",
  descriptionRequired: "Description required",
  questionsRequired: "There should be atleast one question to add quiz",
  totalMarksRequired: "Invalid Total Marks",
  singleQuestionMarksRequired:
    "Please select how many marks each question carries",
  passPercentageRequired: "Invalid Pass Percentage",
  availableFromRequired: "Please select quiz available from date",
  availableUntilRequired: "Please select quiz available until date",
  availableToRequired: "Please select quiz available to ",
  dueDateRequired: "Due Date Required",
  timeLimitRequired: "Please select Time Limit in minutes ",
  attemptsRequired: "Please select how many attempts ",
  dateMismatch: "Available Until cannot be before or equal From date",
  questionRequired: "Please enter question",
  questionTypeRequired: "Please select question type",
  batchRequired: "Please select Batch",
  studentsRequired: "Please select students to avail quiz",
  dueDateBeforeError: "Due Date cannot be before or same as available from",
  dueDateAfterError: "Due Date cannot be after available until",
  groupTitleRequired: "group title is required",
  questionRequiredInGroup: "There should be atleast one question to add group",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const questionTypes = [
  // {
  //   label: "Fill In The Blanks",
  //   value: "fill_in_the_blank",
  // },
  {
    label: "Single Option",
    value: "single_option",
  },
  {
    label: "Multiple Options",
    value: "multiple_options",
  },
];

const defaultInputState = {
  name: {
    ...commonInputFieldProps,
  },
  description: {
    ...commonInputFieldProps,
  },
  totalMarks: {
    ...commonInputFieldProps,
  },
  singleQuestionMarks: {
    ...commonInputFieldProps,
  },
  availableFrom: {
    ...commonInputFieldProps,
    value: moment(),
  },
  availableUntil: {
    ...commonInputFieldProps,
    value: moment(),
  },
  dueDate: {
    ...commonInputFieldProps,
    value: null,
  },
  timeLimit: {
    ...commonInputFieldProps,
  },
  timeLimitEnabled: {
    ...commonInputFieldProps,
    value: true,
  },
  attempts: {
    ...commonInputFieldProps,
  },
  attemptsEnabled: {
    ...commonInputFieldProps,
    value: true,
  },
  passPercentage: {
    ...commonInputFieldProps,
  },
  availableToEveryone: {
    ...commonInputFieldProps,
    value: false,
  },

  id: {
    ...commonInputFieldProps,
  },
};

const defaultQuestionInput = {
  questionText: {
    ...commonInputFieldProps,
  },
  questionType: {
    ...commonInputFieldProps,
    value: questionTypes.find((q) => q.value === "single_option"),
  },

  optionText: {
    ...commonInputFieldProps,
  },

  selectedOption: {
    ...commonInputFieldProps,
  },

  selectedOptions: {
    ...commonInputFieldProps,
    value: [],
  },

  options: {
    ...commonInputFieldProps,
    value: [],
  },
};

const CreateEditQuizNew = ({ mode }) => {
  const [quiz, setQuiz] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [batchesLoading, setBatchesLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onGetBatches } = useContext(BatchesContext);
  const {
    onGetQuiz,
    onEditQuiz,
    onCreateQuiz,
    onDeleteQuestion,
    onDeleteQuestionGroup,
  } = useContext(QuizNewContext);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [batches, setBatches] = useState([]);

  const [selectedBatches, setSelectedBatches] = useState(null);

  const [questionGroups, setQuestionGroups] = useState([]);
  const [selectedQuestionGroup, setSelectedQuestionGroup] = useState({});
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);

  const [question, setQuestion] = useState(defaultQuestionInput);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(false);

  const navigate = useNavigate();
  let numRegex = /^\d+$/;

  const addQuestionGroupRef = useRef(null);
  const optionRef = useRef();
  const questionRef = useRef(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setInputs((p) => ({
      ...p,
      description: {
        error: false,
        errorMessage: "",
        value: html,
      },
    }));
  }, [editorState]);

  // fetching quiz
  useEffect(() => {
    if (!batchesLoading) {
      if (mode) {
        let title = mode === "edit" ? "Edit Quiz" : "Add New Quiz";
        document.title = title;
      }
      if (mode === "edit") {
        let editId = searchParams.get("id");
        if (!editId) {
          navigate("/dashboard/quiz-new");
          return;
        }
        getQuizData();
      }
    }
  }, [mode, batchesLoading]);

  const getQuizData = () => {
    let editId = searchParams.get("id");
    if (editId) {
      onGetQuiz(
        editId,
        (result) => {
          let quizData = result.quiz;
          setQuiz(quizData);
        },
        () => {
          navigate("/dashboard/quiz-new");
        },
        true,
        false,
      );
    }
  };

  // fetching batches function call
  useEffect(() => {
    if (mode) {
      getBatches();
    }
  }, [mode]);

  // structuring quiz after fetching
  useEffect(() => {
    onStructureData();
  }, [quiz]);

  useEffect(() => {
    let singleQuestionMark = inputs.singleQuestionMarks.value;
    let noOfQuestions = 0;
    if (questionGroups.length) {
      questionGroups.forEach((questionGroup) => {
        noOfQuestions += questionGroup.questions.length;
      });
    }

    if (
      noOfQuestions &&
      noOfQuestions > 0 &&
      numRegex.test(singleQuestionMark)
    ) {
      let totalMarks = Number(singleQuestionMark) * noOfQuestions;
      totalMarks = totalMarks.toString();
      onValueChangeHandler({
        target: {
          name: "totalMarks",
          value: totalMarks,
        },
      });
    } else {
      onValueChangeHandler({
        target: {
          name: "totalMarks",
          value: "",
        },
      });
    }
  }, [inputs.singleQuestionMarks, questionGroups]);

  // structuring questions
  //@ need change
  const onStructureData = () => {
    if (!quiz) {
      return;
    }
    let {
      name,
      description,
      totalMarks,
      passPercentage,
      availableFrom,
      availableUntil,
      dueDate,
      availableToEveryone,
      singleQuestionMarks,
      timeLimit,
      attempts,
      attemptsEnabled,
      timeLimitEnabled,
      batches: qBatches,
      questionGroups,
      _id,
    } = quiz;
    if (!availableToEveryone && qBatches?.length > 0) {
      let batchesFound = batches.filter((b) =>
        qBatches.some((cBatch) => cBatch._id === b._id),
      );
      setSelectedBatches(batchesFound);
    }

    const structuredGroups = [];

    questionGroups.forEach((questionGroup) => {
      const groupObj = {};
      groupObj.groupHeader = {
        ...commonInputFieldProps,
        value: questionGroup.groupHeader,
      };
      if (questionGroup._id) {
        groupObj._id = questionGroup._id;
      }
      let structuredQuestions = [];
      questionGroup.questions.forEach((question, i) => {
        let { questionText, questionType, options } = question;
        let obj = {
          ...defaultQuestionInput,
          questionText: {
            ...defaultQuestionInput.questionText,
            value: questionText,
          },
          questionType: {
            ...defaultQuestionInput.questionType,
            value: questionTypes.find((q) => q.value === questionType),
          },

          options: {
            value: {},
          },
        };
        let structuredOptions = [];
        let selectedOption = {
          ...commonInputFieldProps,
        };
        let selectedOptions = {
          ...commonInputFieldProps,
          value: [],
        };
        options.forEach((option) =>
          structuredOptions.push({
            optionText: option.optionText,
          }),
        );
        if (questionType === "single_option") {
          let optionFound = options.find((option) => option.isCorrect);
          if (optionFound && optionFound.optionText) {
            selectedOption.value = optionFound.optionText;
          }
        }
        if (
          questionType === "multiple_options" ||
          questionType === "fill_in_the_blank"
        ) {
          let optionsFound = options.filter((option) => option.isCorrect);
          if (optionsFound && optionsFound.length > 0) {
            selectedOptions.value = optionsFound.map(
              (option) => option.optionText,
            );
          }
        }

        obj.selectedOption = selectedOption;
        obj.selectedOptions = selectedOptions;
        obj.options.value = structuredOptions;
        if (question._id) {
          obj._id = question._id;
        }
        structuredQuestions.push(obj);
      });
      groupObj.questions = structuredQuestions;
      structuredGroups.push(groupObj);
    });

    setQuestionGroups(structuredGroups);

    let newEditorState = EditorState.createWithContent(
      convertFromHTML(description),
    );
    setEditorState(newEditorState);

    setInputs((prevState) => ({
      ...prevState,
      name: {
        ...commonInputFieldProps,
        value: name,
      },
      passPercentage: {
        ...commonInputFieldProps,
        value: passPercentage.toString(),
      },
      timeLimitEnabled: {
        ...commonInputFieldProps,
        value: timeLimitEnabled,
      },
      attemptsEnabled: {
        ...commonInputFieldProps,
        value: attemptsEnabled,
      },
      totalMarks: {
        ...commonInputFieldProps,
        value: totalMarks.toString(),
      },
      singleQuestionMarks: {
        ...commonInputFieldProps,
        value: singleQuestionMarks.toString(),
      },
      availableToEveryone: {
        ...commonInputFieldProps,
        value: availableToEveryone,
      },
      availableFrom: {
        ...commonInputFieldProps,
        value: moment(availableFrom),
      },
      dueDate: {
        ...commonInputFieldProps,
        value: dueDate ? moment(dueDate) : null,
      },
      availableUntil: {
        ...commonInputFieldProps,
        value: moment(availableUntil),
      },
      timeLimit: {
        ...commonInputFieldProps,
        value: timeLimit?.toString() || "",
      },
      attempts: {
        ...commonInputFieldProps,
        value: attempts?.toString() || "",
      },
      id: {
        value: _id,
      },
    }));
  };

  // fetch batches
  const getBatches = (query = "?dropDown=yes") => {
    onGetBatches(
      query,
      (result) => {
        setBatchesLoading(false);
        if (result && result.batches) {
          setBatches(result.batches);
        }
      },
      () => {
        setBatchesLoading(false);
      },
      true,
      false,
    );
  };

  // handling inputs
  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onQuestionValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setQuestion((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const returnTrimmedData = (value) => {
    return typeof value === "string" ? value?.trim() : value;
  };

  //@ need change
  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };

    let {
      name,
      description,
      totalMarks,
      timeLimit,
      attempts,
      availableFrom,
      availableToEveryone,
      availableUntil,
      timeLimitEnabled,
      attemptsEnabled,
      singleQuestionMarks,
      passPercentage,
      dueDate,
      id,
    } = inputs;
    name = returnTrimmedData(name.value);
    description = returnTrimmedData(description.value);
    totalMarks = returnTrimmedData(totalMarks.value);
    singleQuestionMarks = returnTrimmedData(singleQuestionMarks.value);
    timeLimit = returnTrimmedData(timeLimit.value);
    attempts = returnTrimmedData(attempts.value);

    availableFrom = returnTrimmedData(availableFrom.value);
    availableUntil = returnTrimmedData(availableUntil.value);
    availableToEveryone = returnTrimmedData(availableToEveryone.value);
    timeLimitEnabled = returnTrimmedData(timeLimitEnabled.value);
    attemptsEnabled = returnTrimmedData(attemptsEnabled.value);

    passPercentage = returnTrimmedData(passPercentage.value);
    dueDate = returnTrimmedData(dueDate.value);
    id = returnTrimmedData(id.value);
    availableFrom = availableFrom?.valueOf();
    availableUntil = availableUntil?.valueOf();
    dueDate = dueDate?.valueOf();

    if (!name) {
      setErrorMessage("name", errors.nameRequired);
    }

    if (!singleQuestionMarks || !numRegex.test(singleQuestionMarks)) {
      setErrorMessage(
        "singleQuestionMarks",
        errors.singleQuestionMarksRequired,
      );
    }

    if (!totalMarks || !numRegex.test(totalMarks)) {
      setErrorMessage("totalMarks", errors.totalMarksRequired);
    }

    let passingMarks = calculateScoreToPass()
      ? Number(calculateScoreToPass())
      : "";
    if (!passPercentage || !numRegex.test(passPercentage)) {
      setErrorMessage("passPercentage", errors.passPercentageRequired);
    } else if (!passingMarks || passingMarks > totalMarks) {
      setErrorMessage(
        "passPercentage",
        "Pass Percentage cannot exceed total marks",
      );
    }

    if ((!timeLimit || !numRegex.test(timeLimit)) && timeLimitEnabled) {
      setErrorMessage("timeLimit", errors.timeLimitRequired);
    }

    if ((!attempts || !numRegex.test(attempts)) && attemptsEnabled) {
      setErrorMessage("attempts", errors.attemptsRequired);
    }
    if (!availableFrom) {
      setErrorMessage("availableFrom", errors.availableFromRequired);
    }
    if (!availableUntil) {
      setErrorMessage("availableUntil", errors.availableUntil);
    }
    if (
      !availableToEveryone &&
      (!selectedBatches || selectedBatches.length === 0)
    ) {
      showNotification({ message: errors.studentsRequired, status: "error" });
      hadErrors = true;
    }

    if (availableFrom && availableUntil) {
      let availFrom = moment(availableFrom);
      let availUntil = moment(availableUntil);
      if (availUntil.isSameOrBefore(availFrom)) {
        setErrorMessage("availableUntil", errors.dateMismatch);
      }
    }

    if (availableUntil && dueDate) {
      let dueDt = moment(dueDate);
      let availFrom = moment(availableFrom);
      let availUntil = moment(availableUntil);

      if (dueDt.isSameOrBefore(availFrom)) {
        setErrorMessage("dueDate", errors.dueDateBeforeError);
      }
      if (dueDt.isAfter(availUntil)) {
        setErrorMessage("dueDate", errors.dueDateAfterError);
      }
    }
    let noOfQuestions = 0;
    questionGroups.forEach((questionGroup) => {
      noOfQuestions += questionGroup.questions.length;
    });

    if (!hadErrors && noOfQuestions === 0) {
      showNotification({ message: errors.questionsRequired, status: "error" });
      hadErrors = true;
    }

    if (hadErrors) {
      return;
    }

    let data = {
      name: name,
      description: description === "<p></p>" ? "" : description,
      passPercentage: Number(passPercentage),
      timeLimitEnabled: timeLimitEnabled,
      attemptsEnabled: attemptsEnabled,
      batches: [],
      singleQuestionMarks: Number(singleQuestionMarks),
      totalMarks: Number(totalMarks),
      availableFrom: availableFrom,
      availableUntil: availableUntil,
      availableToEveryone: availableToEveryone,
    };

    if (timeLimitEnabled) {
      data.timeLimit = Number(timeLimit);
    } else {
      data.timeLimit = 0;
    }
    if (attemptsEnabled) {
      data.attempts = Number(attempts);
    } else {
      data.attempts = 0;
    }
    if (dueDate) {
      data.dueDate = dueDate;
    } else {
      data.dueDate = null;
    }

    if (!availableToEveryone) {
      data.batches = selectedBatches.map((b) => b._id);
    }
    const structuredGroups = [];
    questionGroups.forEach((questionGroup) => {
      const groupObj = {};
      groupObj.groupHeader = questionGroup.groupHeader.value;
      if (questionGroup._id) {
        groupObj._id = questionGroup._id;
      }
      let structuredQuestions = [];
      questionGroup.questions.forEach((question, i) => {
        let obj = {};
        let {
          questionText,
          questionType,
          selectedOption,
          options,
          selectedOptions,
        } = question;

        obj.questionText = questionText.value;
        obj.questionType = questionType.value?.value;
        let structeredOptions = [];
        options.value.forEach((option) => {
          let optionObj = {};
          let { optionText } = option;
          optionObj.optionText = optionText;
          optionObj.isCorrect =
            obj.questionType === "single_option"
              ? selectedOption.value === optionText
              : selectedOptions.value?.includes(optionText);
          structeredOptions.push(optionObj);
        });
        obj.options = structeredOptions;
        structuredQuestions.push(obj);
        if (question._id) {
          obj._id = question._id;
        }
      });
      groupObj.questions = structuredQuestions;
      structuredGroups.push(groupObj);
    });

    data.questionGroups = structuredGroups;

    if (mode === "edit") {
      onEditQuiz(id, data, (result) => {
        navigate("/dashboard/quiz-new");
      });
    } else {
      onCreateQuiz(data, () => {
        navigate("/dashboard/quiz-new");
      });
    }
  };

  const onAddOption = () => {
    let optionText = question.optionText.value;
    if (!optionText) {
      setQuestion((prevState) => ({
        ...prevState,
        optionText: {
          ...prevState.optionText,
          error: true,
          errorMessage: "Please Enter Option",
        },
      }));
    } else {
      let currentOptions = question.options.value || [];
      let option = {
        optionText: optionText,
      };
      currentOptions.push(option);
      setQuestion((p) => ({
        ...p,
        optionText: {
          ...p.optionText,
          value: "",
        },
        options: {
          value: currentOptions,
        },
      }));
    }
    optionRef.current?.focus();
  };

  const onAddOrEditQuestion = () => {
    let {
      questionText,
      questionType,
      options,
      selectedOption,
      selectedOptions,
    } = question;
    questionText = returnTrimmedData(questionText.value);
    questionType = returnTrimmedData(questionType.value);
    options = returnTrimmedData(options.value);
    selectedOptions = returnTrimmedData(selectedOptions.value);

    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setQuestion((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };

    if (!questionText) {
      setErrorMessage("questionText", errors.questionRequired);
    }
    if (!questionType) {
      setErrorMessage("questionType", errors.questionTypeRequired);
    }

    if (!options || options.length === 0) {
      setErrorMessage("optionText", "Atleast one option required");
    } else {
      let optionErrors = false;
      options.map((option, i) => {
        let { optionText } = option;
        if (!optionText || !optionText.trim()) {
          option.error = true;
          option.errorMessage = "Please enter the options";
          optionErrors = true;
        }
      });
      if (optionErrors) {
        hadErrors = true;
        setQuestion((p) => ({
          ...p,
          options: {
            value: options,
          },
        }));
      }
    }
    if (
      !selectedOption?.value &&
      !hadErrors &&
      questionType.value === "single_option"
    ) {
      showNotification({
        message: "Please select correct answer",
        status: "error",
      });
      hadErrors = true;
    }
    if (
      selectedOptions?.length < 2 &&
      !hadErrors &&
      questionType.value === "multiple_options"
    ) {
      showNotification({
        message: "Please select more than one option",
        status: "error",
      });
      hadErrors = true;
    }

    if (hadErrors) {
      return;
    }

    if (typeof selectedQuestionIndex === "number") {
      setSelectedQuestionGroup((prevGroupData) => {
        const updatedQuestions = [...prevGroupData.questions];
        updatedQuestions[selectedQuestionIndex] = question;
        return {
          _id: prevGroupData._id,
          groupHeader: prevGroupData.groupHeader,
          questions: updatedQuestions,
        };
      });
      setSelectedQuestionIndex(null);
    } else {
      setSelectedQuestionGroup((prevGroupData) => {
        return {
          _id: prevGroupData._id,
          groupHeader: prevGroupData.groupHeader,
          questions: prevGroupData?.questions?.length
            ? [...prevGroupData.questions, question]
            : [question],
        };
      });
    }

    setQuestion({
      ...defaultQuestionInput,
      options: {
        ...defaultQuestionInput.options,
        value: [],
      },
    });
  };

  const onClickDeleteOption = (index) => {
    let options = _.cloneDeep(question.options.value);
    let finalItems = options.filter((o, i) => i !== index);
    setQuestion((p) => ({
      ...p,
      selectedOption: {
        value: "",
      },
      selectedOptions: {
        value: [],
      },
      options: {
        value: finalItems,
      },
    }));
  };

  const onOptionValueChangeHandler = (index, value) => {
    let options = _.cloneDeep(question.options.value);
    let currentOption = _.get(options, index);
    currentOption.optionText = value;
    currentOption.error = false;
    currentOption.errorMessage = "";

    options[index] = currentOption;
    setQuestion((p) => ({
      ...p,
      selectedOption: {
        value: "",
      },
      selectedOptions: {
        value: [],
      },
      options: {
        value: options,
      },
    }));
  };

  const calculateScoreToPass = () => {
    let totalMarks = inputs.totalMarks.value;
    let passPercentage = inputs.passPercentage.value;
    if (totalMarks && passPercentage) {
      let passingMarks = Math.round((passPercentage / 100) * totalMarks);
      if (passingMarks > totalMarks) {
        return "";
      }
      return passingMarks.toString();
    }
    return "";
  };

  const onClickEditQuestion = (index) => {
    setSelectedQuestionIndex(index);
    let currentQuestions = _.cloneDeep(selectedQuestionGroup.questions);
    questionRef?.current?.scrollIntoView({ behavior: "smooth" });
    setQuestion(currentQuestions[index]);
  };

  const onClickDeleteQuestion = (questionIndex, groupIndex) => {
    const deleteQuestion = () => {
      // if (typeof groupIndex !== Number) {
      //   let currentQuestions = _.cloneDeep(selectedQuestionGroup.questions);
      //   let finalQuestions = currentQuestions.filter(
      //     (o, i) => i !== questionIndex,
      //   );
      //   setSelectedQuestionGroup((prevGroupData) => {
      //     return {
      //       ...prevGroupData,
      //       questions: finalQuestions,
      //     };
      //   });
      // }
      const currentGroup = questionGroups[groupIndex];
      if (!currentGroup.questions[questionIndex]._id) {
        if (currentGroup.questions.length === 1) {
          showNotification({
            message: "There should at least one question in the group ",
            status: "error",
          });
          return;
        }
        setQuestionGroups((prevGroups) => {
          const tempGroups = [...prevGroups];
          const filteredGroupQuestions = currentGroup.questions.filter(
            (q, i) => i !== questionIndex,
          );
          tempGroups[groupIndex] = {
            ...currentGroup,
            questions: filteredGroupQuestions,
          };
          return tempGroups;
        });
      } else {
        const data = {
          groupId: currentGroup._id,
          questionId: currentGroup.questions[questionIndex]._id,
        };
        onDeleteQuestion(
          data,
          (result) => {
            if (result && result.status === "success") {
              getQuizData();
            }
          },
          (error) => {
            console.log(error);
          },
          true,
          false,
        );
      }
    };
    Swal.fire({
      title: "Are you sure to delete the question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQuestion();
      }
    });
  };

  const onClickDeleteGroup = (groupIndex) => {
    const onDeleteGroup = () => {
      const questionGroup = questionGroups[groupIndex];
      if (!questionGroup._id) {
        let currentGroups = _.cloneDeep(questionGroups);
        let finalGroups = currentGroups.filter((o, i) => i !== groupIndex);
        setQuestionGroups(finalGroups);
      } else {
        const data = { questionGroupId: questionGroup._id, quizId: quiz._id };
        onDeleteQuestionGroup(
          data,
          (result) => {
            if (result && result.status === "success") {
              getQuizData();
            }
          },
          (err) => {
            console.log(err);
          },
          true,
          false,
        );
      }
    };
    Swal.fire({
      title: "Are you sure to delete the group?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteGroup();
      }
    });
  };

  const fillInTheBlank = (question, answers) => {
    let filledQuestion = question;
    answers.forEach((answer) => {
      filledQuestion = filledQuestion.replace("#blank#", () => {
        return answer ? `<u>${answer}</u>` : "<u>#blank#</u>";
      });
    });
    return filledQuestion;
  };

  const onChangeSelectBatch = (e, newValue) => {
    if (newValue.length === 0) {
      setSelectedBatches([]);
      return;
    }
    let uniqueValues = _.uniqBy(newValue, "_id");
    setSelectedBatches(uniqueValues);
  };

  const onCurrGroupDataChanges = (data, type) => {
    if (type === "title") {
      setSelectedQuestionGroup((prevData) => ({
        ...prevData,
        groupHeader:
          //  data.target.value,
          {
            value: data.target.value,
            focused: false,
            error: false,
            errorMessage: "",
          },
      }));
    }
  };

  const onAddEditGroup = () => {
    if (!selectedQuestionGroup?.groupHeader?.value) {
      setSelectedQuestionGroup((prevGroupData) => {
        return {
          ...prevGroupData,
          groupHeader: {
            value: "",
            focused: true,
            error: true,
            errorMessage: errors.groupTitleRequired,
          },
        };
      });
      return;
    }

    if (!selectedQuestionGroup?.questions?.length) {
      showNotification({
        message: errors.questionRequiredInGroup,
        status: "error",
      });
      return;
    }

    if (typeof selectedGroupIndex === "number" && selectedGroupIndex >= 0) {
      setQuestionGroups((prevGroups) => {
        const newGroups = [...prevGroups];
        newGroups[selectedGroupIndex] = selectedQuestionGroup;
        return newGroups;
      });
      setSelectedGroupIndex(null);
    } else {
      setQuestionGroups((prevGroups) => {
        return [...prevGroups, selectedQuestionGroup];
      });
    }
    setSelectedQuestionGroup({});
  };

  const onEditGroup = (groupIndex) => {
    setSelectedGroupIndex(groupIndex);
    setSelectedQuestionGroup(questionGroups[groupIndex]);
    addQuestionGroupRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onClickEditGroupQuestion = (groupIndex, questionIndex) => {
    // set group index and question index
    setSelectedGroupIndex(groupIndex);
    setSelectedQuestionIndex(questionIndex);

    // set current group and current question
    setSelectedQuestionGroup(questionGroups[groupIndex]);
    setQuestion(questionGroups[groupIndex].questions[questionIndex]);

    // scroll to group and the question
    addQuestionGroupRef?.current?.scrollIntoView({ behavior: "smooth" });
    questionRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onClickClearGroup = () => {
    setQuestion({
      ...defaultQuestionInput,
      options: {
        ...defaultQuestionInput.options,
        value: [],
      },
    });
    setSelectedQuestionIndex(null);
    setSelectedQuestionGroup({});
    setSelectedGroupIndex(null);
  };

  const onClickClearQuestion = () => {
    setQuestion({
      ...defaultQuestionInput,
      options: {
        ...defaultQuestionInput.options,
        value: [],
      },
    });
    setSelectedQuestionIndex(null);
  };

  return (
    <section>
      <Box component="form" noValidate sx={{ mt: 2 }}>
        <Typography gutterBottom variant="h5" component="div">
          {mode === "create" ? "Add New Quiz " : "Edit Quiz"}
        </Typography>

        <LoadingButton
          type="button"
          onClick={onSubmitForm}
          loadingPosition="end"
          endIcon={<MdAssignmentAdd />}
          color="primary"
          loading={loading}
          loadingIndicator={"Adding..."}
          variant="contained"
          sx={{ display: "flex", ml: "auto" }}>
          {!loading && mode === "edit" ? "PROCEED & UPDATE" : "PROCEED & ADD"}
        </LoadingButton>

        <br />
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {/* name */}
              <Grid item md={12}>
                <TextField
                  error={inputs.name.error}
                  helperText={inputs.name.errorMessage}
                  margin="normal"
                  placeholder="Enter Quiz Name "
                  required
                  fullWidth
                  id="name"
                  label="Quiz Name"
                  name="name"
                  value={inputs.name.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* description */}
              <Grid item md={12}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
                {inputs.description.error && (
                  <FormHelperText error>
                    {inputs.description.errorMessage}
                  </FormHelperText>
                )}
              </Grid>

              {/* Single Question Marks */}
              <Grid item md={3}>
                <TextField
                  required
                  error={inputs.singleQuestionMarks.error}
                  helperText={inputs.singleQuestionMarks.errorMessage}
                  margin="normal"
                  fullWidth
                  id="singleQuestionMarks"
                  label="Marks for each question?"
                  placeholder="Each question carries?"
                  name="singleQuestionMarks"
                  value={inputs.singleQuestionMarks.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* Total Marks */}
              <Grid item md={3}>
                <TextField
                  required
                  error={inputs.totalMarks.error}
                  helperText={inputs.totalMarks.errorMessage}
                  margin="normal"
                  fullWidth
                  id="totalMarks"
                  label="Total Marks"
                  placeholder="Enter Total Marks"
                  name="totalMarks"
                  disabled
                  value={inputs.totalMarks.value}
                  // onChange={onValueChangeHandler}
                />
              </Grid>

              {/* Pass Marks  */}
              <Grid item md={3}>
                <TextField
                  required
                  error={inputs.passPercentage.error}
                  helperText={inputs.passPercentage.errorMessage}
                  margin="normal"
                  fullWidth
                  id="passPercentage"
                  label="Pass Marks (Percentage)"
                  placeholder="Enter pass percentage"
                  name="passPercentage"
                  value={inputs.passPercentage.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* Final Score  */}
              <Grid item md={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  disabled
                  label="Score To Pass"
                  value={calculateScoreToPass()}
                />
              </Grid>

              {/* available from  */}
              <Grid item md={4}>
                <br />
                <DateTimePicker
                  label="Available From"
                  format="DD-MM-YYYY hh:mm:ss A"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: inputs.availableFrom.error,
                      helperText: inputs.availableFrom.errorMessage,
                    },
                  }}
                  value={inputs.availableFrom.value}
                  onChange={(newValue) => {
                    onValueChangeHandler({
                      target: {
                        name: "availableFrom",
                        value: newValue,
                      },
                    });
                  }}
                />
              </Grid>

              {/* Available Until */}
              <Grid item md={4}>
                <br />
                <DateTimePicker
                  label="Available Until"
                  format="DD-MM-YYYY hh:mm:ss A"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: inputs.availableUntil.error,
                      helperText: inputs.availableUntil.errorMessage,
                    },
                  }}
                  value={inputs.availableUntil.value}
                  onChange={(newValue) => {
                    onValueChangeHandler({
                      target: {
                        name: "availableUntil",
                        value: newValue,
                      },
                    });
                  }}
                />
              </Grid>

              {/* Due Date */}
              <Grid item md={4}>
                <br />
                <DateTimePicker
                  label="Due Date"
                  format="DD-MM-YYYY hh:mm:ss A"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: inputs.dueDate.error,
                      helperText: inputs.dueDate.errorMessage,
                    },
                  }}
                  value={inputs.dueDate.value}
                  onChange={(newValue) => {
                    onValueChangeHandler({
                      target: {
                        name: "dueDate",
                        value: newValue,
                      },
                    });
                  }}
                />
              </Grid>

              {/* Time Limit enabled */}
              <Grid item md={6} sx={{ mt: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    checked={inputs.timeLimitEnabled.value}
                    onChange={(e) => {
                      let value = !inputs.timeLimitEnabled.value;
                      onValueChangeHandler({
                        target: {
                          name: "timeLimitEnabled",
                          value: value,
                        },
                      });
                    }}
                    control={<Checkbox />}
                    label={`Enable Time Limit for Quiz`}
                  />
                </FormGroup>
              </Grid>

              {/* Time Limit */}
              {inputs.timeLimitEnabled.value && (
                <Grid item md={6}>
                  <TextField
                    required
                    error={inputs.timeLimit.error}
                    helperText={inputs.timeLimit.errorMessage}
                    margin="normal"
                    fullWidth
                    id="timeLimit"
                    label="Time Limit (in minutes)"
                    placeholder="Enter Time Limit in minutes"
                    name="timeLimit"
                    value={inputs.timeLimit.value}
                    onChange={onValueChangeHandler}
                  />
                </Grid>
              )}

              {/* Attempts enabled */}
              <Grid item md={6} sx={{ mt: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    checked={inputs.attemptsEnabled.value}
                    onChange={(e) => {
                      let value = !inputs.attemptsEnabled.value;
                      onValueChangeHandler({
                        target: {
                          name: "attemptsEnabled",
                          value: value,
                        },
                      });
                    }}
                    control={<Checkbox />}
                    label={`Enable No Of Attempts for Quiz`}
                  />
                </FormGroup>
              </Grid>

              {/* Attempts */}
              {inputs.attemptsEnabled.value && (
                <Grid item md={6}>
                  <TextField
                    required
                    error={inputs.attempts.error}
                    helperText={inputs.attempts.errorMessage}
                    margin="normal"
                    fullWidth
                    id="attempts"
                    label="Attempts"
                    placeholder="Enter no of attempts"
                    name="attempts"
                    value={inputs.attempts.value}
                    onChange={onValueChangeHandler}
                  />
                </Grid>
              )}

              {/* avaialbe to everyone */}
              <Grid item md={6} sx={{ mt: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    checked={inputs.availableToEveryone.value}
                    onChange={(e) => {
                      let value = !inputs.availableToEveryone.value;
                      if (!value) {
                        setSelectedBatches(null);
                      }
                      onValueChangeHandler({
                        target: {
                          name: "availableToEveryone",
                          value: value,
                        },
                      });
                    }}
                    control={<Checkbox />}
                    label={`Available To Everyone`}
                  />
                </FormGroup>
              </Grid>

              {/* select batches */}
              {!inputs.availableToEveryone.value && (
                <>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      id="batches"
                      className="mt-1"
                      options={batches}
                      multiple
                      fullWidth
                      value={selectedBatches || []}
                      onChange={(e, newValue) => {
                        onChangeSelectBatch(e, newValue);
                      }}
                      getOptionLabel={(option) =>
                        `${option.name} - ${option.code} `
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Batches "
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {/* submit button */}
            </Grid>
          </CardContent>
        </Card>
        <br />

        {questionGroups.length > 0 && (
          <Card
            sx={{
              mt: 2,
            }}>
            {questionGroups.map((currQuestionGroup, groupIndex) => {
              return (
                <CardContent key={groupIndex}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <div className={classes.groupHeader}>
                        <Typography variant="h5">
                          {groupIndex + 1}.{" "}
                          {currQuestionGroup.groupHeader.value}
                        </Typography>
                        <div>
                          <div>
                            <IconButton
                              onClick={() => onEditGroup(groupIndex)}
                              aria-label="edit"
                              color="info">
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => onClickDeleteGroup(groupIndex)}
                              aria-label="delete"
                              color="error">
                              <DeleteIcon />
                            </IconButton>{" "}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      {currQuestionGroup.questions &&
                        currQuestionGroup.questions.length > 0 && (
                          <Card>
                            <Typography
                              variant="h5"
                              sx={{ px: 2, mt: 3 }}
                              component="div">
                              Total Questions -{" "}
                              {currQuestionGroup.questions.length}
                            </Typography>
                            {currQuestionGroup.questions.map((question, i) => {
                              let {
                                questionText,
                                options,
                                questionType,
                                selectedOption,
                                selectedOptions,
                              } = question;
                              let filledQuestion = "";
                              if (
                                questionType?.value?.value ===
                                "fill_in_the_blank"
                              ) {
                                filledQuestion = fillInTheBlank(
                                  questionText.value,
                                  selectedOptions.value,
                                );
                              }

                              return (
                                <React.Fragment key={i}>
                                  <CardContent>
                                    {/* disply question & options*/}
                                    {questionText.value && (
                                      <div
                                        className={classes.questionContainer}>
                                        <div className={classes.questionFlex}>
                                          <div className={classes.question}>
                                            {questionType?.value?.value !==
                                              "fill_in_the_blank" && (
                                              <>
                                                {i + 1}. {questionText.value}
                                              </>
                                            )}
                                            {questionType?.value?.value ===
                                              "fill_in_the_blank" && (
                                              <p>
                                                {i + 1}.{" "}
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: filledQuestion,
                                                  }}></span>
                                              </p>
                                            )}
                                          </div>
                                          <div>
                                            <IconButton
                                              onClick={() =>
                                                onClickEditGroupQuestion(
                                                  groupIndex,
                                                  i,
                                                )
                                              }
                                              aria-label="edit"
                                              color="info">
                                              <EditIcon />
                                            </IconButton>
                                            <IconButton
                                              onClick={() =>
                                                onClickDeleteQuestion(
                                                  i,
                                                  groupIndex,
                                                )
                                              }
                                              aria-label="delete"
                                              color="error">
                                              <DeleteIcon />
                                            </IconButton>{" "}
                                          </div>
                                        </div>

                                        <div className={classes.options}>
                                          {questionType?.value?.value ===
                                            "single_option" && (
                                            <FormControl>
                                              <RadioGroup
                                                name="radio-buttons-group"
                                                value={
                                                  selectedOption.value || null
                                                }>
                                                {options.value.map(
                                                  (item, i) => {
                                                    let { optionText: ot } =
                                                      item;
                                                    return (
                                                      <FormControlLabel
                                                        key={i}
                                                        value={ot}
                                                        control={<Radio />}
                                                        label={ot}
                                                      />
                                                    );
                                                  },
                                                )}
                                              </RadioGroup>
                                            </FormControl>
                                          )}

                                          {questionType?.value?.value ===
                                            "multiple_options" && (
                                            <FormGroup>
                                              {options.value.map((item, i) => {
                                                let { optionText } = item;
                                                return (
                                                  <FormControlLabel
                                                    key={i}
                                                    checked={_.includes(
                                                      selectedOptions.value,
                                                      optionText,
                                                    )}
                                                    value={optionText}
                                                    control={<Checkbox />}
                                                    label={optionText}
                                                  />
                                                );
                                              })}
                                            </FormGroup>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </CardContent>
                                  <Divider sx={{ mb: 0 }} />
                                </React.Fragment>
                              );
                            })}
                          </Card>
                        )}
                    </Grid>
                  </Grid>
                </CardContent>
              );
            })}
          </Card>
        )}

        {/* edit question group */}

        <Card
          ref={addQuestionGroupRef}
          sx={{
            mt: 2,
          }}>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="h6">
                {typeof selectedGroupIndex === "number"
                  ? `Edit Group No - ${selectedGroupIndex + 1}`
                  : "Add Group"}
              </Typography>
              <Box>
                <Button onClick={onClickClearGroup} variant="contained">
                  clear
                </Button>
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  error={selectedQuestionGroup?.groupHeader?.error || false}
                  helperText={
                    selectedQuestionGroup?.groupHeader?.errorMessage || ""
                  }
                  margin="normal"
                  placeholder="Enter group title "
                  required
                  fullWidth
                  id="name"
                  label="Group title"
                  name="name"
                  value={selectedQuestionGroup?.groupHeader?.value || ""}
                  onChange={(e) => onCurrGroupDataChanges(e, "title")}
                />
              </Grid>
              <Grid item md={12}>
                {selectedQuestionGroup.questions &&
                  selectedQuestionGroup.questions.length > 0 && (
                    <Card>
                      <Typography
                        variant="h5"
                        sx={{ px: 2, mt: 3 }}
                        component="div">
                        Total Questions -{" "}
                        {selectedQuestionGroup.questions.length}
                      </Typography>
                      {selectedQuestionGroup.questions.map((question, i) => {
                        let {
                          questionText,
                          options,
                          questionType,
                          selectedOption,
                          selectedOptions,
                        } = question;
                        let filledQuestion = "";
                        if (
                          questionType?.value?.value === "fill_in_the_blank"
                        ) {
                          filledQuestion = fillInTheBlank(
                            questionText.value,
                            selectedOptions.value,
                          );
                        }

                        return (
                          <React.Fragment key={i}>
                            <CardContent
                            // ref={(el) => (questionRefs.current[i] = el)}
                            >
                              {/* disply question & options*/}
                              {questionText.value && (
                                <div className={classes.questionContainer}>
                                  <div className={classes.questionFlex}>
                                    <div className={classes.question}>
                                      {questionType?.value?.value !==
                                        "fill_in_the_blank" && (
                                        <>
                                          {i + 1}. {questionText.value}
                                        </>
                                      )}
                                      {questionType?.value?.value ===
                                        "fill_in_the_blank" && (
                                        <p>
                                          {i + 1}.{" "}
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: filledQuestion,
                                            }}></span>
                                        </p>
                                      )}
                                    </div>
                                    <div>
                                      <IconButton
                                        onClick={() => onClickEditQuestion(i)}
                                        aria-label="edit"
                                        color="info">
                                        <EditIcon />
                                      </IconButton>
                                      {/* <IconButton
                                        onClick={() =>
                                          onClickDeleteQuestion(
                                            i,
                                            selectedGroupIndex,
                                          )
                                        }
                                        aria-label="delete"
                                        color="error">
                                        <DeleteIcon />
                                      </IconButton>{" "} */}
                                    </div>
                                  </div>

                                  <div className={classes.options}>
                                    {questionType?.value?.value ===
                                      "single_option" && (
                                      <FormControl>
                                        <RadioGroup
                                          name="radio-buttons-group"
                                          value={selectedOption.value || null}>
                                          {options.value.map((item, i) => {
                                            let { optionText: ot } = item;
                                            return (
                                              <FormControlLabel
                                                key={i}
                                                value={ot}
                                                control={<Radio />}
                                                label={ot}
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                                    )}

                                    {questionType?.value?.value ===
                                      "multiple_options" && (
                                      <FormGroup>
                                        {options.value.map((item, i) => {
                                          let { optionText } = item;
                                          return (
                                            <FormControlLabel
                                              key={i}
                                              checked={_.includes(
                                                selectedOptions.value,
                                                optionText,
                                              )}
                                              value={optionText}
                                              control={<Checkbox />}
                                              label={optionText}
                                            />
                                          );
                                        })}
                                      </FormGroup>
                                    )}
                                  </div>
                                </div>
                              )}
                            </CardContent>
                            <Divider sx={{ mb: 0 }} />
                          </React.Fragment>
                        );
                      })}
                    </Card>
                  )}
              </Grid>
              <Grid item md={12}>
                <Card ref={questionRef}>
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center">
                      <Typography variant="h6">
                        {typeof selectedQuestionIndex === "number"
                          ? `Edit Question No - ${selectedQuestionIndex + 1}`
                          : "Add Question"}
                      </Typography>
                      <Box>
                        <Button
                          onClick={onClickClearQuestion}
                          variant="contained">
                          clear
                        </Button>
                      </Box>
                    </Box>
                    <br />
                    {/* question and question type */}
                    <Grid container spacing={2}>
                      {/* Question */}
                      <Grid item md={12}>
                        <TextField
                          error={question.questionText.error}
                          helperText={question.questionText.errorMessage}
                          margin="normal"
                          placeholder="Enter Question "
                          required
                          fullWidth
                          multiline
                          id="questionText"
                          label="Question"
                          name="questionText"
                          value={question.questionText.value}
                          onChange={onQuestionValueChangeHandler}
                        />
                      </Grid>

                      {/* question type */}
                      <Grid item md={12}>
                        <Autocomplete
                          disablePortal
                          id="questionType"
                          className="mt-1"
                          options={questionTypes}
                          fullWidth
                          value={question.questionType.value || null}
                          onChange={(e, newValue) => {
                            onQuestionValueChangeHandler({
                              target: {
                                name: "questionType",
                                value: newValue,
                              },
                            });
                          }}
                          getOptionLabel={(option) => `${option.label} `}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              helperText={question.questionType.errorMessage}
                              error={question.questionType.error}
                              label="Select Question Type "
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    {/* select option block and preview*/}
                    {question.questionText.value && (
                      <div className={classes.questionContainer}>
                        <div className={classes.question}></div>
                        <div className={classes.options}>
                          {question.questionType.value?.value ===
                            "multiple_options" && (
                            <FormGroup>
                              {question.options.value.map((item, i) => {
                                let { optionText } = item;
                                return (
                                  <FormControlLabel
                                    key={i}
                                    checked={_.includes(
                                      question.selectedOptions.value,
                                      optionText,
                                    )}
                                    value={optionText}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      let selectedOptions = _.cloneDeep(
                                        question.selectedOptions.value || [],
                                      );
                                      let alreadyExists = selectedOptions.find(
                                        (option) => option === value,
                                      );
                                      if (alreadyExists) {
                                        selectedOptions =
                                          selectedOptions.filter(
                                            (option) => option !== value,
                                          );
                                      } else {
                                        selectedOptions.push(value);
                                      }
                                      onQuestionValueChangeHandler({
                                        target: {
                                          value: selectedOptions,
                                          name: "selectedOptions",
                                        },
                                      });
                                    }}
                                    control={<Checkbox />}
                                    label={optionText}
                                  />
                                );
                              })}
                            </FormGroup>
                          )}

                          {question.questionType.value?.value ===
                            "single_option" && (
                            <FormControl>
                              <RadioGroup
                                name="radio-buttons-group"
                                onChange={(e) =>
                                  onQuestionValueChangeHandler({
                                    target: {
                                      value: e.target.value,
                                      name: "selectedOption",
                                    },
                                  })
                                }
                                value={question.selectedOption.value || null}>
                                {question.options.value.map((item, i) => {
                                  let { optionText } = item;
                                  return (
                                    <FormControlLabel
                                      key={i}
                                      value={optionText}
                                      control={<Radio />}
                                      label={optionText}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          )}
                        </div>
                      </div>
                    )}

                    {/* options block */}
                    {question.questionText.value &&
                      question.questionType.value && (
                        <>
                          {/* default add option */}
                          <Grid container spacing={2} sx={{ mb: 4 }}>
                            <Grid item md={8}>
                              <TextField
                                error={question.optionText.error}
                                helperText={question.optionText.errorMessage}
                                margin="normal"
                                placeholder="Enter Option "
                                required
                                inputRef={optionRef}
                                fullWidth
                                multiline
                                id="optionText"
                                label="Option"
                                variant="standard"
                                name="optionText"
                                value={question.optionText.value}
                                onChange={onQuestionValueChangeHandler}
                              />
                            </Grid>
                            <Grid item md={4}>
                              <Button
                                onClick={onAddOption}
                                fullWidth
                                variant="outlined"
                                sx={{
                                  mt: 4,
                                }}>
                                Add Option
                              </Button>
                            </Grid>
                          </Grid>

                          {/* options list */}
                          {question.options.value &&
                            question.options.value.length > 0 && (
                              <Grid container spacing={3} sx={{ mb: 4 }}>
                                {question.options.value.map((item, i) => {
                                  let { optionText, error, errorMessage } =
                                    item;
                                  return (
                                    <React.Fragment key={i}>
                                      <Grid item md={11} sm={11}>
                                        <TextField
                                          error={error}
                                          helperText={errorMessage}
                                          variant="standard"
                                          margin="normal"
                                          placeholder="Enter Option"
                                          required
                                          fullWidth
                                          label={`Option ${i + 1}`}
                                          value={optionText}
                                          onChange={(e) =>
                                            onOptionValueChangeHandler(
                                              i,
                                              e.target.value,
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={1} sm={1}>
                                        <IconButton
                                          sx={{ mt: 4 }}
                                          onClick={() => onClickDeleteOption(i)}
                                          aria-label="delete"
                                          color="error">
                                          <DeleteIcon />
                                        </IconButton>{" "}
                                      </Grid>
                                    </React.Fragment>
                                  );
                                })}
                              </Grid>
                            )}
                        </>
                      )}

                    <Divider sx={{ mb: 4 }} />
                    <Button
                      onClick={onAddOrEditQuestion}
                      variant="contained"
                      fullWidth
                      endIcon={<FaQuestion size={15} />}
                      color="info">
                      {typeof selectedQuestionIndex === "number"
                        ? "Update Question"
                        : "Add Question"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* <Typography variant="h6">
              {typeof selectedGroupIndex === "number"
                ? `Edit Group No - ${selectedGroupIndex + 1}`
                : "Add Group"}
            </Typography> */}
            <Divider sx={{ mb: 4 }} />
            <Button
              className={classes.addGroupBtn}
              sx={{ background: "#D20062" }}
              onClick={onAddEditGroup}
              variant="contained"
              fullWidth
              endIcon={<FaQuestion size={15} />}
              color="info">
              {typeof selectedGroupIndex === "number"
                ? `Update Group No - ${selectedGroupIndex + 1}`
                : "Add Group"}
            </Button>
          </CardContent>
        </Card>

        {/* Add quesiton form */}
      </Box>
    </section>
  );
};

export default CreateEditQuizNew;
