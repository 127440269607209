import { Outlet } from "react-router-dom";
import { BatchesContextProvider } from "../../../services/Batches/Batches.context";
import { useEffect } from "react";
import { UsersContextProvider } from "../../../services/users/users.context";
import { AuthenticationContextProvider } from "../../../services/Authentication/Authentication.context";

const UsersHome = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);
  return (
    <AuthenticationContextProvider>
      <BatchesContextProvider>
        <UsersContextProvider>
          <Outlet />
        </UsersContextProvider>
      </BatchesContextProvider>
    </AuthenticationContextProvider>
  );
};

export default UsersHome;
