import React, { useState, useEffect, useContext } from "react";
import AceEditor from "react-ace";
import ace from "ace-builds";
import "ace-builds/webpack-resolver";
import classes from "./StudentPlayground.module.css";
import { executeJS, executePython } from "../../../utility/codeExecutors";
import { MenuItem, Select, FormControl } from "@mui/material";
import { CodeExecutionContext } from "../../../services/CodeExecution/CodeExecution.context";

const StudentPlayground = ({ title }) => {
  const [code, setCode] = useState("//code here \n");
  const [output, setOutput] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [theme, setTheme] = useState("dracula");
  const [currMode, setCurrMode] = useState("code");
  const [fontSize, setFontSize] = useState("12px");
  const { onExecuteJava, onExecuteCPP, onExecuteCLanguage } =
    useContext(CodeExecutionContext);

  useEffect(() => {
    document.title = title;
  }, []);

  function onChange(newValue) {
    setCode(newValue.toString());
  }

  const runCode = async () => {
    if (currMode !== "output") {
      setCurrMode("output");
    }
    try {
      if (language === "javascript") {
        setOutput(await executeJS(code));
      } else if (language === "python") {
        setOutput(await executePython(code));
      } else if (language === "java") {
        onExecuteJava(
          { code },
          (result) => {
            if (result && result.output) {
              setOutput(result.output);
            }
          },
          (error) => {
            console.log(error);
            setOutput(error.message.split("Main.")[1]);
          },
          true,
          false,
        );
        // setOutput(executeJavaCode(code));
      } else if (language === "c_cpp") {
        let lang = code.includes("#include <iostream>") ? "cpp" : "c";
        if (lang === "cpp") {
          onExecuteCPP(
            { code },
            (result) => {
              if (result && result.output) {
                setOutput(result.output);
              }
            },
            (error) => {
              console.log(error);
              setOutput(error.message)
            },
            true,
            false,
          );
        } else {
          onExecuteCLanguage(
            { code },
            (result) => {
              if (result && result.output) {
                setOutput(result.output);
              }
            },
            (error) => {
              console.log(error);
              setOutput(error.message)
            },
            true,
            false,
          );
        }
      }
    } catch (error) {
      setOutput("Error: " + error.message);
    }
  };

  const onModeChange = (mode) => {
    setCurrMode(mode);
  };

  const handleChangeLanguage = (e) => {
    setLanguage(e.target.value);
    if (output) {
      setOutput("");
    }
    if (currMode !== "code") {
      setCurrMode("code");
    }
    if (e.target.value === "javascript") {
      setCode("// code here \n");
    } else if (e.target.value === "python") {
      setCode("# code here \n");
    } else if (e.target.value === "java") {
      setCode(`
// code here
public class Main {
  public static void main(String[] args) {
    System.out.println("Hello World");
  }
}`);
    } else {
      setCode("// code here");
    }
  };

  return (
    <div className={classes.playgroundMain} style={{ padding: "28px" }}>
      <div className={classes.playgroundHeader}>
        <h3>Code Playground!</h3>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}>
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <div style={{ marginRight: "8px" }}>
              <FormControl variant="outlined" size="small">
                <Select
                  value={language}
                  size="small"
                  onChange={(e) => handleChangeLanguage(e)}
                  sx={{
                    minWidth: 120,
                    fontSize: "0.875rem",
                    "& .MuiMenuItem-root": { minHeight: 30 },
                    "& .MuiSelect-root": { paddingTop: 10, paddingBottom: 10 },
                  }}
                  className={classes.dropdown}>
                  <MenuItem value="javascript">Javascript</MenuItem>
                  <MenuItem value="python">Python</MenuItem>
                  <MenuItem value="java">Java (jdk 21)</MenuItem>
                  <MenuItem value="c_cpp">C & C++</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ marginRight: "8px" }}>
              <FormControl variant="outlined" size="small">
                <Select
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  size="small"
                  sx={{
                    minWidth: 120,
                    fontSize: "0.875rem",
                    "& .MuiMenuItem-root": { minHeight: 30 },
                    "& .MuiSelect-root": { paddingTop: 10, paddingBottom: 10 },
                  }}
                  className={classes.dropdown}>
                  <MenuItem value="dracula">Dracula</MenuItem>
                  <MenuItem value="monokai">Monokai</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl variant="outlined" size="small">
                <Select
                  value={fontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                  size="small"
                  sx={{
                    minWidth: 100,
                    fontSize: "0.875rem",
                    "& .MuiMenuItem-root": { minHeight: 30 },
                    "& .MuiSelect-root": { paddingTop: 10, paddingBottom: 10 },
                  }}
                  className={classes.dropdown}>
                  <MenuItem value="10px">10px</MenuItem>
                  <MenuItem value="12px">12px</MenuItem>
                  <MenuItem value="14px">14px</MenuItem>
                  <MenuItem value="16px">16px</MenuItem>
                  <MenuItem value="18px">18px</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <div style={{ marginLeft: "8px" }}>
              <button
                className={classes.actionBtns}
                variant="outlined"
                size="small"
                onClick={runCode}>
                Run
              </button>
            </div>
            <div style={{ marginLeft: "8px" }}>
              <button
                className={classes.actionBtns}
                variant="outlined"
                size="small"
                onClick={() => setOutput("")}>
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.editorContainer}>
        {(window.innerWidth > 500 || currMode === "code") && (
          <div>
            <div className={classes.tabsContainer}>
              <button
                className={currMode === "code" ? classes.activeTab : ""}
                onClick={() => onModeChange("code")}>
                code
              </button>
              <button
                className={currMode === "output" ? classes.activeTab : ""}
                onClick={() => onModeChange("output")}>
                output
              </button>
            </div>
            <AceEditor
              className={classes.editor}
              style={{
                borderRadius: "0px 5px 5px",
                overflowY: "auto",
              }}
              mode={language}
              theme={theme}
              onChange={onChange}
              name="ace-editor"
              value={code}
              fontSize={fontSize}
              width="100%"
              height="500px"
              editorProps={{ $blockScrolling: true }}
              showGutter={true}
              setOptions={{
                tabSize: 4,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                spellcheck: true,
              }}
            />
          </div>
        )}
        {(window.innerWidth > 500 || currMode === "output") && (
          <div>
            <div className={classes.tabsContainer}>
              <button
                className={currMode === "code" ? classes.activeTab : ""}
                onClick={() => onModeChange("code")}>
                code
              </button>
              <button
                className={currMode === "output" ? classes.activeTab : ""}
                onClick={() => onModeChange("output")}>
                output
              </button>
            </div>
            <div className={`${classes.output}`}>
              {window.innerWidth > 500 && <h6>Output:</h6>}
              <pre>
                <code>{output}</code>
              </pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentPlayground;
