import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import React, { useContext, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { MdAssignmentAdd } from "react-icons/md";
import classes from "./CreateEditDSA.module.css";
import { BatchesContext } from "../../../services/Batches/Batches.context";
import _ from "lodash";
import { DSAContext } from "../../../services/DSA/DSA.context";
import { useNavigate } from "react-router-dom";

const CreateEditDSA = ({ mode }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorError, setEditorError] = useState("");
  const [loading, setLoading] = useState(false);
  const { onGetBatches } = useContext(BatchesContext);
  const { onCreateDSA } = useContext(DSAContext);
  const [batches, setBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState(null);
  const [isAvailableToEveryone, setIsavailableToEveryone] = useState(false);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [difficultyLevel, selectDiffycultyLevel] = useState("easy");

  useEffect(() => {
    getBatches();
  }, []);

  const onClickAddDSA = () => {
    const data = {
      isAvailableToEveryone,
      title,
      difficultyLevel
    };
    if (!isAvailableToEveryone && selectedBatches?.length > 0) {
      data.batches = selectedBatches.map((b) => b._id);
    }
    if (editorState) {
      const currentContent = editorState.getCurrentContent();
      const html = convertToHTML(currentContent);
      data.content = html;
    }

    onCreateDSA(
      data,
      (result) => {
        console.log(result);
        navigate("/dashboard/dsa");
      },
      (error) => {
        console.log(error);
      },
      true,
      true,
    );
  };

  const handleEditorChange = (newState) => {
    setEditorState(newState);
    const currentContent = newState.getCurrentContent();
    const html = convertToHTML(currentContent);
    // console.log(html);
    if (!html || html === "<p></p>") {
      setEditorError("The body cannot be empty.");
    } else {
      setEditorError("");
    }
  };

  const getBatches = (query = "?dropDown=yes") => {
    onGetBatches(
      query,
      (result) => {
        setLoading(false);
        if (result && result.batches) {
          setBatches(result.batches);
        }
      },
      () => {
        setLoading(false);
      },
      true,
      false,
    );
  };

  const handleCheckboxChange = (e) => {
    setIsavailableToEveryone(e.target.checked);
  };

  const onChangeSelectBatch = (e, newValue) => {
    if (newValue.length === 0) {
      setSelectedBatches([]);
      return;
    }
    let uniqueValues = _.uniqBy(newValue, "_id");
    setSelectedBatches(uniqueValues);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDifficultyLevelChange = (e) => {
    selectDiffycultyLevel(e.target.value);
  };

  return (
    <Card sx={{ p: 2 }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}>
          <Typography gutterBottom variant="h5" component="div">
            {mode === "create" ? "Add New Quiz " : "Edit Quiz"}
          </Typography>

          <LoadingButton
            type="button"
            onClick={onClickAddDSA}
            loadingPosition="end"
            endIcon={<MdAssignmentAdd />}
            color="primary"
            loading={loading}
            loadingIndicator={"Adding..."}
            variant="contained"
            sx={{ display: "flex", ml: "auto" }}>
            {!loading && mode === "edit" ? "PROCEED & UPDATE" : "PROCEED & ADD"}
          </LoadingButton>
        </Box>
        <Box>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName={classes.editorClass}
            toolbarClassName="toolbar-class">
            {editorError && <div className="error-message">{editorError}</div>}
          </Editor>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              margin="normal"
              placeholder="Enter title"
              required
              fullWidth
              id="name"
              label="Title"
              name="title"
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item md={6}>
            <FormControl variant="outlined">
              <Select
                value={difficultyLevel}
                fullWidth
                onChange={handleDifficultyLevelChange}>
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} sx={{ mt: 2 }}>
            <FormGroup>
              <FormControlLabel
                checked={isAvailableToEveryone}
                onChange={handleCheckboxChange}
                control={<Checkbox />}
                label={`Is available to everyone`}
              />
            </FormGroup>
          </Grid>

          {!isAvailableToEveryone && (
            <>
              <Grid item md={6}>
                <Autocomplete
                  disablePortal
                  id="batches"
                  className="mt-1"
                  options={batches}
                  multiple
                  fullWidth
                  value={selectedBatches || []}
                  onChange={(e, newValue) => {
                    onChangeSelectBatch(e, newValue);
                  }}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.code} `
                  }
                  renderInput={(params) => (
                    <TextField {...params} required label="Select Batches " />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Card>
  );
};

export default CreateEditDSA;
