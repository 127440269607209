import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import classes from "./Student.module.css";
import { FaChevronUp } from "react-icons/fa";
import { scrollToTop } from "../../utility/helper";
import Sidebar from "../../shared/Sidebar/Sidebar";

const Student = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsVisible(scrollTop > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isCourseView = pathname === "/student/courses/view";
  const isPracticeView = pathname === "/student/practice/view";
  const quizStart = pathname === "/student/quiz/start";
  const quizNewStart = pathname === "/student/quiz-new/start";

  const specialCase = () => {
    return isCourseView || quizStart || quizNewStart || isPracticeView;
  };

  const notASpecialCase = () => {
    return !isCourseView && !quizStart && !quizNewStart && !isPracticeView;
  };

  return (
    <>
      {false && (
        <div
          className={`${classes.scrollButton} ${
            isVisible ? classes.visible : ""
          }`}
          onClick={scrollToTop}>
          <FaChevronUp className={classes.scrollIcon} />
        </div>
      )}
      <div className={classes.mainContainer}>
        <Sidebar style={{maxHeight: "100vh"}} />
        <Box
          className={
            notASpecialCase() ? classes.outlet : classes.courseView
          }
          component="main"
          sx={{
            minHeight: "100vh",
            width: "100%",
            background: "rgb(12, 15, 42)",
            mt: specialCase() ? "66px" : "",
            flex: 1,
            overflow: "visible",
            maxHeight: "100vh"
          }}>
          <div
            style={{
              width: "100%",
              background: "#fff",
              overflowY: "auto",
              maxHeight: "auto"
            }}
            className={
              notASpecialCase()
                ? `${classes["top-bottom-left-border"]} ${classes.studentContent}`
                : ""
            }>
              <Outlet style={{ width: "95%" }} />
          </div>
        </Box>
      </div>
    </>
  );
};

export default Student;
