/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { BatchesContext } from "../../../services/Batches/Batches.context";
import { SocketContext } from "../../../services/Socket/Socket.context";
import { scrollToTop } from "../../../utility/helper";
import SearchIcon from "@mui/icons-material/Search";
import { ReportsContext } from "../../../services/Reports/Reports.context";
import classes from "./Batches.module.css";
import { DateTimePicker } from "@mui/x-date-pickers";

const Batches = ({ title }) => {
  const { onGetBatches, onDeleteBatch, onGetBatchQuizzes } =
    useContext(BatchesContext);
  const { socket, onFetchEvent, onEmitEvent } = useContext(SocketContext);
  const { onDownloadQuizReports } = useContext(ReportsContext);
  const [batches, setBatches] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalBatches, setTotalBatches] = useState(0);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [currBatchQuizzes, setCurrBatchQuizzes] = useState([]);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getBatches();
  }, [page, rowsPerPage]);

  const getBatchQuizzes = (_id) => {
    const query = `?id=${_id}`;
    onGetBatchQuizzes(
      query,
      (result) => {
        setCurrBatchQuizzes(result.batchQuizzes);
      },
      (err) => {
        console.log(err);
      },
      true,
      false,
    );
  };

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        getBatches();
      };
      onFetchEvent("refreshBatches", eventHandler);
      return () => {
        socket?.off("refreshBatches", eventHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchEvent, socket]);

  const getBatches = (
    query = `?page=${page + 1}&limit=${rowsPerPage}&pagination=yes`,
  ) => {
    if (searchKeyword.trim()) {
      query += `&searchKeyword=${searchKeyword}`;
    }
    scrollToTop();
    onGetBatches(
      query,
      (result) => {
        setLoading(false);
        if (result && result.batches) {
          setBatches(result.batches);
          setTotalBatches(result.totalBatches);
        }
      },
      () => {
        setLoading(false);
      },
      true,
      false,
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (page === 0) {
      getBatches();
    } else {
      setPage(0);
    }
  };

  const onClickEditBatch = (batch) => {
    navigate("edit?id=" + batch._id);
  };

  const onClickDeleteBatch = (batch) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: `${batch.name} - ${batch.code}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteBatch(batch._id, (result) => {
          onEmitEvent("refreshBatches");
        });
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentBatches = batches;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["name", "code"];
    if (fields.includes(fieldToSort)) {
      let sortedBatches = _.orderBy(currentBatches, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setBatches(sortedBatches);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearch();
    }
  };

  const handleOpen = (id) => {
    setOpenDownloadModel(true);
    getBatchQuizzes(id);
  };

  const handleClose = () => {
    setOpenDownloadModel(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleCheckboxChange = ({ id, isNewQuizSchema }, isChecked) => {
    if (isChecked) {
      setSelectedQuizzes([...selectedQuizzes, { id, isNewQuizSchema }]);
    } else {
      setSelectedQuizzes(selectedQuizzes.filter((quiz) => quiz.id !== id));
    }
  };

  const handleSelectAll = () => {
    const quizData = currBatchQuizzes.map((quiz) => ({
      id: quiz._id,
      isNewQuizSchema: quiz.isNewQuizSchema,
    }));
    setSelectedQuizzes(quizData);
  };

  const handleDeselectAll = () => {
    setSelectedQuizzes([]);
  };

  function convertUTCToFormattedIST(utcDateStr) {
    const utcDate = new Date(utcDateStr);

    const ISTOffset = 5 * 60 + 30;

    const istDate = new Date(utcDate.getTime() + ISTOffset * 60 * 1000);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    return istDate.toLocaleString("en-IN", options);
  }

  const handleDownloadReportsClick = () => {
    onDownloadQuizReports(
      {
        selectedQuizzes,
        selectedStartDate,
        selectedEndDate,
      },
      (result) => {
        try {
          let csvContent = "\n";
          csvContent += `"TotalQuizes",${
            Object.keys(result.reports).length
          }\n\n`;
          for (const quizId in result.reports) {
            if (result.reports.hasOwnProperty(quizId)) {
              const attempts = result.reports[quizId];
              if (attempts.length === 0) {
                break;
              }
              const quiz = attempts[0].quiz;
              csvContent += `"Quiz Name","Total Marks","Pass Percentage","Max Attempts","Available From"\n`;
              csvContent += `"${quiz.name || ""}","${quiz.totalMarks || ""}","${
                quiz.passPercentage || ""
              }","${quiz.attempts || ""}","${
                convertUTCToFormattedIST(quiz.createdAt) || ""
              }"\n`;
              csvContent += `"No. of students attempted","${attempts.length}"\n\n`;
              csvContent += `"Roll Number","First Name","Last Name","Email","Marks Obtained","Percentage","Result","Attempted at"\n\n`;
              attempts.forEach((attempt) => {
                const user = attempt.user;
                if (!user) {
                  console.error(
                    `User object is undefined or null for attemptId: ${attempt._id}`,
                  );
                  return;
                }
                csvContent += `"${user.rollNumber || "-----"}","${user.firstName || ""}","${
                  user.lastName || ""
                }","${user.email || ""}","${attempt.marksObtained || ""}","${
                  attempt.percentage || ""
                }","${attempt.result || ""}","${
                  convertUTCToFormattedIST(attempt.createdAt) || ""
                }"\n`;
              });
              csvContent += "\n\n";
            }
          }
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "reports.csv";
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (e) {
          console.error(e);
        }
      },
      (e) => {
        console.log(e);
      },
      true,
      true,
    );
  };

  const handleStartDateChange = (newValue) => {
    setSelectedStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setSelectedEndDate(newValue);
  };

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <h2>Batches - {totalBatches}</h2>
        <Link to={"create"}>
          <Button variant="contained" type="button" startIcon={<FaPlus />}>
            Add New Batch
          </Button>
        </Link>
      </Box>

      <TextField
        margin="normal"
        fullWidth
        id="search"
        variant="standard"
        label="Search By Keyword"
        name="search"
        value={searchKeyword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => onClickSearch()}
                edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => onChangeSearchKeyword(e)}
        onKeyDown={handleKeyPress}
      />

      {batches.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Batches Found</NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}

      {batches.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4, overflowY: "scroll" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>
                  {" "}
                  <TableSortLabel
                    direction={
                      sort.type && sort.type === "desc" ? "asc" : "desc"
                    }
                    active
                    onClick={() => onChangeSorting("name")}>
                    BATCH NAME
                  </TableSortLabel>
                </TableCell>
                <TableCell>BATCH CODE</TableCell>
                <TableCell>No of Students</TableCell>
                <TableCell>No of Trainers</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batches.map((batch, index) => {
                let {
                  name,
                  code,
                  studentsCount,
                  trainersCount,
                  _id,
                  createdAt,
                } = batch;

                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    <TableCell>{studentsCount}</TableCell>
                    <TableCell>{trainersCount}</TableCell>

                    <TableCell>
                      {moment(createdAt).format("MMM DD, YYYY - hh:mm A")}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={() => onClickEditBatch(batch)}
                        aria-label="edit"
                        color="info">
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => onClickDeleteBatch(batch)}
                        aria-label="delete"
                        color="error">
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="download"
                        onClick={() => handleOpen(_id)}>
                        <DownloadIcon />
                        <span style={{ fontSize: "15px" }}>Reports</span>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  count={totalBatches}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  labelRowsPerPage="Batches Per Page"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      <Modal
        open={openDownloadModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <div style={{ display: "flex" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h4>Select Quizzes</h4>
                </div>
                <div
                  onClick={
                    selectedQuizzes.length > 0
                      ? handleDeselectAll
                      : handleSelectAll
                  }
                  className={classes.selectAll}>
                  {selectedQuizzes.length > 0 ? "Deselect All" : "Select All"}
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <div style={{ marginBottom: "15px" }}>
                  <DateTimePicker
                    label="Start Time"
                    format="DD-MM-YYYY hh:mm:ss A"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    value={selectedStartDate}
                    onChange={(newValue) => {
                      handleStartDateChange(newValue);
                    }}
                  />
                </div>
                <div>
                  <DateTimePicker
                    label="End Time"
                    format="DD-MM-YYYY hh:mm:ss A"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    value={selectedEndDate}
                    onChange={(newValue) => {
                      handleEndDateChange(newValue);
                    }}
                  />
                </div>
              </div>
              <div>
                {currBatchQuizzes && currBatchQuizzes.length > 0 && (
                  <Box mt={2}>
                    <TableContainer
                      component={Paper}
                      sx={{
                        mt: 4,
                        maxHeight: "350px !important",
                        overflowY: "scroll",
                      }}>
                      <Table sx={{ minWidth: 350 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Quiz Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currBatchQuizzes.map((quiz, index) => {
                            let { name, _id, isNewQuizSchema } = quiz;
                            return (
                              <TableRow
                                key={_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}>
                                <TableCell>
                                  <FormGroup>
                                    <FormControlLabel
                                      checked={selectedQuizzes.some(
                                        (quiz) => quiz.id === _id,
                                      )}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          { id: _id, isNewQuizSchema },
                                          e.target.checked,
                                        )
                                      }
                                      control={<Checkbox />}
                                    />
                                  </FormGroup>
                                </TableCell>

                                <TableCell>{name}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </div>
            </div>
          </div>
          <Button
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            component="span"
            onClick={handleDownloadReportsClick}>
            Download Reports
          </Button>
        </Box>
      </Modal>
    </section>
  );
};

export default Batches;
