/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useRef, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  Grid,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useNavigate } from "react-router-dom";
import classes from "./StudentCourses.module.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { CourseNewContext } from "../../../services/CoursesNew/CoursesNew.context";
import { generatePresignedUrl } from "../../../utility/s3Helpers";

const StudentCourses = ({ title }) => {
  const { onGetCourses } = useContext(CourseNewContext);

  const [courses, setCourses] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(0);
  const totalCourses = useRef(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    document.title = title;
    getCourses();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      fetchImageUrls();
    }
  }, [courses]);

  const fetchImageUrls = async () => {
    try {
      const urls = await Promise.all(
        courses.map(async (course) => {
          try {
            const signedUrl = await generatePresignedUrl(course.banner);
            return signedUrl;
          } catch (err) {
            console.log(err);
            return null;
          }
        }),
      );
      setImageUrls(urls);
    } catch (e) {
      console.error("Error fetching presigned URLs:", e);
    }
  };

  const getCourses = (query = `?page=${page + 1}&limit=${rowsPerPage}`) => {
    if (searchKeyword.trim()) {
      query += `&searchKeyword=${searchKeyword}`;
    }

    onGetCourses(
      query,
      (result) => {
        setLoading(false);
        if (result && result.courses) {
          setCourses(result.courses);
          totalCourses.current = result.totalCourses;
        }
      },
      true,
      false,
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (page === 0) {
      getCourses();
    } else {
      setPage(0);
    }
  };

  const onClickViewCourse = (course) => {
    navigate("view?id=" + course._id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearch();
    }
  };

  return (
    <section className={classes.coursesMain} style={{ padding: "28px" }}>
      <div className={classes.headerContainer}>
        <div className={classes.headerText}>
          <h4>Enrolled courses: {totalCourses.current}</h4>
        </div>
        <div className={classes.searchContainer}>
          <div className={classes["wrapper"]}>
            <div className={classes["searchBar"]}>
              <input
                id={classes["searchQueryInput"]}
                type="text"
                name="searchQueryInput"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e) => onChangeSearchKeyword(e)}
                onKeyDown={handleKeyPress}
              />
              <button
                onClick={() => onClickSearch()}
                id={classes["searchQuerySubmit"]}
                type="submit"
                name="searchQuerySubmit">
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24">
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {courses.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Courses Available!</NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}
      {courses.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: "12px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead></TableHead>
            <TableBody
              sx={{
                "& div:last-child": { border: 0 },
              }}>
              <div className={classes.courses}>
                {courses.map((c, i) => {
                  let { title, headline, totalDuration, totalLectures } = c;
                  totalDuration = (totalDuration / 3600).toFixed(2);
                  return (
                    <div key={i} className={`cp ${classes.course}`}>
                      <Grid sx={{ padding: "15px" }} container spacing={3}>
                        <Grid
                          item
                          sx={{ pt: "0 !important", cursor: "default" }}
                          md={5}>
                          <img
                            className={classes.banner}
                            src={imageUrls[i]}
                            alt="..."
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{ pt: "0 !important", cursor: "default" }}
                          md={7}>
                          <div className={classes.courseTitle}>{title}</div>
                          <div className={classes.courseHeadline}>
                            {headline}
                          </div>
                          <div className={classes.durationContainer}>
                            <p className={classes.totalDuration}>
                              {totalDuration} total hours
                            </p>
                            <p className={classes.totalLectures}>
                              {totalLectures} Lectures
                            </p>
                          </div>
                          <div>
                            <button
                              className={classes.continueBtn}
                              onClick={() => onClickViewCourse(c)}>
                              Continue Learning <ArrowRightIcon />
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  labelRowsPerPage="Courses Per Page"
                  count={totalCourses.current}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </section>
  );
};

export default StudentCourses;
