import { useContext, useEffect, useRef, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../../../utility/helper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import styles from "../../../styles/styles.module.css";
import classes from "./StudentQuizNew.module.css";
import { QuizNewContext } from "../../../services/QuizNew/QuizNew.context";

const StudentQuizNew = ({ title }) => {
  const { onGetQuizes } = useContext(QuizNewContext);
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalQuizzes = useRef(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getQuizes();
  }, [page, rowsPerPage]);

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (page === 0) {
      getQuizes();
    } else {
      setPage(0);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getQuizes = (query = `?page=${page + 1}&limit=${rowsPerPage}`) => {
    if (searchKeyword.trim()) {
      query += `&searchKeyword=${searchKeyword}`;
    }

    onGetQuizes(
      query,
      (result) => {
        setLoading(false);
        setQuizes(result.quizzes);
        totalQuizzes.current = result.totalQuizzes;
      },
      true,
      false,
    );
  };

  const AvailableFromCountdownTimer = ({ availableFrom }) => {
    const calculateTimeRemaining = () => {
      const now = moment();
      const startDate = moment(availableFrom);
      const duration = moment.duration(startDate.diff(now));
      const secondsRemaining = Math.max(0, duration.asSeconds());
      return Math.floor(secondsRemaining);
    };

    const [timeRemaining, setTimeRemaining] = useState(
      calculateTimeRemaining(),
    );
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);

      return () => clearInterval(timer);
    }, [availableFrom]);

    const formatTime = (time) => moment.utc(time * 1000).format("hh:mm:ss");
    const hasStarted = timeRemaining === 0;

    return (
      <p
        className={styles.fontGorditaRegular}
        style={{
          color: hasStarted ? "#378CE7" : "#D37676",
          marginBottom: 0,
        }}>
        {hasStarted
          ? `${moment(availableFrom).format("MMM DD, YYYY - hh:mm A")}`
          : `Starts In ${formatTime(timeRemaining)}`}
      </p>
    );
  };

  const onViewQuiz = (quiz) => {
    if(quiz.isNewQuizSchema) {
      navigate(`start?id=${quiz._id}`);
    }else {
      navigate(`/student/quiz/start?id=${quiz._id}`);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearch();
    }
  };

  return (
    <div className={classes.quizMain} style={{ padding: "28px" }}>
      <div className={classes.headerContainer}>
        <div className={classes.headerText}>
          <h4>Available quizzes: {totalQuizzes.current}</h4>
        </div>
        <div className={classes.searchContainer}>
          <div className={classes["wrapper"]}>
            <div className={classes["searchBar"]}>
              <input
                id={classes["searchQueryInput"]}
                type="text"
                name="searchQueryInput"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e) => onChangeSearchKeyword(e)}
                onKeyDown={handleKeyPress}
              />
              <button
                onClick={() => onClickSearch()}
                id={classes["searchQuerySubmit"]}
                type="submit"
                name="searchQuerySubmit">
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24">
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {quizes.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>
              No Quizes Available Right Now! <br /> Come Back Later
            </NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}
      {quizes.length > 0 && (
        <TableContainer
          className={classes.quizTable}
          component={Paper}
          sx={{ mt: "12px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>Quiz</TableCell>
                <TableCell>Time Limit</TableCell>
                <TableCell>No of Attempts</TableCell>
                <TableCell>Available From </TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Available Until</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizes.map((quiz, index) => {
                let {
                  name,
                  timeLimit,
                  timeLimitEnabled,
                  attemptsEnabled,
                  attempts,
                  availableFrom,
                  availableUntil,
                  dueDate,
                  _id,
                } = quiz;

                let dueDateExceeded = moment().isAfter(dueDate);

                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: index % 2 === 0 ? "rgb(250, 250, 250)" : "inherit",
                    }}>
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell align="center">
                      {timeLimitEnabled ? `${formatTime(timeLimit)}` : "∞"}
                    </TableCell>
                    <TableCell align="center">
                      {attemptsEnabled ? `${attempts}` : "∞"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {/* for future use */}
                      {/* <AvailableFromCountdownTimer
                        availableFrom={availableFrom}
                      /> */}
                      {moment(availableFrom).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: dueDateExceeded ? "#D37676" : "#7F9F80",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}>
                      {dueDate
                        ? moment(dueDate).format("MMM DD, YYYY")
                        : "No Due Date"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {moment(availableUntil).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => onViewQuiz(quiz)}
                        variant="outlined"
                        // startIcon={<MdVisibility  />}
                        color="info">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
              <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]} // Ensure this is an empty array
                  count={totalQuizzes.current}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  // Use componentsProps to style and hide the unwanted elements
                  componentsProps={{
                    select: { style: { display: "none" } }, // Hide the rows per page select dropdown
                    actions: {
                      style: { display: "flex", justifyContent: "flex-end" },
                    }, // Align pagination actions properly
                    labelDisplayedRows: { style: { display: "none" } }, // Hide the displayed rows label
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default StudentQuizNew;
