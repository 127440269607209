import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

dayjs.extend(weekday);

const HorizontalCalender = ({
  daysArray,
  scrollMenuRef,
  handleDateClick,
  selectedDate,
  scrollToSelectedDate,
  handleChangeSelectedDate,
}) => {
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>
            <p
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                color: "#758694",
              }}>
              {selectedDate.format("MMMM YYYY")}
            </p>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div>
              <DatePicker
                label="Pick Date"
                value={selectedDate}
                onChange={(newValue) => {
                  handleChangeSelectedDate(newValue);
                  scrollToSelectedDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </div>
        <ScrollMenu ref={scrollMenuRef}>
          {daysArray.map((day, index) => (
            <div
              key={index}
              onClick={() => handleDateClick(day)}
              style={{
                width: "50px",
                fontSize: "12px",
                padding: "5px",
                margin: "5px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: day.isSame(selectedDate, "day")
                  ? "#5A639C" // color for the selected date
                  : day.day() === 0 // day.day() returns 0 for Sunday
                  ? "#FFAFAF" // color for Sundays (e.g., light pink)
                  : "#f5f5f5", // default color for other days
                color: day.isSame(selectedDate, "day")
                  ? "#FFFBE6"
                  : day.day() === 0 // day.day() returns 0 for Sunday
                  ? "#FFF8E8"
                  : "#758694",
              }}>
              <div
                style={{
                  fontWeight: "bold",
                  color: day.isSame(selectedDate, "day")
                    ? "#FFFBE6"
                    : day.day() === 0 // day.day() returns 0 for Sunday
                    ? "#FFF8E8"
                    : "#405D72",
                }}>
                {day.format("ddd")}
              </div>
              <div>{day.format("DD")}</div>
            </div>
          ))}
        </ScrollMenu>
      </div>
    </>
  );
};

export default HorizontalCalender;
