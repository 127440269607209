import React, { useContext, useEffect, useState, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import classes from "./StudentAllNotifications.module.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationsContext } from "../../../services/Notification/Notification.context";
import { useNavigate } from "react-router-dom";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import QuizIcon from "@mui/icons-material/Quiz";
import Points from "../../../assets/icons/points.svg";
import Coins from "../../../assets/icons/coins.svg";

const StudentAllNotifications = ({ handleClickBack }) => {
  const { onGetNotifications } = useContext(NotificationsContext);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const totalNotifications = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalNotifications.current / 10);

  useEffect(() => {
    getNotifications((currentPage - 1) * 10);
  }, [currentPage]);

  const getNotifications = (skip = 0) => {
    let query = `?limit=10&skip=${skip}`;
    onGetNotifications(
      query,
      (result) => {
        if (result && result?.notifications) {
          setNotifications(result.notifications);
          totalNotifications.current = result.total;
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      },
      (e) => {
        console.log(e);
      },
      true,
      false,
    );
  };

  const convertUTCtoIST = (utcDateTime) => {
    const date = new Date(utcDateTime);
    // Adjust for Indian Standard Time (IST) offset (UTC +5:30)
    const istDate = new Date(date.getTime());
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // For 12-hour format
    };
    return istDate.toLocaleString("en-US", options);
  };

  const hanldeNotificationClick = (type, id) => {
    if (type === "video") {
      navigate(`/student/courses/view?id=${id}`);
    } else if (type === "quiz") {
      navigate(`/student/quiz/start?id=${id}`);
    }
  };

  const renderPagination = () => {
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    return (
      <div className={classes.paginationContainer}>
        <div>
          <button
            className={classes.paginationBts}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prevPage)}>
            Prev
          </button>
        </div>
        <div>{currentPage}</div>
        <div>
          <button
            className={classes.paginationBts}
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(nextPage)}>
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.allNotificationMain}>
      <div className={classes.notificationMainHeader}>
        <div className={classes.backBtn} onClick={handleClickBack}>
          <ArrowBackIosIcon height={20} width={20} /> Back
        </div>
        <div className={classes.notificationAllHeader}>
          <div className={classes.notificationIcon}>
            <NotificationsIcon />
          </div>
          <div className={classes.allNotificationText}>All Notifications</div>
        </div>
      </div>
      <div className={classes.notificationsContainer}>
        {notifications?.length > 0 && (
          <>
            <div>
              {notifications?.map((notification, index) => {
                const {
                  _id,
                  type,
                  read,
                  message,
                  createdAt,
                  courseId,
                  quizId,
                } = notification;
                const isTypeVideo = type === "video";
                const isTypeQuiz = type === "quiz";
                const isTypePoints = type === "points";
                const isTypeCoins = type === "coins";
                return (
                  <div
                    style={{
                      backgroundColor: index % 2 === 0 ? "#eaf0f7" : "",
                    }}
                    key={_id}
                    onClick={() =>
                      hanldeNotificationClick(
                        type,
                        courseId ? courseId : quizId,
                      )
                    }
                    className={`${classes.notification} ${
                      isTypeVideo || isTypeQuiz ? classes.pointer : ""
                    }`}>
                    <div className={classes.notificationHeader}>
                      <div className={classes.notificationType}>
                        {isTypeVideo && (
                          <>
                            <div className={classes.playIcon}>
                              <PlayCircleIcon />
                            </div>
                            <div className={classes.typeVideoText}>Video</div>
                          </>
                        )}
                        {isTypeQuiz && (
                          <>
                            <div className={classes.quizIcon}>
                              <QuizIcon />
                            </div>
                            <div className={classes.typeQuizText}>Quiz</div>
                          </>
                        )}
                        {isTypePoints && (
                          <>
                            <div className={classes.pointsIcon}>
                              <img
                                width={16}
                                height={16}
                                src={Points}
                                alt="points"
                              />
                            </div>
                            <div className={classes.typePointsText}>Points</div>
                          </>
                        )}
                        {isTypeCoins && (
                          <>
                            <div className={classes.coinsIcon}>
                              <img
                                width={16}
                                height={16}
                                src={Coins}
                                alt="coins"
                              />
                            </div>
                            <div className={classes.typeCoinsText}>Coins</div>
                          </>
                        )}
                      </div>
                      <div className={classes.dateText}>
                        {!read && <div className={classes.redDot}></div>}
                        <div>{convertUTCtoIST(createdAt)}</div>
                      </div>
                    </div>
                    <div className={classes.notificationText}>{message}</div>
                  </div>
                );
              })}
            </div>
            <div>{renderPagination()}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default StudentAllNotifications;
