export const setSessionStorage = (name, data) => {
  sessionStorage.setItem(name, data);
};

export const getSessionStorage = (name) => {
  var data = sessionStorage.getItem(name);
  return data;
};

export const removeSesionStorage = (name) => {
  sessionStorage.removeItem(name);
};
