import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-horizontal-scrolling-menu/dist/styles.css";
import weekday from "dayjs/plugin/weekday";
import HorizontalCalender from "./HorizontalCalender";
import WorkUpdateForm from "./WorkUpdateForm";
import classes from "./TrainerData.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TrainerStatusContext } from "../../../services/TrainerStatus/TrainerStatus.context";
import WorkUpdateListing from "./WorkUpdateListing";
import { AuthenticationContext } from "../../../services/Authentication/Authentication.context";
import LinkIcon from "@mui/icons-material/Link";

dayjs.extend(weekday);

const TrainerData = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [daysToShow, setDaysToShow] = useState(15);
  const [showForm, setShowForm] = useState(false);
  const scrollMenuRef = useRef(null);
  const { onFetchWorkUpdates } = useContext(TrainerStatusContext);
  const { userData } = useContext(AuthenticationContext);
  const [lectures, setLectures] = useState([]);

  const calculateDaysToShow = useCallback(() => {
    const width = window.innerWidth;
    const daysCount = Math.floor(width / 70) / 2;
    setDaysToShow(Math.max(2, daysCount));
  }, []);

  useEffect(() => {
    if (!showForm) {
      onFetchWorkUpdates(
        { date: selectedDate },
        (result) => {
          if (result && result.workUpdates) {
            setLectures(result.workUpdates);
          }
        },
        (error) => {
          console.log(error);
        },
        true,
        false,
      );
    }
  }, [showForm, selectedDate]);

  useEffect(() => {
    calculateDaysToShow();
    window.addEventListener("resize", calculateDaysToShow);
    return () => window.removeEventListener("resize", calculateDaysToShow);
  }, [calculateDaysToShow]);

  useEffect(() => {
    scrollToSelectedDate(selectedDate);
  }, [selectedDate]);

  const daysArray = useMemo(() => {
    const startDay = selectedDate;
    let days = [];
    for (let i = -daysToShow; i <= daysToShow; i++) {
      days.push(startDay.add(i, "day"));
    }
    return days;
  }, [selectedDate, daysToShow]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    scrollToSelectedDate(date);
  };

  const scrollToSelectedDate = (date) => {
    const index = daysArray.findIndex((day) => day.isSame(date, "day"));
    if (scrollMenuRef.current) {
      scrollMenuRef.current.scrollToItem(index, "smooth", "center");
    }
  };

  const handleChangeSelectedDate = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleClickAddWorkUpdate = () => {
    setShowForm(true);
  };

  const handleClickBack = () => {
    setShowForm(false);
  };

  const handleEdit = (id) => {
    console.log("Edit clicked for ID:", id);
  };

  const handleDelete = (id) => {
    console.log("Delete clicked for ID:", id);
  };

  const formateUrl = (url) => {
    return url.startsWith("http") ? url : `https://${url}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ width: "100%" }}>
        <h2>Trainer Work update</h2>
        <div
          style={{
            color: "#758694",
          }}>
          Runbook url
          <a
            style={{ marginLeft: "10px" }}
            rel="noreferrer noopener"
            target="_blank"
            href={formateUrl(userData?.runbookUrl || "google.com")}>
            <LinkIcon height={15} width={15} />
          </a>
        </div>

        <HorizontalCalender
          daysArray={daysArray}
          scrollMenuRef={scrollMenuRef}
          handleDateClick={handleDateClick}
          selectedDate={selectedDate}
          scrollToSelectedDate={scrollToSelectedDate}
          handleChangeSelectedDate={handleChangeSelectedDate}
        />
        <hr />
        <div>
          {!showForm ? (
            <button
              className={classes.addWorkButton}
              onClick={handleClickAddWorkUpdate}>
              Add work update
            </button>
          ) : (
            <button className={classes.backButton} onClick={handleClickBack}>
              <ArrowBackIosIcon
                style={{ height: "14px", width: "14px", marginTop: "-2px" }}
              />
              Back
            </button>
          )}
        </div>
      </div>
      <div>
        {showForm && (
          <WorkUpdateForm
            selectedDate={selectedDate}
            handleClickBack={handleClickBack}
          />
        )}
      </div>
      {!showForm && lectures.length > 0 && (
        <div style={{ marginTop: "25px" }}>
          <WorkUpdateListing
            lectures={lectures}
            onEdit={handleEdit}
            onDelete={handleDelete}
            isActionRequired={true}
            isTimeStampRequired={false}
          />
        </div>
      )}
      {!showForm && lectures.length === 0 && (
        <div style={{ marginTop: "25px" }}>
          <div
            style={{
              height: "15vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            No work updates found!
          </div>
        </div>
      )}
    </LocalizationProvider>
  );
};

export default TrainerData;
