import React from "react";
import styles from "./CircularProgressBar.module.css"; // Import CSS module for styling

const CircularProgressBar = ({
  percentage,
  total,
  fillColor,
  backgroundColor,
  width,
  height,
  circleWidth,
  isTextRequired,
  isPercentageReqired,
  textFontSize,
  isTotalRequired,
  ...rest
}) => {
  const isFullFill = percentage >= total;

  const circumference = 2 * Math.PI * (Math.min(width, height) * 0.45);
  const dashOffset = isFullFill
    ? 0
    : ((total - percentage) / total) * circumference;

  return (
    <div
      className={styles["circular-progress-container"]}
      style={{ width, height, ...rest }}>
      <svg
        className={styles["circular-progress"]}
        viewBox={`0 0 ${width} ${height}`}>
        <circle
          className={styles["background-circle"]}
          cx={width / 2}
          cy={height / 2}
          r={Math.min(width, height) * 0.45}
          fill="none"
          stroke={backgroundColor}
          strokeWidth={circleWidth}
        />
        <circle
          className={styles["progress-circle"]}
          cx={width / 2}
          cy={height / 2}
          r={Math.min(width, height) * 0.45}
          fill="none"
          stroke={fillColor}
          strokeWidth={circleWidth}
          strokeDasharray={circumference}
          strokeDashoffset={isFullFill ? "0" : dashOffset}
          transform={`rotate(-90 ${width / 2} ${height / 2})`}
        />
        {isTextRequired && (
          <text
            style={{ fontSize: textFontSize }}
            className={styles["progress-text"]}
            x={width / 2}
            y={height / 2}
            textAnchor="middle"
            dominantBaseline="middle">
            {`${percentage}${isPercentageReqired ? "%" : ""}${
              isTotalRequired ? `/${total}` : ""
            }`}
            <br />
          </text>
        )}
      </svg>
    </div>
  );
};

export default CircularProgressBar;
