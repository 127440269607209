import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { DSAContext } from "../../../services/DSA/DSA.context";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import studentClasses from "../../Student/StudentDSAPractice/StudentDSAPractice.module.css";

const DSA = () => {
  const { onGetAllDSA } = useContext(DSAContext);
  const [allDSA, setAllDSA] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalDSA = useRef(0);

  useEffect(() => {
    getAllDSA();
  }, [page, rowsPerPage]);

  const getAllDSA = (query = `?page=${page + 1}&limit=${rowsPerPage}`) => {
    onGetAllDSA(
      query,
      (result) => {
        console.log(result);
        if (result && result.dsa) {
          setAllDSA(result.dsa);
          totalDSA.current = result.totalCount;
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <h2>DSA Questions - {allDSA.length}</h2>
        <Link to={"create"}>
          <Button variant="contained" startIcon={<FaPlus />}>
            Add New DSA
          </Button>
        </Link>
      </Box>

      {allDSA.length === 0 && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Quizzes Found</NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}

      {allDSA.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>No. of batches</TableCell>
                <TableCell>Difficulty level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allDSA.map((dsa, index) => {
                let { title, batches, _id, difficultyLevel } = dsa;
                const isEasyLevel = difficultyLevel === "easy";
                const isMediumLevel = difficultyLevel === "medium";
                const isHardLevel = difficultyLevel === "hard";
                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{title}</TableCell>
                    <TableCell>{batches.length}</TableCell>
                    <TableCell>
                    <div
                          className={`${studentClasses.difficultyLevel} ${
                            isEasyLevel
                              ? studentClasses.easyLevel
                              : isMediumLevel
                              ? studentClasses.mediumLevel
                              : studentClasses.hardLevel
                          }`}>
                          {difficultyLevel}
                        </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  labelRowsPerPage="DSA per Page"
                  count={totalDSA.current}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  ActionsComponent={TablePaginationActions}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DSA;
