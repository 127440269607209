import { createContext } from "react";
import useHttp from "../../hooks/useHttp";

export const NotificationsContext = createContext({
  onGetNotifications: (callback, errorCallBack, loader, notify) => null,
  onMarkNotificationAsRead: (callback, errorCallBack, loader, notify) => null,
});

export const NotificationsContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/notification";

  const onGetNotifications = async (
    query,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/getnotifications" + query,
        type: "GET",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };
  const onMarkNotificationAsRead = (
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/markasread",
        type: "POST",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  return (
    <NotificationsContext.Provider
      value={{
        onGetNotifications,
        onMarkNotificationAsRead,
      }}>
      {children}
    </NotificationsContext.Provider>
  );
};
