import { useContext, useEffect, useState, useRef } from "react";
import classes from "./StudentReports.module.css";
import { ReportsContext } from "../../../services/Reports/Reports.context";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { formatTimeWithSeconds, getSubString } from "../../../utility/helper";
import { FaRegEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import styles from "../../../styles/styles.module.css";

const StudentReports = ({ title, id, onViewAttempt, currentQuizSchema }) => {
  const { onGetReports, onGetStudentReports } = useContext(ReportsContext);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const totalReports = useRef(0);

  useEffect(() => {
    document.title = title;
    getReports();
  }, [page, rowsPerPage]);

  const getReports = (query = "?reportsBy=quiz") => {
    query += `&page=${page + 1}&limit=${rowsPerPage}`;
    if (searchKeyword.trim()) {
      query += `&searchKeyword=${searchKeyword}`;
    }
    if (id) {
      query += `&id=${id}`;
      query += `&schemaType=${currentQuizSchema}`
    }
    const apiFun = id ? onGetStudentReports : onGetReports;
    apiFun(
      query,
      (result) => {
        setLoading(false);
        setReports(result.reports);
        totalReports.current = result.totalReports;
      },
      () => {
        setLoading(false);
      },
      true,
      false,
    );
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onClickSearch = () => {
    if (page === 0) {
      getReports();
    } else {
      setPage(0);
    }
  };

  const onChangeSorting = (fieldToSort) => {
    var currentReports = reports;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["percentage"];
    if (fields.includes(fieldToSort)) {
      let sortedReports = _.orderBy(currentReports, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setReports(sortedReports);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearch();
    }
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.reportsMain} style={{ padding: "28px" }}>
      <div className={classes.headerContainer}>
        <div className={classes.headerText}>
          <h4>Quiz attempts: {totalReports.current}</h4>
        </div>
        <div className={classes.searchContainer}>
          <div className={classes["wrapper"]}>
            <div className={classes["searchBar"]}>
              <input
                id={classes["searchQueryInput"]}
                type="text"
                name="searchQueryInput"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e) => onChangeSearchKeyword(e)}
                onKeyDown={handleKeyPress}
              />
              <button
                onClick={() => onClickSearch()}
                id={classes["searchQuerySubmit"]}
                type="submit"
                name="searchQuerySubmit">
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24">
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {reports.length === 0 && !loading && (
        <NotFoundContainer>
          <div>
            <NotFoundText>
              No Reports Available!<br />
            </NotFoundText>
            <NotFoundContainerImage
              src={require("../../../assets/no_data.png")}
              alt="..."
            />
          </div>
        </NotFoundContainer>
      )}
      {reports.length > 0 && (
        <TableContainer
          className={classes.reportsTable}
          component={Paper}
          sx={{ mt: "12px" }}>
          <Table aria-label="simple table">
            <TableHead
              sx={{ fontFamily: "'Sofia Pro', sans-serif" }}
              className={styles.fontSofiaProRegular}>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>Quiz Name</TableCell>
                <TableCell>Marks </TableCell>
                <TableCell>
                  {" "}
                  <TableSortLabel
                    direction={
                      sort.type && sort.type === "desc" ? "asc" : "desc"
                    }
                    active
                    onClick={() => onChangeSorting("percentage")}>
                    Percentage
                  </TableSortLabel>
                </TableCell>
                <TableCell>Time Taken</TableCell>
                <TableCell>Result</TableCell>
                <TableCell>Report</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report, index) => {
                let {
                  marksObtained,
                  percentage,
                  timeSpentInSeconds,
                  result,
                  quiz,
                  _id,
                } = report;
                let { name, totalMarks } = quiz;
                let isPass = result === "pass";
                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: index % 2 === 0 ? "rgb(250, 250, 250)" : "inherit",
                    }}>
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{getSubString(name, 80)}</TableCell>
                    <TableCell>{`${marksObtained}/${totalMarks}`}</TableCell>
                    <TableCell>{`${percentage}%`}</TableCell>
                    <TableCell>
                      {formatTimeWithSeconds(timeSpentInSeconds)}
                    </TableCell>
                    <TableCell>
                      <img
                        className={classes.resultImg}
                        src={require(`../../../assets/${
                          isPass ? "pass.png" : "fail.png"
                        }`)}
                        alt="..."
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        if (id) {
                          onViewAttempt(report);
                        } else {
                          navigate(`view?id=${_id}`);
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                      }}>
                      <FaRegEye size={25} color="#3FC8FE" />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  labelRowsPerPage="Total Reports Per Page"
                  count={totalReports.current}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  componentsProps={{
                    select: { style: { display: "none" } }, // Hide the rows per page select dropdown
                    actions: {
                      style: { display: "flex", justifyContent: "flex-end" },
                    },
                    labelDisplayedRows: { style: { display: "none" } }, // Hide the displayed rows label
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default StudentReports;
