import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const EmailServiceContext = createContext({
  onSendEmailsToMails: (data, callback, errorCallaBack, loader, notify) => null,
});

export const EmailServiceContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();
  const baseUrl = "/email";

  const onSendEmailsToMails = async (
    data,
    callback,
    errorCallBack = () => null,
    loader,
    notify,
  ) => {
    sendRequest(
      {
        url: baseUrl + `/sendEmailsToMails`,
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };
  return (
    <EmailServiceContext.Provider
      value={{
        onSendEmailsToMails,
      }}>
      {children}
    </EmailServiceContext.Provider>
  );
};
