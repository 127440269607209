import { createContext } from "react";
import useHttp from "../../hooks/useHttp";

export const LeaderboardsContext = createContext({
  onGetYesterdaysLeaderboard: (callback, errorCallBack, loader, notify) => null,
  onGetTodaysLeaderboard: (callback, errorCallBack, loader, notify) => null,
  onGetLastweekLeaderboard: (callback, errorCallBack, loader, notify) => null,
  onGetThisWeekLeaderboardList: (callback, errorCallBack, loader, notify) => null,
});

export const LeaderboardsContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/leaderboard";

  const onGetYesterdaysLeaderboard = async (
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + `/yesterday`,
        type: "GET",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onGetTodaysLeaderboard = async (
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + `/todays`,
        type: "GET",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onGetLastweekLeaderboard = async (
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + `/lastweek`,
        type: "GET",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };
  const onGetThisWeekLeaderboardList = async (
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + `/thisweek`,
        type: "GET",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  return (
    <LeaderboardsContext.Provider
      value={{
        onGetYesterdaysLeaderboard,
        onGetTodaysLeaderboard,
        onGetLastweekLeaderboard,
        onGetThisWeekLeaderboardList
      }}>
      {children}
    </LeaderboardsContext.Provider>
  );
};
