import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const GlobalSearchContext = createContext({
  onGlobalSearch: (data, callback, errorCallaBack, loader, notify) => null,
});

export const GlobalSearchContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();
  const baseUrl = "/global-search";

  const onGlobalSearch = async (
    query,
    callback,
    errorCallBack = () => null,
    loader,
    notify,
  ) => {
    sendRequest(
      {
        url: baseUrl + query,
        type: "GET",
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };
  return (
    <GlobalSearchContext.Provider
      value={{
        onGlobalSearch,
      }}>
      {children}
    </GlobalSearchContext.Provider>
  );
};
