import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  Menu,
  MenuItem,
} from "@mui/material";
import classes from "./StudentCourseView.module.css";
import { useContext, useEffect, useState, useRef } from "react";
import {
  formatVideoDuration,
  scrollToTop,
  scrollToElement,
} from "../../../utility/helper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CourseNewContext } from "../../../services/CoursesNew/CoursesNew.context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GrResources } from "react-icons/gr";
import {
  downloadFileFromS3Url,
  generatePresignedUrl,
  onDownloadAllFilesFromS3,
} from "../../../utility/s3Helpers";
import { FaDownload } from "react-icons/fa";
import { hideLoader, showLoader } from "../../../shared/Loader/Loader";
import { showNotification } from "../../../shared/Notification/Notification";
import { useDispatch } from "react-redux";
import { VideoPlayer } from "@videojs-player/react";
import "video.js/dist/video-js.css";
import PlayIcon from "../../../assets/icons/play-button.svg";
import PlayIconActive from "../../../assets/icons/play-button-active.svg";
import CloseIcon from "@mui/icons-material/Close";
import ViewSidebarRoundedIcon from "@mui/icons-material/ViewSidebarRounded";
import Points from "../../../assets/icons/points.svg";
import { SocketContext } from "../../../services/Socket/Socket.context";
import { AuthenticationContext } from "../../../services/Authentication/Authentication.context";
import CircleTickGreen from "../../../assets/icons/circle-tick-green.svg";

const StudentCourseView = ({ title }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { onGetCourse, onUpdateVideoAsWatched } = useContext(CourseNewContext);
  const { onEmitEvent, socket, onFetchEvent } = useContext(SocketContext);
  const { onGetSelfUser } = useContext(AuthenticationContext);
  const [course, setCourse] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedResources, setSelectedResources] = useState({
    section: null,
    resources: [],
  });
  const [currSection, setCurrSection] = useState(1);
  const [currVideoNum, setCurrVideoNum] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(null);
  const [isShowContent, setIsShowContent] = useState(true);
  const contentRef = useRef(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const playerOptions = {
    controls: true,
    volume: 0.7,
    width: "750",
    height: "420",
    responsive: true,
    playbackRates: [0.5, 1.0, 1.25, 1.5, 2.0],
    enableSmoothSeeking: true,
    videoId: selectedVideo?._id,
    controlBar: {
      children: [
        "playToggle",
        "seekButtons",
        "volumePanel",
        "currentTimeDisplay",
        "progressControl",
        "durationDisplay",
        "timeDivider",
        "remainingTimeDisplay",
        "playbackRateMenuButton",
        "pictureInPictureToggle",
        "fullscreenToggle",
      ],
    },
  };

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        onGetSelfUser((result) => {}, false, false);
      };
      onFetchEvent("refreshUserData", eventHandler);
      return () => {
        socket?.off("refreshUserData", eventHandler);
      };
    }
  }, [onFetchEvent, socket]);

  useEffect(() => {
    setExpanded(`panel${currSection - 1}`);
  }, [currSection]);

  useEffect(() => {
    document.title = title;
    scrollToTop();
    let courseId = searchParams.get("id");
    if (!courseId) {
      navigate("/student/courses");
      return;
    }
    if (course?._id) {
      return;
    }
    if (courseId) {
      onGetCourse(
        courseId,
        async (result) => {
          let courseData = result.course[0];
          if (!courseData) {
            return;
          }
          const videoId = searchParams.get("v_id");
          if (videoId) {
            let videoSection;
            let videoNum;
            courseData.content.sections.forEach((section, s_i) => {
              if (videoSection && videoNum) {
                return; // Exit the loop if the video is already foun
              }
              section.videos.forEach((video, v_i) => {
                if (video._id === videoId) {
                  videoSection = s_i + 1;
                  videoNum = v_i + 1;
                  const { path, duration, _id } = video;
                  setSelectedVideo({ path, duration, _id });
                  setTimeout(() => {
                    scrollToElement(_id);
                  }, 1000);
                  return false;
                }
              });
            });

            setCurrSection(videoSection);
            setCurrVideoNum(videoNum);
            setExpanded(`panel${videoSection - 1}`);
          } else {
            const { path, duration, _id } =
              courseData.content.sections[currSection - 1].videos[
                currVideoNum - 1
              ];
            setSearchParams({ id: courseId, v_id: _id });
            setSelectedVideo({ path, duration, _id });
          }
          document.title = `${courseData.title}`;
          let banner = await generatePresignedUrl(courseData.banner);
          setBannerUrl(banner);
          setCourse(courseData);
        },
        () => {
          navigate("/student/courses");
        },
        true,
        false,
      );
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth < 950 &&
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        isShowContent
      ) {
        setIsShowContent(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      scrollToTop();
      handlePlayVideo();
    }
  }, [selectedVideo]);

  const handlePlayVideo = async () => {
    let { path, duration } = selectedVideo;
    let previewUrl = await generatePresignedUrl(path, duration);
    setVideoUrl(previewUrl);
  };

  const onClickResourcesButton = (section, resources, event) => {
    setSelectedResources({
      section: section,
      resources: resources,
    });
    setAnchorEl(event.currentTarget);
  };

  const onDownloadResource = async (resource) => {
    showLoader(dispatch);
    try {
      let { title, path } = resource;
      const fileExtension = path.split("/").pop().split(".").pop();
      const fileName = `${title}.${fileExtension}`;
      hideLoader(dispatch);
      await downloadFileFromS3Url(path, fileName);
    } catch (e) {
      console.log(e);
      hideLoader(dispatch);
      showNotification({
        message: e.toString(),
        status: "error",
      });
    }
  };

  const onDownloadAllResources = async () => {
    showLoader(dispatch);
    try {
      let resources = selectedResources.resources.map((r) => {
        let { title, path } = r;
        const fileExtension = path.split("/").pop().split(".").pop();
        const fileName = `${title}.${fileExtension}`;
        return {
          path: path,
          fileName: fileName,
        };
      });
      hideLoader(dispatch);
      await onDownloadAllFilesFromS3(
        resources,
        `${selectedResources.section?.title}.zip`,
      );
    } catch (e) {
      console.log(e);
      hideLoader(dispatch);
      showNotification({
        message: e.toString(),
        status: "error",
      });
    }
  };

  const onClickPrev = () => {
    if (currVideoNum === 1) {
      setCurrVideoNum(course.content.sections[currSection - 2].videos.length);
      setCurrSection((prev) => prev - 1);
      const { path, duration, _id } =
        course.content.sections[currSection - 2].videos.at(-1);
      setSelectedVideo({ path, duration, _id });
      setSearchParams({ id: searchParams.get("id"), v_id: _id });
    } else {
      const { path, duration, _id } =
        course.content.sections[currSection - 1].videos[currVideoNum - 2];
      setSelectedVideo({ path, duration, _id });
      setSearchParams({ id: searchParams.get("id"), v_id: _id });
      setCurrVideoNum((prev) => prev - 1);
    }
  };

  const onClickNext = () => {
    if (
      currVideoNum === course.content.sections[currSection - 1].videos.length
    ) {
      setCurrSection((prevSec) => prevSec + 1);
      setCurrVideoNum(1);
      const { path, duration, _id } =
        course.content.sections[currSection].videos[0];
      setSelectedVideo({ path, duration, _id });
      setSearchParams({ id: searchParams.get("id"), v_id: _id });
    } else {
      setCurrVideoNum((prev) => prev + 1);
      const { path, duration, _id } =
        course.content.sections[currSection - 1].videos[currVideoNum];
      setSelectedVideo({ path, duration, _id });
      setSearchParams({ id: searchParams.get("id"), v_id: _id });
    }
  };

  const handleExpandContent = () => {
    setIsShowContent(true);
  };

  const handleCloseContent = () => {
    setIsShowContent(false);
  };

  const updateStudentWatch = () => {
    let query = "?id=" + selectedVideo._id;
    onUpdateVideoAsWatched(
      query,
      (result) => {
        if (result.video) {
          onEmitEvent("refreshUserData");
        }
      },
      (e) => {
        console.log(e);
      },
      false,
      false,
    );
  };

  const onVideoTimeUpdate = (player) => {
    const totalDuration = player.target.player.cache_?.duration;
    const remainingTime =
      totalDuration - player.target.player.cache_?.currentTime;
    if (remainingTime <= 40 && remainingTime > 39) {
      updateStudentWatch();
    }
  };

  const onVideoSeeked = (player) => {
    const totalDuration = player.target.player.cache_?.duration;
    const remainingTime =
      totalDuration - player.target.player.cache_?.currentTime;
    if (remainingTime <= 40) {
      updateStudentWatch();
    }
  };

  const changeVideo = ({ path, _id, duration }) => {
    setSelectedVideo({ path, duration, _id });
    setSearchParams({ id: searchParams.get("id"), v_id: _id });
  };

  return (
    <>
      {course && (
        <div
          className={`${classes.gridContainer} ${
            isShowContent && window.innerWidth > 950 ? classes.main : ""
          }`}>
          <Box
            ref={contentRef}
            className={`${classes.videoSections} ${
              !isShowContent ? classes.hide : ""
            }`}>
            <div style={{ background: "#182642", padding: "22px 16px" }}>
              <div className={classes.toggleContainer}>
                <div className={classes.contentText}>CONTENTS</div>
                <div
                  onClick={handleCloseContent}
                  className={classes.toggleIcon}>
                  <CloseIcon />
                </div>
              </div>
            </div>
            {course.content.sections.map((s, i) => {
              let { title, resources, videos } = s;
              let totalVideosDuration = 0;
              videos.forEach((v) => (totalVideosDuration += v.duration));
              let sectionDuration = formatVideoDuration(totalVideosDuration);
              const isPanelExpanded = expanded === `panel${i}`;
              return (
                <Accordion
                  key={i}
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`)}
                  sx={{
                    background: "rgb(27, 43, 80)",
                    mt: "0 !important",
                    mb: "0 !important",
                  }}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          color: "#E8F9FD",
                        }}
                      />
                    }
                    aria-controls={`section_${i}`}
                    id={`section_${i}`}
                    style={{
                      color: "#E8F9FD",
                      background: isPanelExpanded ? "#4069ba" : "#182642",
                      fontWeight: isPanelExpanded ? "600" : "500",
                      "& > div:firstChild": {
                        margin: "13px 0px",
                      },
                      padding: "0 30px 0 20px",
                    }}>
                    <Box>
                      <div className={classes.sectionTitle}>
                        Section {i + 1}: {title}
                      </div>
                      <p className={classes.sectionDuration}>
                        {videos.length} videos | {sectionDuration}
                      </p>
                    </Box>
                  </AccordionSummary>

                  {resources && resources.length > 0 && (
                    <>
                      <div className={classes.resourcesButton}>
                        <Button
                          variant="outlined"
                          size="small"
                          endIcon={<GrResources />}
                          id={`menu_button`}
                          aria-controls={open ? `menu_resource` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          sx={{
                            color: "rgb(128, 154, 202)",
                            borderColor: "rgb(128, 154, 202)",
                          }}
                          onClick={(e) =>
                            onClickResourcesButton(s, resources, e)
                          }>
                          Resources
                        </Button>
                      </div>
                    </>
                  )}

                  {videos.map((v, vi) => {
                    let { title, path, duration, _id, watchedByCurrentUser } =
                      v;
                    let active = selectedVideo?.path === path;
                    return (
                      <div
                        id={_id}
                        onClick={() => {
                          // setSelectedVideo({ path, duration, _id });
                          changeVideo({ path, _id, duration });
                          if (i + 1 !== currSection) {
                            setCurrSection(i + 1);
                          }
                          setCurrVideoNum(vi + 1);
                        }}
                        key={vi}
                        className={`${classes.videoContainer} ${
                          active ? classes.active : ""
                        } ${videos[vi + 1] ? classes.videoAfter : ""} ${
                          videos[vi - 1] ? classes.videoBefore : ""
                        }
                        `}>
                        {watchedByCurrentUser ? (
                          <span className={classes.greenTickContainer}>
                            <img
                              src={CircleTickGreen}
                              alt="greent tick"
                              width={12}
                              height={12}
                            />
                          </span>
                        ) : (
                          <div className={classes.emptyCircle}></div>
                        )}
                        <div className={classes.videoTitleContainer} >
                          <p
                            className={`${classes.videoTitle} ${
                              active ? classes.active : ""
                            }`}>
                            {vi + 1}. {title}
                          </p>
                          <div
                            className={`${classes.durationContainer} ${
                              active ? classes.active : ""
                            }`}>
                            <img
                              width="16px"
                              height="16px"
                              src={active ? PlayIconActive : PlayIcon}
                              alt="svg"
                            />
                            <div
                              className={`${classes.duration} ${
                                active ? classes.active : ""
                              }`}>
                              {formatVideoDuration(duration)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Accordion>
              );
            })}
          </Box>

          <div className={classes.rightPanel}>
            <div className={classes.videoPlayerContainer}>
              {videoUrl ? (
                <div style={{ background: "#f1f5f9"}}>
                  <div className={classes.videoTitleWrapper}>
                    <div className={classes.titleContainer}>
                      {!isShowContent && (
                        <div
                          className={classes.forwardArrow}
                          onClick={handleExpandContent}>
                          <ViewSidebarRoundedIcon
                            style={{ height: "22px", width: "22px" }}
                          />
                        </div>
                      )}
                      <div
                        className={`${classes.sectionPath} ${
                          isShowContent ? classes["ml-8"] : ""
                        }`}>
                        {course.content.sections[currSection - 1].title}
                      </div>
                      <div> {">"}</div>
                      <div className={classes.videoPath}>
                        {" "}
                        {
                          course.content.sections[currSection - 1].videos[
                            currVideoNum - 1
                          ].title
                        }
                      </div>
                    </div>
                    <div>
                      <div className={classes.pointsContainer}>
                        <span>Win</span>
                        <span style={{ marginTop: "-2px" }}>
                          <img
                            width={20}
                            height={20}
                            src={Points}
                            alt="points"
                          />
                        </span>
                        <span>
                          {selectedVideo.duration <= 1800
                            ? 50
                            : selectedVideo.duration <= 3600
                            ? 80
                            : 100}
                        </span>
                        <span> Points</span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ margin: "0 auto" }}
                    className={`${classes.videoPlayer} ${
                      isShowContent ? classes.contentActive : ""
                    }`}>
                    <VideoPlayer
                      className={`${classes.videoPlayer} ${
                        isShowContent ? classes.contentActive : ""
                      }`}
                      src={videoUrl}
                      poster={bannerUrl}
                      options={playerOptions}
                      onTimeUpdate={onVideoTimeUpdate}
                      onSeeked={onVideoSeeked}
                    />
                    <div className={classes.videoActionContainer}>
                      <div>
                        <button
                          disabled={currSection === 1 && currVideoNum === 1}
                          className={
                            currSection === 1 && currVideoNum === 1
                              ? classes.btnDisabled
                              : ""
                          }
                          onClick={onClickPrev}>
                          « Previous
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={
                            currSection === course.content.sections?.length &&
                            currVideoNum ===
                              course.content.sections.at(-1)?.videos?.length
                          }
                          className={
                            currSection === course.content.sections?.length &&
                            currVideoNum ===
                              course.content.sections.at(-1)?.videos?.length
                              ? classes.btnDisabled
                              : ""
                          }
                          onClick={onClickNext}>
                          Next »
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <img src={bannerUrl} alt="..." className={classes.banner} />
              )}
            </div>

            <div className={classes.about}>
              <p className={classes.title}>
                {course.title} - <span>About this course</span>{" "}
              </p>
              <p className={classes.headline}>- {course.headline}</p>
              <p className="mt-1">
                Total Duration - {formatVideoDuration(course.totalDuration)}{" "}
              </p>
              <p className="mt-1">Total Lectures - {course.totalLectures} </p>
              <div className={classes.description}>
                <p>
                  <b>Description</b> :{" "}
                </p>
                {course.description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: course.description,
                    }}></div>
                )}
              </div>
            </div>
          </div>

          <Menu
            id={`menu_resource`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": `menu_button`,
            }}>
            <MenuItem onClick={() => onDownloadAllResources()}>
              Download All{" "}
              <span className="ml-1">
                <FaDownload color="var(--primary)" />
              </span>
            </MenuItem>
            {selectedResources.resources.map((r, ri) => {
              let { title } = r;
              return (
                <MenuItem key={ri} onClick={() => onDownloadResource(r)}>
                  {title}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )}
    </>
  );
};

export default StudentCourseView;
