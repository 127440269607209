import React, { useContext, useState, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { AuthenticationContext } from "../../services/Authentication/Authentication.context";
import classes from "./Sidebar.module.css";
import dashboard from "../../assets/icons/dashboard.svg";
import dashboardStyled from "../../assets/icons/dashboard-styled.svg";
import courses from "../../assets/icons/courses.svg";
import coursesStyled from "../../assets/icons/courses-styled.svg";
import logout from "../../assets/icons/logout.svg";
import logoutStyled from "../../assets/icons/logout-styled.svg";
import profile from "../../assets/icons/profile.svg";
import profileStyled from "../../assets/icons/profile-styled.svg";
import quiz from "../../assets/icons/quiz.svg";
import quizStyled from "../../assets/icons/quiz-styled.svg";
import reports from "../../assets/icons/reports.svg";
import reportsStyled from "../../assets/icons/reports-styled.svg";
import resources from "../../assets/icons/resources.svg";
import resourcesStyled from "../../assets/icons/resources-styled.svg";
import coding from "../../assets/icons/coding.svg";
import codingStyled from "../../assets/icons/coding-styled.svg";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import StudentSearchModal from "../../components/Student/StudentSearchModal/StudentSearchModal";
import PracticeIcon from "../../assets/icons/practice.svg";
import PracticeIconStyled from "../../assets/icons/practice-styled.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { onLogout } = useContext(AuthenticationContext);
  const [isLogoutHovered, setIsLogoutHovered] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showSearchModel, setShowSearchModal] = useState(false);
  const searchInputRef = useRef(null);

  const onClickLogout = () => {
    onLogout(() => {
      navigate("/auth/signin");
    });
  };
  const navigateToPath = (path) => {
    navigate(path);
  };

  const handleLogoutHover = () => {
    setIsLogoutHovered(true);
  };

  const handleLogoutLeave = () => {
    setIsLogoutHovered(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const sideItems = [
    {
      name: "Dashboard",
      path: "/student/dashboard",
      icon: dashboard,
      activeIcon: dashboardStyled,
      active: ["/student/dashboard"],
    },
    {
      name: "Quiz",
      path: "/student/quiz-new",
      icon: quiz,
      activeIcon: quizStyled,
      active: ["/student/quiz-new", "/student/quiz-new/start", "/student/quiz/start"],
    },

    {
      name: "Reports",
      path: "/student/reports",
      icon: reports,
      activeIcon: reportsStyled,
      active: ["/student/reports", "/student/reports/view"],
    },
    {
      name: "My Courses",
      path: "/student/courses",
      icon: courses,
      activeIcon: coursesStyled,
      active: ["/student/courses", "/student/courses/view"],
    },
    {
      name: "Resources",
      path: "/student/resources",
      icon: resources,
      activeIcon: resourcesStyled,
      active: ["/student/resources"],
    },
    {
      name: "Playground",
      path: "/student/playground",
      icon: coding,
      activeIcon: codingStyled,
      active: ["/student/playground"],
    },
    {
      name: "Practice",
      path: "/student/practice",
      icon: PracticeIcon,
      activeIcon: PracticeIconStyled,
      active: ["/student/practice", "/student/practice/view"],
    },
    {
      name: "Profile",
      path: "/student/profile",
      icon: profile,
      activeIcon: profileStyled,
      active: ["/student/profile"],
    },
  ];

  const handleSearchInputFocus = () => {
    setShowSearchModal(true);
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const handleCloseSearchModel = () => {
    setShowSearchModal(false);
  }

  const container =
    window !== undefined ? () => window.document.body : undefined;
  let sideItemElement = (
    <div className={classes.container}>
      <div className={classes["logo-container"]} style={{ height: "61px", paddingTop: "8px" }}>
        <img src={require("../../assets/logo_white.png")} alt="..." />
      </div>
      {window.innerWidth > 950 && (
        <div className={classes.globalSearchContainer}>
          <input
            onClick={handleSearchInputFocus}
            ref={searchInputRef}
            className={classes.globalSearchInput}
            type="text"
            placeholder="Search for quiz, resource, etc."
          />
        </div>
      )}
      <div className={classes["item-container"]}>
        {sideItems.map(
          ({ name, path, active, onClick, icon, activeIcon }, i) => {
            const isActive = active?.some((path) => path.includes(pathname))
              ? true
              : false;
            // because of slightly bigger profile icon
            const isProfile = name === "Profile";
            return (
              <div
                className={`${classes.item} ${isActive && classes.active}`}
                key={i}
                onClick={onClick ? onClick : () => navigateToPath(path)}
                style={{ display: "flex" }}>
                <div className={`${classes.icon}`}>
                  <img
                    width={isProfile ? "22px" : "24px"}
                    height={isProfile ? "22px" : "24px"}
                    src={isActive ? activeIcon : icon}
                    alt="svg"
                  />
                </div>
                <div className={`${isActive && classes.itemText}`}>{name}</div>
              </div>
            );
          },
        )}
        <div
          className={`${classes.item} ${isLogoutHovered && classes.active}`}
          onClick={onClickLogout}
          onMouseEnter={handleLogoutHover}
          onMouseLeave={handleLogoutLeave}
          style={{ display: "flex" }}>
          <div className={`${classes.icon}`}>
            <img
              width="24px"
              height="24px"
              src={isLogoutHovered ? logoutStyled : logout}
              alt="svg"
            />
          </div>
          <div>Logout</div>
        </div>
      </div>
    </div>
  );
  const isCourseView = pathname === "/student/courses/view";
  const isPracticeView = pathname === "/student/practice/view";
  const quizStart = pathname === "/student/quiz/start";
  const quizNewStart = pathname === "/student/quiz-new/start"

  const specialCase = () => {
    return isCourseView || quizStart || quizNewStart || isPracticeView;
  };

  const notASpecialCase = () => {
    return !isCourseView && !quizStart && !quizNewStart && !isPracticeView;
  };

  const drawerWidth = specialCase() && window.innerWidth > 950 ? 320 : 240;

  return (
    <div>
      <div
        className={`${classes.sectionContainer} ${
          specialCase() ? classes.courseActive : ""
        }`}>
        <div style={{ display: "flex" }}>
          <div className={classes.menuIcon}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={`${
                specialCase() ? classes.courseActive : classes.drawIcon
              }`}
              sx={{ mr: 2 }}>
              <MenuIcon style={{ width: "30px", height: "30px" }} />
            </IconButton>
          </div>
          <div
            className={`${classes.sectionText} ${
              specialCase() ? classes.courseActive : ""
            }`}>
            {
              sideItems.find((item) => {
                return item.active.find((pathText) =>
                  pathText.includes(pathname),
                );
              })?.name
            }
          </div>
        </div>
      </div>

      {notASpecialCase() && (
        <div className={classes.main}>
          {/* <div className={classes["top-curve"]}>
            <div></div>
          </div> */}
          {/* <div className={classes["bottom-curve"]}>
            <div></div>
          </div> */}
          {sideItemElement}
        </div>
      )}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "rgb(12, 15, 42)"
            },
          }}>
          {sideItemElement}
        </Drawer>
      </nav>
      {showSearchModel && (
        <StudentSearchModal
          showSearchModel={showSearchModel}
          handleCloseSearchModel={handleCloseSearchModel}
        />
      )}
    </div>
  );
};

export default Sidebar;
