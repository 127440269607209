import React, { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../services/Authentication/Authentication.context";
import {
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import classes from "./StudentProfile.module.css";
import { UsersContext } from "../../../services/users/users.context";
import { SocketContext } from "../../../services/Socket/Socket.context";

const StudentProfile = ({ title }) => {
  const { onUpdateUserData, onUpdatePassword } = useContext(UsersContext);
  const { onEmitEvent, socket, onFetchEvent } = useContext(SocketContext);
  const { userData, onGetSelfUser } = useContext(AuthenticationContext);
  const [editNameMode, setEditNameMode] = useState(false);
  const [editPasswordMode, setEditPasswordMode] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    oldPasswordErr: "",
    newPasswordErr: "",
    confirmPasswordErr: "",
  });
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        onGetSelfUser(()=>{}, false, false);
      };
      onFetchEvent("refreshUserData", eventHandler);
      return () => {
        socket?.off("refreshUserData", eventHandler);
      };
    }
  }, [onFetchEvent, socket]);

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName || "");
      setLastName(userData.lastName || "");
    }
  }, [userData]);

  useEffect(() => {
    document.title = title;
  }, []);

  const handleEditNameClick = () => {
    setEditNameMode(true);
  };

  const handleCancelNameClick = () => {
    setEditNameMode(false);
    setFirstName(userData?.firstName || "");
    setLastName(userData?.lastName || "");
    setNameError("");
  };

  const handleConfirmNameClick = () => {
    if (firstName.trim() === "" || lastName.trim() === "") {
      setNameError("First name and last name cannot be empty");
    } else {
      const data = {
        firstName,
        lastName,
        userId: userData._id,
        role: userData.role,
      };
      onUpdateUserData(
        data,
        (result) => {
          if (result.status === "success") {
            setNameError("");
            setEditNameMode(false);
            onEmitEvent("refreshUserData");
          }
        },
        (e) => console.log(e),
      );
    }
  };

  const handleChangePassClick = () => {
    setEditPasswordMode(true);
  };

  const handleCancelPassClick = () => {
    setEditPasswordMode(false);
    setOldPassword("");
    setNewPassword({ password: "", confirmPassword: "" });
    setEmptyErrors();
  };

  const setEmptyErrors = () => {
    setPasswordErrors({
      newPasswordErr: "",
      oldPasswordErr: "",
      confirmPasswordErr: "",
    });
  };

  const handleConfirmPassClick = () => {
    if (newPassword.password.trim() !== newPassword.confirmPassword.trim()) {
      setPasswordErrors((prevErr) => ({
        ...prevErr,
        confirmPasswordErr: "Passwords do not match",
        newPasswordErr: "Passwords do not match",
      }));
      return;
    } else {
      setEmptyErrors();
    }
    if (
      passwordErrors.oldPasswordErr.trim() ||
      passwordErrors.newPasswordErr.trim() ||
      passwordErrors.confirmPasswordErr.trim()
    ) {
      return;
    }
    const data = {
      oldPassword,
      newPassword: newPassword.password,
      userId: userData._id,
    };
    onUpdatePassword(
      data,
      (result) => {
        if (result.status === "success") {
          setEditPasswordMode(false);
        }
      },
      (e) => console.log(e),
    );
  };

  return (
    <div className={classes.profileMain} style={{padding: "28px"}}>
      <Box className={classes.title}>
        <h3>Your Profile!</h3>
      </Box>
      <Box
        mt={2}
        border={1}
        borderRadius={"6px"}
        position="relative"
        className={`${editNameMode && classes.fieldPad} ${classes.boxStyle}`}>
        {editNameMode ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  error={nameError !== ""}
                  helperText={nameError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  error={nameError !== ""}
                  helperText={nameError}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <button
                className={classes.actionBtn}
                onClick={handleCancelNameClick}
                style={{ marginRight: "10px" }}>
                Cancel
              </button>
              <button
                className={classes.actionBtn}
                color="primary"
                onClick={handleConfirmNameClick}>
                Confirm
              </button>
            </Box>
          </Box>
        ) : (
          <>
            <IconButton
              aria-label="edit"
              onClick={handleEditNameClick}
              style={{
                position: "absolute",
                top: "12px",
                right: "15px",
                color: "rgb(49, 59, 71)",
              }}>
              <EditIcon />
            </IconButton>
            <Typography variant="h6" component="h2" gutterBottom>
              <Typography
                className={classes.title}
                variant="body1"
                component="span">
                Name:
              </Typography>{" "}
              <Typography variant="body1" component="span" fontWeight="bold">
                {firstName} {lastName}
              </Typography>
            </Typography>
          </>
        )}
      </Box>

      <Box className={classes.boxStyle} mt={2} border={1} borderRadius={"6px"}>
        <Typography variant="h6" component="h2" gutterBottom>
          <Typography
            className={classes.title}
            variant="body1"
            component="span">
            Email:
          </Typography>{" "}
          <Typography variant="body1" component="span" fontWeight="bold">
            {userData?.email}
          </Typography>
        </Typography>
      </Box>

      <Box
        mt={2}
        border={1}
        borderRadius={"6px"}
        position="relative"
        className={`${editPasswordMode && classes.fieldPad} ${
          classes.boxStyle
        }`}>
        {editPasswordMode ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Old Password"
                  value={oldPassword}
                  type="password"
                  required
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setPasswordErrors((prevErr) => ({
                      ...prevErr,
                      oldPasswordErr: e.target.value.trim()
                        ? ""
                        : "Old Password cannot be empty",
                    }));
                  }}
                  error={passwordErrors.oldPasswordErr !== ""}
                  helperText={passwordErrors.oldPasswordErr}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="New Password"
                  name="password"
                  type="password"
                  required
                  value={newPassword.password}
                  onChange={(e) => {
                    setNewPassword((p) => ({
                      ...p,
                      [e.target.name]: e.target.value,
                    }));
                    setPasswordErrors((prevErr) => ({
                      ...prevErr,
                      newPasswordErr: e.target.value
                        ? ""
                        : "Password cannot be empty",
                    }));
                  }}
                  error={passwordErrors.newPasswordErr !== ""}
                  helperText={passwordErrors.newPasswordErr}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  required
                  value={newPassword.confirmPassword}
                  onChange={(e) => {
                    setNewPassword((p) => ({
                      ...p,
                      [e.target.name]: e.target.value,
                    }));
                    setPasswordErrors((prevErr) => ({
                      ...prevErr,
                      confirmPasswordErr: e.target.value
                        ? ""
                        : "Password cannot be empty",
                    }));
                  }}
                  error={passwordErrors.confirmPasswordErr !== ""}
                  helperText={passwordErrors.confirmPasswordErr}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <button
                className={classes.actionBtn}
                onClick={handleCancelPassClick}
                style={{ marginRight: "10px" }}>
                Cancel
              </button>
              <button
                className={classes.actionBtn}
                onClick={handleConfirmPassClick}>
                Confirm
              </button>
            </Box>
          </Box>
        ) : (
          <>
            <IconButton
              aria-label="edit"
              onClick={handleChangePassClick}
              style={{
                position: "absolute",
                top: "12px",
                right: "15px",
              }}>
              <Typography className={classes.changeText} variant="body1">
                Change
              </Typography>
            </IconButton>
            <Typography variant="h6" component="h2" gutterBottom>
              <Typography
                className={classes.title}
                variant="body1"
                component="span">
                Password:
              </Typography>{" "}
              <Typography
                style={{ fontSize: "22px" }}
                variant="body1"
                component="span"
                fontWeight="bold">
                **********
              </Typography>
            </Typography>
          </>
        )}
      </Box>

      <Box
        mt={2}
        border={1}
        borderRadius={"6px"}
        className={`${classes.batchesContainer} ${classes.boxStyle}`}>
        <Typography
          className={classes.title}
          variant="body1"
          component="span"
          gutterBottom>
          Batches:
        </Typography>
        <Box style={{ display: "flex", flexWrap: "wrap" }}>
          {userData?.batchNames?.map((batchName, i) => (
            <Box
              key={i}
              p={1}
              m={1}
              border={1}
              borderRadius={"8px"}
              style={{ minWidth: 90 }}
              className={`${classes.batchTitle}`}>
              <Typography
                sx={{ fontWeight: "600" }}
                variant="body2"
                component="span">
                {batchName}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default StudentProfile;
