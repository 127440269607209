import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { TrainerStatusContext } from "../../../services/TrainerStatus/TrainerStatus.context";
import classes from "./TrainerStatus.module.css";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import HorizontalCalender from "../TrainerData/HorizontalCalender";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import WorkUpdateListing from "../TrainerData/WorkUpdateListing";
import LinkIcon from "@mui/icons-material/Link";
import { Button, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
dayjs.extend(weekday);

const TrainerStatus = () => {
  const {
    onGetTrainers,
    onFetchWorkUpdates,
    onUpdateRunbookUrl,
    onGetTrainerData,
  } = useContext(TrainerStatusContext);
  const [trainers, setTrainers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [daysToShow, setDaysToShow] = useState(15);
  const scrollMenuRef = useRef(null);
  const [currentTrainerId, setCurrentTrainerId] = useState(null);
  const [lectures, setLectures] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [currentTrainer, setCurrentTrainer] = useState({});
  const [newUrl, setNewUrl] = useState("");

  useEffect(() => {
    getTrainers();
  }, []);

  const calculateDaysToShow = useCallback(() => {
    const width = window.innerWidth;
    const daysCount = Math.floor(width / 90) / 2;
    setDaysToShow(Math.max(2, daysCount));
  }, []);

  useEffect(() => {
    calculateDaysToShow();
    window.addEventListener("resize", calculateDaysToShow);
    return () => window.removeEventListener("resize", calculateDaysToShow);
  }, [calculateDaysToShow]);

  useEffect(() => {
    scrollToSelectedDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    onFetchWorkUpdates(
      { date: selectedDate, userId: currentTrainerId },
      (result) => {
        if (result && result.workUpdates) {
          setLectures(result.workUpdates);
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  }, [selectedDate, currentTrainerId]);

  useEffect(() => {
    onGetTrainerData(
      { userId: currentTrainerId },
      (result) => {
        if (result && result.user) {
          setCurrentTrainer(result.user);
          setNewUrl(result.user?.runbookUrl || "");
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  }, [currentTrainerId]);

  const daysArray = useMemo(() => {
    const startDay = selectedDate;
    let days = [];
    for (let i = -daysToShow; i <= daysToShow; i++) {
      days.push(startDay.add(i, "day"));
    }
    return days;
  }, [selectedDate, daysToShow]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    scrollToSelectedDate(date);
  };

  const scrollToSelectedDate = (date) => {
    const index = daysArray.findIndex((day) => day.isSame(date, "day"));
    if (scrollMenuRef.current) {
      scrollMenuRef.current.scrollToItem(index, "smooth", "center");
    }
  };

  const handleChangeSelectedDate = (newValue) => {
    setSelectedDate(newValue);
  };

  const getTrainers = (query = "") => {
    onGetTrainers(
      query,
      (result) => {
        if (result && result.trainers) {
          setTrainers(result.trainers);
          setCurrentTrainerId(result.trainers[0]?._id);
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  };

  const changeHandler = (e) => {
    setSearchText(e.target.value);
  };

  const handleClickSearch = () => {
    let query = "";
    if (searchText) {
      query = `?searchText=${searchText}`;
    }
    getTrainers(query);
  };

  const handleChangeTrainer = (newId) => {
    if (newId !== currentTrainerId) {
      setCurrentTrainerId(newId);
    }
  };

  const handleEdit = (id) => {
    console.log("Edit clicked for ID:", id);
  };

  const handleDelete = (id) => {
    console.log("Delete clicked for ID:", id);
  };

  const formateUrl = (url) => {
    return url.startsWith("http") ? url : `https://${url}`;
  };

  const handleEditClick = () => {
    setShowForm(true);
  };

  const handleInputChange = (e) => {
    setNewUrl(e.target.value);
  };

  const handleUpdateClick = () => {
    setShowForm(false);
    onUpdateRunbookUrl(
      { runbookUrl: newUrl, userId: currentTrainerId },
      (result) => {
        if (result && result.user) {
          setCurrentTrainer(result.user);
        }
      },
      (error) => {
        console.log(error);
      },
      true,
      false,
    );
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <div>
          <div className={classes.headerText}>
            <h3>Trainers Status</h3>
          </div>
          <div>
            <div className={classes.container}>
              <div className={classes.left}>
                {trainers.length > 0 && (
                  <>
                    <div
                      style={{ background: "#fff", border: "initial" }}
                      className={classes["search-container"]}>
                      <input
                        value={searchText}
                        onChange={changeHandler}
                        type="text"
                        className={classes["search-input"]}
                        placeholder="Search..."
                      />
                      <button
                        className={classes["search-button"]}
                        onClick={handleClickSearch}>
                        Search
                      </button>
                    </div>
                  </>
                )}
                {trainers.length > 0 &&
                  trainers.map((trainer, index) => {
                    const { firstName, lastName, _id } = trainer;
                    return (
                      <div
                        key={_id}
                        onClick={() => handleChangeTrainer(_id)}
                        style={{
                          marginTop: index === 0 ? "0px" : "5px",
                          cursor: "pointer",
                          textAlign: "center",
                          boarderRadius: "5px",
                          backgroundColor:
                            _id === currentTrainerId ? "#5A639C" : "#f5f5f5",
                          color:
                            _id === currentTrainerId ? "#FFFBE6" : "#758694",
                        }}>
                        {firstName} {lastName}
                      </div>
                    );
                  })}

                {trainers.length === 0 && <div>No trainers found.</div>}
              </div>

              <div className={classes.right}>
                <div>
                  <div>
                    <div
                      style={{
                        color: "#758694",
                      }}>
                      Runbook url
                      {!showForm && (
                        <>
                          <a
                            style={{ marginLeft: "10px" }}
                            rel="noreferrer noopener"
                            target="_blank"
                            href={formateUrl(newUrl)}>
                            <LinkIcon height={15} width={15} />
                          </a>
                          <IconButton
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "rgb(59, 113, 202)",
                              marginLeft: "5px",
                            }}
                            onClick={handleEditClick}>
                            <EditIcon
                              style={{ height: "20px", width: "20px" }}
                            />
                          </IconButton>
                        </>
                      )}
                      <div>
                        {showForm && (
                          <div style={{ margin: "15px 0" }}>
                            <TextField
                              label="Edit URL"
                              value={newUrl}
                              onChange={handleInputChange}
                              fullWidth
                              size="small"
                              variant="outlined"
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleUpdateClick}
                              style={{ marginTop: "10px" }}>
                              Update
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleCancelClick}
                              sx={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                backgroundColor: "#f44336",
                                "&:hover": {
                                  backgroundColor: "#d32f2f",
                                },
                              }}>
                              Cancel
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <HorizontalCalender
                    daysArray={daysArray}
                    scrollMenuRef={scrollMenuRef}
                    handleDateClick={handleDateClick}
                    selectedDate={selectedDate}
                    scrollToSelectedDate={scrollToSelectedDate}
                    handleChangeSelectedDate={handleChangeSelectedDate}
                  />
                </div>
                <div>
                  {lectures.length > 0 && (
                    <div style={{ marginTop: "25px" }}>
                      <WorkUpdateListing
                        lectures={lectures}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        isActionRequired={false}
                        isTimeStampRequired={true}
                      />
                    </div>
                  )}
                  {lectures.length === 0 && (
                    <div style={{ marginTop: "25px" }}>
                      <div
                        style={{
                          height: "15vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        No work updates found!
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default TrainerStatus;
