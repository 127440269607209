import { createContext } from "react";
import useHttp from "../../hooks/useHttp";

export const TrainerStatusContext = createContext({
  onGetTrainers: (query, callback, errorCallBack, loader, notify) => null,
  onAddWorkUpdate: (data, callback, errorCallBack, loading, notify) => null,
  onFetchWorkUpdates: (data, callback, errorCallBack, loading, notify) => null,
  onUpdateRunbookUrl: (data, callback, errorCallBack, loading, notify) => null,
  onGetTrainerData: (data, callback, errorCallBack, loading, notify) => null,
});

export const TrainerStatusContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();
  const baseUrl = "/trainer";
  const onGetTrainers = async (
    query,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = false,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/get-trainers" + query,
        type: "GET",
      },
      {
        successCallback: callback,
        errorCallBback: errorCallBack,
      },
      loader,
      notify,
    );
  };
  const onAddWorkUpdate = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = false,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/add-work-update",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onFetchWorkUpdates = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = false,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/fetch-work-update",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onUpdateRunbookUrl = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = false,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/update-runbook-url",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onGetTrainerData = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = false,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/get-trainer-data",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };
  return (
    <TrainerStatusContext.Provider
      value={{
        onGetTrainers,
        onAddWorkUpdate,
        onFetchWorkUpdates,
        onUpdateRunbookUrl,
        onGetTrainerData,
      }}>
      {children}
    </TrainerStatusContext.Provider>
  );
};
