import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const DSAContext = createContext({
  onGetAllDSA: (query, callback, errorCallBack, loader, notify) => null,
  onCreateDSA: (data, callback, errorCallBack, loader, notify) => null,
  onGetSingleDSA: (dsaId, callback, errorCallBack, loader, notify) => null,
});

export const DSAContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();
  const baseUrl = "/dsa";
  const onGetAllDSA = async (
    query,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/fetch-all-dsa" + query,
        type: "GET",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onCreateDSA = async (
    data,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/create-dsa",
        type: "POST",
        data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  const onGetSingleDSA = async (
    dsaId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true,
  ) => {
    sendRequest(
      {
        url: baseUrl + "/fetch-dsa" + "/" + dsaId,
        type: "GET",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify,
    );
  };

  return (
    <DSAContext.Provider value={{ onGetAllDSA, onCreateDSA, onGetSingleDSA }}>
      {children}
    </DSAContext.Provider>
  );
};
