import React from "react";
import classes from "./StudentNotifications.module.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useNavigate } from "react-router-dom";
import QuizIcon from "@mui/icons-material/Quiz";
import Points from "../../../assets/icons/points.svg";
import Coins from "../../../assets/icons/coins.svg";

const StudentNotifications = ({ notifications, handleClickShowAll }) => {
  const navigate = useNavigate();

  const convertUTCtoIST = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const istDate = new Date(date.getTime());
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // For 12-hour format
    };
    return istDate.toLocaleString("en-US", options);
  };

  const handleNotificationClick = (type, id, createdAt) => {
    // the date when the oldquizzes are disabled
    const dateThreshold = new Date("2024-06-05T02:57:00");

    if (type === "video") {
      navigate(`/student/courses/view?id=${id}`);
    } else if (type === "quiz") {
      const createdDate = new Date(createdAt);
      if (createdDate < dateThreshold) {
        navigate(`/student/quiz/start?id=${id}`);
      } else {
        navigate(`/student/quiz-new/start?id=${id}`);
      }
    }
  };

  return (
    <div className={classes.notificationsMain}>
      {notifications?.length > 0 && (
        <>
          <div className={classes.notificationWrapper}>
            {notifications?.map((notification) => {
              const { _id, type, read, message, createdAt, courseId, quizId } =
                notification;
              const isTypeVideo = type === "video";
              const isTypeQuiz = type === "quiz";
              const isTypePoints = type === "points";
              const isTypeCoins = type === "coins";
              return (
                <div
                  key={_id}
                  onClick={() =>
                    handleNotificationClick(type, courseId ? courseId : quizId, createdAt)
                  }
                  className={`${classes.notification} ${
                    isTypeVideo || isTypeQuiz ? classes.pointer : ""
                  }`}>
                  <div className={classes.notificationHeader}>
                    <div className={classes.notificationType}>
                      {isTypeVideo && (
                        <>
                          <div className={classes.playIcon}>
                            <PlayCircleIcon />
                          </div>
                          <div className={classes.typeVideoText}>Video</div>
                        </>
                      )}
                      {isTypeQuiz && (
                        <>
                          <div className={classes.quizIcon}>
                            <QuizIcon />
                          </div>
                          <div className={classes.typeQuizText}>Quiz</div>
                        </>
                      )}
                      {isTypePoints && (
                        <>
                          <div className={classes.pointsIcon}>
                            <img
                              width={16}
                              height={16}
                              src={Points}
                              alt="points"
                            />
                          </div>
                          <div className={classes.typePointsText}>Points</div>
                        </>
                      )}
                      {isTypeCoins && (
                        <>
                          <div className={classes.coinsIcon}>
                            <img
                              width={16}
                              height={16}
                              src={Coins}
                              alt="coins"
                            />
                          </div>
                          <div className={classes.typeCoinsText}>Coins</div>
                        </>
                      )}
                    </div>
                    <div className={classes.dateText}>
                      {!read && <div className={classes.redDot}></div>}
                      <div>{convertUTCtoIST(createdAt)}</div>
                    </div>
                  </div>
                  <div className={classes.notificationText}>{message}</div>
                </div>
              );
            })}
          </div>
          <div className={classes.allBtnContainer}>
            <div onClick={handleClickShowAll}>All notifications</div>
          </div>
        </>
      )}
      {!notifications?.length && (
        <div className={classes.noNotifications}>
          <div>you don't have any notifications</div>
        </div>
      )}
    </div>
  );
};
export default StudentNotifications;
