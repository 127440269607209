import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const WorkUpdateListing = ({
  lectures,
  onEdit,
  onDelete,
  isActionRequired,
  isTimeStampRequired,
}) => {
  const convertToIST = (timestamp) => {
    const date = new Date(timestamp);

    // Convert the date to IST (UTC + 5:30)
    const utcOffset = date.getTime() + date.getTimezoneOffset() * 60000; // Convert to UTC
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30
    const istDate = new Date(utcOffset + istOffset);

    // Format the IST date and time
    const istDateString = istDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });

    return istDateString;
  };
  return (
    <TableContainer component={Paper}>
      {/* This div adds horizontal scrolling when the content overflows */}
      <div style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>College</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Branch & Section</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Total Students</TableCell>
              <TableCell>Attended Students</TableCell>
              {isActionRequired && <TableCell>Actions</TableCell>}
              {isTimeStampRequired && (
                <TableCell>Created At & Updated At</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {lectures.map((lecture) => {
              const {
                _id,
                college,
                year,
                topic,
                branchAndSection,
                course,
                startTime,
                endTime,
                totalStudents,
                attendedStudents,
                createdAt,
                updatedAt,
              } = lecture;
              return (
                <TableRow key={_id}>
                  <TableCell>{college}</TableCell>
                  <TableCell>{year}</TableCell>
                  <TableCell>{branchAndSection}</TableCell>
                  <TableCell>{topic}</TableCell>
                  <TableCell>{course}</TableCell>
                  <TableCell>
                    {new Date(startTime).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(endTime).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </TableCell>

                  <TableCell>{totalStudents}</TableCell>
                  <TableCell>{attendedStudents}</TableCell>
                  {isActionRequired && (
                    <TableCell>
                      <IconButton color="primary" onClick={() => onEdit(_id)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => onDelete(_id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                  {isTimeStampRequired && (
                    <TableCell>
                      {convertToIST(createdAt)} & {convertToIST(updatedAt)}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};

export default WorkUpdateListing;
